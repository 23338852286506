import React from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentVersion } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Text } from '@pro4all/shared/ui/typography';
import { sortByWithInnerSort } from '@pro4all/shared/utils';

import { DocumentVersionCard } from './DocumentVersionCard';

interface Props {
  versions: DocumentVersion[];
}

export const VersionList: React.FC<Props> = ({ versions }) => {
  const { t } = useTranslation();

  const versionsSorted = versions
    .sort(
      sortByWithInnerSort({
        mainProp: 'createdAt',
        mainPropSortOrder: 'desc',
        subProp: 'name',
        subPropSortOrder: 'desc',
      })
    )
    .slice(1); //to skip the current version

  return (
    <Box pb={2} px={3}>
      {versionsSorted.length ? (
        versionsSorted.map((version: DocumentVersion) => (
          <DocumentVersionCard
            documentVersion={version}
            key={version.id}
            versionNumber={version.versionNumber ?? 0}
          />
        ))
      ) : (
        <Text variant="body1">{t('This document has no older versions')}</Text>
      )}
    </Box>
  );
};

export default VersionList;
