import { useCardToggle } from '@pro4all/shared/ui/card-toggle';

import { Log } from '../shared';

export const SectionLog = () => {
  const { CardToggle, expanded } = useCardToggle({ identifier: 'log' });

  return (
    <CardToggle icon="log" label="Log">
      {expanded ? <Log /> : null}
    </CardToggle>
  );
};
