import { useDocument } from '@pro4all/documents/data-access';
import { useSetDocumentPreview } from '@pro4all/documents/ui/sidebar';
import { useDocumentPreviewPane } from '@pro4all/shared/ui/image-preview';
import { Loader } from '@pro4all/shared/ui/loader';

export const useDocumentFullscreenMain = () => {
  const { document, loading: loadingDocument, version } = useDocument();
  const showLoaderMain = loadingDocument;

  const {
    currentVersion,
    show3DViewer,
    showPdfViewer,
    previewImages,
    setPreviewImages,
    slidesLoaded,
    totalPreviewPages,
    urn,
  } = useSetDocumentPreview({
    document,
    open: true,
    version,
  });

  const { documentPreviewPane } = useDocumentPreviewPane({
    currentVersion,
    open: true,
    previewImages,
    show3DViewer,
    showPdfViewer,
    slidesLoaded,
    sphereModeEnabled: true,
    totalPreviewPages,
    urn,
  });

  const mainContent = showLoaderMain ? <Loader /> : documentPreviewPane;

  return { mainContent, setPreviewImages };
};
