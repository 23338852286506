import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSidePanelContext } from '@pro4all/shared/contexts';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { AppBar, Box, Toolbar, Typography } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';

import { TopBarProps } from './layoutFrameTypes';

export const TopBar = ({ onClose, title }: TopBarProps) => {
  const { t } = useTranslation();
  const { setSidePanelIsFullscreen } = useSidePanelContext();

  const { setLocalStorageItem: setSidenavIsModalLocalStorage } =
    useLocalStorage<boolean>({
      key: `sidenav-modal`,
    });

  return (
    <AppBar
      color="transparent"
      elevation={0}
      position="static"
      sx={{
        backgroundColor: 'transparent',
        borderBottom: `1px solid ${customColors.grey400}`,
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">{title}</Typography>
        <Box sx={{ display: 'flex' }}>
          <IconButton
            aria-label={t('Toggle fullscreen')}
            color="default"
            disableBorder
            iconName="fullscreenExit"
            onClick={() => {
              setSidenavIsModalLocalStorage(false);
              setSidePanelIsFullscreen(false);
            }}
          />
          <IconButton
            aria-label={t('Close')}
            color="default"
            disableBorder
            iconName="close"
            onClick={onClose}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
