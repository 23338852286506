import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { DocumentService } from '@pro4all/documents/data-access';
import { use3DConversion } from '@pro4all/documents/ui/3d';
import { DocumentVersion } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/actions';
import { Card, FileSize } from '@pro4all/shared/ui/general';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';
import { Timestamp } from '@pro4all/shared/ui/timestamp';

interface Props {
  documentVersion: DocumentVersion;
  versionNumber: number;
}

export const DocumentVersionCard: React.FC<Props> = ({
  documentVersion,
  versionNumber,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { id: versionId, name } = documentVersion;

  const { refetch, threeDAction } = use3DConversion({
    activatePolling: true,
    selected: [documentVersion],
    versionIds: [versionId],
  });

  const downloadVersion = async () => {
    enqueueSnackbar(t('Download started'));
    const response = await DocumentService.downloadVersion(documentVersion);
    if (response) {
      const message = (
        <ItemChangedMessage
          description={MessageAction.Download}
          entityName={name}
          entityTypeTranslation={EntityTypeTranslation.Document}
        />
      );
      enqueueSnackbar(message);
    }
  };

  const downloadVersionMenuItem: ActionProps = {
    ariaLabel: t('Download version'),
    dataTestId: 'download',
    key: 'download',
    label: t('Download version'),
    onClick: downloadVersion,
    startIcon: 'download',
  };

  const menuItems = threeDAction
    ? [downloadVersionMenuItem, threeDAction]
    : [downloadVersionMenuItem];

  return (
    <Card
      iconName="file"
      menuItems={menuItems}
      meta={[
        <FileSize bytes={documentVersion.fileSize} />,
        documentVersion.createdBy?.displayName ??
          documentVersion.createdBy?.email,
        <Timestamp date={documentVersion.createdAt} format="lll" />,
      ]}
      onContextMenu={refetch}
      title={`${t('Version')} ${versionNumber}`}
    />
  );
};
