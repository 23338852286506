import { useTranslation } from 'react-i18next';

import { useShareBuild12Context } from '@pro4all/documents/ui/share-build12';
import { ActionProps } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';

import { DocumentActionProps } from '../../types';

export const useShareToBuild12Action = ({
  active,
  editableDocumentsWithFinalized,
  isVersion,
}: Pick<
  DocumentActionProps,
  'active' | 'editableDocumentsWithFinalized' | 'isVersion'
>) => {
  const { t } = useTranslation();
  const { searchParams, params } = useRouting();
  const { projectId } = params;
  const { setSelectedDocuments } = useShareBuild12Context();

  const openModal = () => {
    setSelectedDocuments(
      editableDocumentsWithFinalized.filter((document) => !document.isExpected)
    );
    searchParams.set({
      action: 'shareto12build',
    });
  };

  const shareToBuild12Action: ActionProps = {
    ariaLabel: t('Share to 12Build'),
    dataTestId: 'share-document-to-12build',
    disabled:
      !active ||
      !projectId ||
      editableDocumentsWithFinalized.length === 0 ||
      isVersion,
    key: 'share-document-to-12build',
    label: t('Share to 12Build'),
    onClick: openModal,
    startIcon: 'build12',
  };

  return shareToBuild12Action;
};
