import { useState } from 'react';

import {
  useDocument,
  useUserFolderPermissions,
} from '@pro4all/documents/data-access';
import { FinalizationState, Folder, ScopeType } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useAi } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';

import { DocumentKeywordsDisplay } from '../document-properties/keywords/DocumentKeywordsDisplay';
import { DocumentKeywordsEdit } from '../document-properties/keywords/DocumentKeywordsEdit';
import { KeywordModal } from '../document-properties/keywords/KeywordModal';
import { useGetKeywordData } from '../document-properties/keywords/useGetKeywordData';

export const AiKeywords = ({ folder }: { folder?: Folder }) => {
  const { document, version } = useDocument();
  const [displayKeywords, setDisplayKeywords] = useState(true);
  const {
    params: { projectId },
    searchParams,
  } = useRouting();
  const versionId = searchParams.get('versionid');
  const hasAiGenerateKeywordsFeatureFlag = useFeatureFlag('generate-keywords');

  const { aiEnabledContext } = useAi(
    projectId ? ScopeType.Project : ScopeType.Organization
  );

  const { hasSupportedExtensionToGenerateKeywords, keywordsInitially } =
    useGetKeywordData({ document, version });

  const { canUpdateDocumentOrVersion } = useUserFolderPermissions({
    folderId: folder?.id || document?.folderId || '',
  });

  const canEdit = canUpdateDocumentOrVersion({
    document: document ? document : null,
    version: version ? version : null,
  });

  const isFinalized = document
    ? document.state === FinalizationState.Finalized
    : version
    ? version.state === FinalizationState.Finalized
    : false;

  const versionIds = document
    ? [document.versionId || '']
    : version
    ? [version.id]
    : [];

  return document &&
    !versionId &&
    hasAiGenerateKeywordsFeatureFlag &&
    aiEnabledContext &&
    hasSupportedExtensionToGenerateKeywords &&
    Boolean(!document?.isExpected) ? (
    <>
      {displayKeywords ? (
        <DocumentKeywordsDisplay
          document={document ? document : null}
          editable={canEdit}
          isFinalized={isFinalized}
          keywordsInitially={keywordsInitially}
          setDisplayKeywords={setDisplayKeywords}
          version={version ? version : null}
          versionId={versionIds[0]}
        />
      ) : (
        <DocumentKeywordsEdit
          document={document ? document : null}
          setDisplayKeywords={setDisplayKeywords}
          version={version ? version : null}
          versionId={versionIds[0]}
        />
      )}
      <KeywordModal
        document={document ? document : null}
        version={version ? version : null}
        versionId={versionIds[0]}
      />
    </>
  ) : null;
};
