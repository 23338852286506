import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/actions';

export function useRieTableActions() {
  const { t } = useTranslation();

  //TODO: implement functions and remove disabled
  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Delete'),
      dataTestId: 'delete-connection',
      disabled: true,
      key: 'delete-rie',
      label: t('Delete'),
      onClick: () => null,
      startIcon: 'delete',
    },
    {
      ariaLabel: t('Edit'),
      dataTestId: 'edit-connection',
      disabled: true,
      key: 'edit-rie',
      label: t('Edit'),
      onClick: () => null,
      startIcon: 'edit',
    },
  ];

  return altActions;
}
