import React, { useRef } from 'react';
import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { ListProps } from '@pro4all/shared/ui/list';
import { ToggleProvider } from '@pro4all/shared/ui/toggle';

import * as Styled from './PropertyList.styles';

export interface Property {
  id?: string;
  label: string;
  prefix?: string;
  suffix?: string;
  value: React.ReactNode;
}

export type Props = Omit<ListProps, 'more'> & {
  children?: never;
  items: Property[];
  limit?: number;
  more?: PropertyListMore;
  orientation?: 'horizontal' | 'vertical';
};

export type PropertyListMore = React.ElementType<{
  children?: never;
  toggle: (force?: boolean) => void;
  toggled: boolean;
}>;

const PropertyListComponent: React.FC<Props> = ({
  items,
  orientation = 'horizontal',
  ...rest
}) => {
  const listRef = useRef<HTMLElement>(null);
  const isMobileScreen = useIsMobileScreen();
  orientation = isMobileScreen ? 'vertical' : orientation;
  return wrapChildren(items, orientation, listRef, rest);
};

export const PropertyList: React.FC<Props> = (props) => (
  <ToggleProvider>
    <PropertyListComponent {...props} />
  </ToggleProvider>
);

const FlexContainer = styled(Box)`
  display: flex;
`;

function wrapChildren(
  childrens: Property[],
  orientation: 'horizontal' | 'vertical',
  listRef: React.RefObject<HTMLElement>,
  rest: any
) {
  return (
    <Styled.List
      {...rest}
      item={Styled.Item}
      itemProps={{ $orientation: orientation }}
      ref={listRef}
    >
      {childrens.map(({ id, label, prefix, suffix, value }) => (
        <FlexContainer
          key={id ?? label}
          sx={{
            flexDirection: orientation === 'horizontal' ? 'row' : 'column',
          }}
        >
          <Box sx={{ mb: 1 }}>
            <Styled.Label $orientation={orientation}>{label}</Styled.Label>
            {prefix ? `${prefix} ` : ''}
            {value}
            {suffix ? ` ${suffix}` : ''}
          </Box>
        </FlexContainer>
      ))}
    </Styled.List>
  );
}
