import { useTranslation } from 'react-i18next';

import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { StorageKeys } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

import { DocumentActionProps } from '../../types';

export const usePublishAction = ({
  editableDocuments,
  isLocked,
  isLockedUser,
  isSpecialFolder,
  isVersion,
  position,
  selection,
}: Pick<
  DocumentActionProps,
  | 'editableDocuments'
  | 'isLocked'
  | 'isLockedUser'
  | 'isSpecialFolder'
  | 'isVersion'
  | 'position'
  | 'selection'
>) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { ACTION_PUBLISH } = useCentralActions();

  const openWarningModal = () => {
    // We store the current document in sessionStorage so that the openFileInputPublish method can pull it out from there.
    window.sessionStorage.setItem(
      'p4a:ps:currentDocument',
      JSON.stringify(selection[0])
    );

    // We store the position from where this action was triggered in sessionStorage so that the selectFileToPublish` method
    // can pull it out from there to deselect the selected row properly.
    window.sessionStorage.setItem(StorageKeys.ACTION_TRIGGERED_FROM, position);

    searchParams.set({
      action: 'publishVersion',
      publishDocumentId: selection[0].id,
    });
  };

  const editFileAction: ActionProps = {
    ...ACTION_PUBLISH,
    disabled:
      editableDocuments.length === 0 ||
      !isLocked ||
      (isLocked && !isLockedUser) ||
      isSpecialFolder ||
      isVersion ||
      selection.length > 1,
    onClick: openWarningModal,
  };

  return editFileAction;
};
