import React, { useState } from 'react';

import { useOrganizationContext } from '@pro4all/organization/context';
import {
  getAuthUserDisplayName,
  useContextScopedOrganizationId,
} from '@pro4all/shared/identity';
import { ProfilePictureEntity } from '@pro4all/shared/types';
import { AvatarProfile } from '@pro4all/shared/ui/file-upload';
import { LayoutLeftMain } from '@pro4all/shared/ui/layout';

import { languageOptions } from './languageOptions';
import { MyProfileForm } from './MyProfileForm';
import { NameType } from './types';
import { FormFields } from './types';

export const MyProfileTabContent = () => {
  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();
  const userName = getAuthUserDisplayName();

  const [, setValues] = useState<NameType>({
    firstName: '',
    lastName: '',
  });

  const {
    userActive,
    userEmail,
    userFirstName,
    userJobTitle,
    userLanguage,
    userLastName,
    userPhoneNumber,
    userId,
  } = useOrganizationContext();

  const languageOption = languageOptions.find(
    (langOption) => langOption.id === userLanguage
  );

  const defaultLanguageOption = languageOptions.find(
    (langOption) => langOption.id === 'en-US'
  );

  const initialValues: FormFields = {
    active: userActive || true,
    email: userEmail || '',
    firstName: userFirstName || '',
    id: userId || '',
    jobTitle: userJobTitle || '',
    language: languageOption || defaultLanguageOption,
    lastName: userLastName || '',
    organizationId: organizationId || '',
    phoneNumber: userPhoneNumber || '',
  };

  const leftContent = (
    <MyProfileForm initialValues={initialValues} setValues={setValues} />
  );

  const mainContent = (
    <AvatarProfile
      avatarFallbacklabel={userName}
      id={userId}
      labelEntity="profile" // i18n
      profilePictureEntity={ProfilePictureEntity.User}
    />
  );

  return <LayoutLeftMain leftContent={leftContent} mainContent={mainContent} />;
};
