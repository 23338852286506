import styled from 'styled-components';

export const Wrapper = styled.div<{ $hasFullScreenView: boolean }>`
  && {
    display: flex;
    height: ${({ $hasFullScreenView }) =>
      $hasFullScreenView ? '76px' : 'auto'};
    justify-content: space-between;
    align-items: center;
    margin: ${({ theme }) => theme.spacing(1)};
  }
`;

export const GenericTableActionsWrapper = styled.div`
  && {
    display: flex;
    color: ${({ theme }) => theme.palette.success.dark};
    > * {
      margin-left: ${({ theme }) => theme.spacing(1)};
    }
  }
`;
