import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';
import { Lightbox as ReactLightbox } from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Inline from 'yet-another-react-lightbox/plugins/inline';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import { use3DViewer } from '@pro4all/documents/ui/3d';
import {
  DocumentsPdfTronViewer,
  PdfTronOpenAction,
} from '@pro4all/documents/ui/annotate';
import { DocumentVersion, Maybe } from '@pro4all/graphql';
import { Action } from '@pro4all/shared/config';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { ImagePlaceHolder } from '@pro4all/shared/ui/image-placeholder';
import { ContentRow } from '@pro4all/shared/ui/layout';
import { useAnalytics } from '@pro4all/shared/vendor';

import { calculatePreviewDimensions } from './ImagePreview.helpers';
import * as Styled from './ImagePreview.styles';
import {
  CurrentSlide,
  PreviewDimensions,
  PreviewImg,
} from './ImagePreview.types';
import { ImagePreviewViewModes } from './ImagePreviewViewOptions';
import { useLightBoxConfig } from './LightboxConfig';
import { SphereViewerStylesheet } from './SphereViewer.styles';

export const useDocumentPreviewPane = ({
  currentVersion,
  open = false,
  show3DViewer = false,
  showPdfViewer = false,
  previewImages,
  slidesLoaded,
  sphereModeEnabled = false,
  totalPreviewPages,
  urn,
}: {
  currentVersion?: Maybe<DocumentVersion | undefined>;
  open: boolean;
  previewImages: PreviewImg[];
  show3DViewer?: boolean;
  showPdfViewer?: boolean;
  slidesLoaded: boolean;
  sphereModeEnabled: boolean;
  totalPreviewPages: number;
  urn?: string;
}) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();

  const [currentSlide, setCurrentSlide] = useState<CurrentSlide>({ index: 0 });
  const [viewMode, setViewMode] = useState<string>('normal');
  const [previewDimensions, setPreviewDimensions] = useState<PreviewDimensions>(
    calculatePreviewDimensions()
  );

  const { viewContainer } = use3DViewer({
    loadingDocument: false,
    loadingFolder: false,
    urn,
  });

  const LIGHTBOX_CONFIG = useLightBoxConfig(viewMode);

  const imagePreviewViewModes = ImagePreviewViewModes.map((opt) => ({
    id: opt.id,
    label: t(opt.label),
  }));

  useEffect(() => {
    const handleResize = () => {
      setPreviewDimensions(calculatePreviewDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getLightBoxPlugins = () => {
    let plugins = [Inline];
    if (viewMode === 'normal') {
      plugins = [...plugins, ...[Fullscreen, Zoom]];
      if (totalPreviewPages > 1) plugins = [...plugins, ...[Thumbnails]];
    }
    return plugins;
  };

  const handleViewModeChange = (value: Option) => {
    track(Action.DocumentPreviewModeChange, {
      viewMode: value.id,
    });
    setViewMode(value.id);
  };

  const documentPreviewPane = show3DViewer ? (
    <ContentRow sx={{ height: '100%' }}>
      <Box
        ref={viewContainer}
        sx={{
          display: 'flex',
          height: '100%',
          position: 'relative',
          width: '100%',
        }}
      />
      <link
        href="https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css"
        rel="stylesheet"
        type="text/css"
      />
    </ContentRow>
  ) : showPdfViewer ? (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        padding: 0,
        width: '100%',
      }}
    >
      <DocumentsPdfTronViewer
        action={PdfTronOpenAction.PREVIEW}
        hideGoBack
        initialVersionId={currentVersion?.id}
      />
    </Box>
  ) : previewImages.length ? (
    <>
      {sphereModeEnabled && (
        <Styled.ViewmodeSelect
          name="viewModeSelect"
          onChange={handleViewModeChange}
          options={imagePreviewViewModes}
          value={viewMode}
        />
      )}
      <style>{open ? LIGHTBOX_CONFIG.styles : ''}</style>
      <style>{SphereViewerStylesheet}</style>
      {!slidesLoaded && (
        <Styled.Loader
          value={100 * (previewImages.length / totalPreviewPages)}
          variant="determinate"
        />
      )}
      <ReactLightbox
        animation={LIGHTBOX_CONFIG.animation}
        carousel={LIGHTBOX_CONFIG.carousel}
        index={currentSlide.index}
        inline={{
          style: {
            aspectRatio: `${previewDimensions.width}/${previewDimensions.height}`,
            background: 'white',
            height: '100%',
            width: '100%',
          },
        }}
        on={{ view: setCurrentSlide }}
        plugins={getLightBoxPlugins()}
        render={{
          buttonNext: previewImages.length <= 1 ? () => null : undefined,
          buttonPrev: previewImages.length <= 1 ? () => null : undefined,
          slide:
            viewMode === 'normal'
              ? undefined
              : ({ slide }) => (
                  <ReactPhotoSphereViewer
                    container="div"
                    height="100%"
                    keyboard={false}
                    navbar={['zoomIn', 'zoomOut', 'fullscreen']}
                    requestHeaders={{
                      'sec-fetch-mode': 'no-cors',
                    }}
                    src={slide.src}
                    width="100%"
                    zoomSpeed={5}
                  ></ReactPhotoSphereViewer>
                ),
        }}
        slides={previewImages}
        thumbnails={LIGHTBOX_CONFIG.thumbnails}
        zoom={LIGHTBOX_CONFIG.zoom}
      />
    </>
  ) : (
    <ImagePlaceHolder randomShape shadow={false} />
  );

  return { currentSlide, documentPreviewPane };
};
