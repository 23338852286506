import { DocumentAction, Task } from '@pro4all/graphql';
import { TaskListDisplay } from '@pro4all/shared/linked-tasks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useLinkedTasks } from '@pro4all/workflow/data-access';

export const TaskList = () => {
  const { linkedTasks, loading: linkedTaskLoading } = useLinkedTasks();
  const { searchParams } = useRouting();

  const handleTaskClick = (taskId: string) => {
    searchParams.set({ action: 'viewTask', id: taskId });
  };

  return (
    <TaskListDisplay
      getTaskType={(task: Task) => task?.documentAction ?? ''}
      loading={linkedTaskLoading}
      noTasksMessage="This document has not been linked to any task yet" // I18n
      onTaskClick={handleTaskClick}
      taskTypeEnum={DocumentAction}
      tasks={linkedTasks as Task[]}
    />
  );
};
