import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useCollectionFolder } from '@pro4all/documents/ui/folders';
import {
  downloadDocument,
  useDocumentsContext,
} from '@pro4all/documents/ui/share';
import { DocumentDetails } from '@pro4all/documents/ui/sidebar';
import { Document, useDocumentsQuery } from '@pro4all/graphql';
import {
  useDocumentActions,
  useDocumentSelection,
} from '@pro4all/shared/hooks/src/documents';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Position } from '@pro4all/shared/types';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { Loader } from '@pro4all/shared/ui/loader';
import { BigMessageNothingCollected } from '@pro4all/shared/ui/messages';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
  useTableContextMenuRowContext,
} from '@pro4all/shared/ui/table';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';
import { TaskSidebar } from '@pro4all/workflow/ui/task-sidebar';

import { useColumns } from './hooks/useColumns';

export const CollectionActionBar = ({
  setActionLoading,
}: {
  setActionLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const { collectionActions } = useDocumentActions({
    isSpecialFolderProp: true,
    setLoading: setActionLoading,
  });

  return (
    <TableActionBar
      altActions={collectionActions}
      dataTestid="collection-action-bar"
      mainActions={[]}
      search
    />
  );
};

export const CollectionTable = () => {
  const {
    setDocTableColumnSizes,
    setTableScrollPositionLeft,
    tableScrollPositionLeft,
  } = useDocumentsContext();

  const columns = useColumns({
    onColumnResizeCallback: setDocTableColumnSizes,
  });
  const { getDocumentsIds } = useCollectionFolder();
  // To avoid unnecessary api calls
  const documentIds = useMemo(() => getDocumentsIds(), [getDocumentsIds]);

  const { searchParams } = useRouting();

  const [actionLoading, setActionLoading] = useState(false);

  const {
    state: { item, items },
  } = useOptimisticResponseContext<Document>();

  const { data, loading } = useDocumentsQuery({
    fetchPolicy: 'no-cache',
    variables: { documentIds: documentIds },
  });

  useSetItemsInLocalState<Document>(data?.documents);

  const { deselectDocument, selectDocument } = useDocumentSelection(items);

  const lastSelectedContextMenuRow = useRef<Document>(null);
  const { contextMenuRow } = useTableContextMenuRowContext<Document>();

  const { collectionActions } = useDocumentActions({
    contextMenuRow,
    position: Position.Contextmenu,
    setLoading: setActionLoading,
  });

  const { collectionActions: sideCollectionActions } = useDocumentActions({
    position: Position.Sidebar,
    setLoading: setActionLoading,
    sidebarRow: item,
  });

  const documentId = searchParams.get('id');

  // We have to store the last selected context menu row. Why is this?
  // Because 'contextMenuRow' will be null again after the user clicks an option from the context menu.
  // And after that we still need the documentId in the method 'onDropContextMenu'.
  useEffect(() => {
    if (contextMenuRow) {
      lastSelectedContextMenuRow.current = contextMenuRow;
    }
  }, [contextMenuRow]);

  if (loading) return <Loader />;
  if (!loading && !data) return <BigMessageNothingCollected />;

  return (
    <TableContextProvider
      columns={columns}
      id="table-documents-collection-bin"
      items={items}
    >
      <FilterContextProvider<Document>>
        <DocumentDetails
          documentId={documentId}
          isSmartFolder
          onClose={deselectDocument}
          sidebarActions={sideCollectionActions}
        />
        <TaskSidebar />
        <CollectionActionBar setActionLoading={setActionLoading} />
        {items.length ? (
          <Table
            contextMenuActions={collectionActions}
            onColumnResizeCallback={setDocTableColumnSizes}
            onRowClick={selectDocument}
            onRowDoubleClick={downloadDocument}
            onTableHorizontalScrollCallback={setTableScrollPositionLeft}
            overlayRenderer={actionLoading ? <Loader /> : null}
            selectedId={documentId}
            tableScrollPositionLeft={tableScrollPositionLeft}
          />
        ) : (
          <BigMessageNothingCollected />
        )}
      </FilterContextProvider>
    </TableContextProvider>
  );
};
