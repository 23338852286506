import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/actions';
import { useActionLogin } from '@pro4all/shared/ui/actions';
import { InfoPage } from '@pro4all/shared/ui/infopages';

export const Page404NotAuthenticated = () => {
  const { t } = useTranslation();
  const actionLogin = useActionLogin({});

  const description = (
    <Trans
      defaults="We can help you in another direction, though.<br /> Where do you want to go?"
      i18nKey="BigMessage.notFound"
    />
  );

  const actions: ActionProps[] = [
    {
      ariaLabel: 'About Prostream',
      color: 'inherit',
      dataTestId: 'about-prostream',
      key: 'about-prostream',
      label: t('About Prostream'),
      onClick: () => window.open('https://prostream.app', '_self'),
      startIcon: 'prostream',
    },
    actionLogin,
  ];

  return (
    <InfoPage
      actions={actions}
      description={description}
      title={t('Page not found')}
      type="error"
    />
  );
};
