export enum AvatarSize {
  AvatarSizeL = 'AvatarSizeL',
  AvatarSizeM = 'AvatarSizeM',
  AvatarSizeS = 'AvatarSizeS',
  AvatarSizeXL = 'AvatarSizeXL',
  AvatarSizeXS = 'AvatarSizeXS',
  AvatarSizeXXL = 'AvatarSizeXXL',
}
// OLD           New naming
//-----------------------------
// ExtraSmall    AvatarSizeXS
// Small         AvatarSizeS
// Medium        AvatarSizeL
// Large         AvatarSizeXL
// ExtraLarge    AvatarSizeXXL

export type AvatarProps = {
  name?: string;
  noOfCharacters?: number;
  size?: AvatarSize;
  src?: string;
};

type AvatarSizeProps = {
  avatarSize: number;
  fontSize: number;
};

export const AvatarSizeXS: AvatarSizeProps = {
  avatarSize: 24,
  fontSize: 12,
};

export const AvatarSizeS: AvatarSizeProps = {
  avatarSize: 32,
  fontSize: 16,
};

export const AvatarSizeM: AvatarSizeProps = {
  avatarSize: 40,
  fontSize: 20,
};

export const AvatarSizeL: AvatarSizeProps = {
  avatarSize: 56,
  fontSize: 28,
};

export const AvatarSizeXL: AvatarSizeProps = {
  avatarSize: 80,
  fontSize: 40,
};

export const AvatarSizeXXL: AvatarSizeProps = {
  avatarSize: 120,
  fontSize: 60,
};
