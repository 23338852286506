import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Document, Folder } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';
import { RELOAD_FLAG } from '@pro4all/shared/search-utils';
import { Position } from '@pro4all/shared/ui/context-menu';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

import { useFolderPath } from './useFolderPath';
import { useStandardFolderActions } from './useStandardFolderActions';

export const useFolderTreeActions = () => {
  const { searchParams } = useRouting();
  const { t } = useTranslation();
  const {
    ACTION_CREATE,
    ACTION_DOWNLOAD,
    ACTION_PROPERTIES,
    ACTION_SEARCH_IN_THIS_FOLDER,
  } = useCentralActions();

  const { resetInitialItems } = useOptimisticResponseContext<Document>() || {};

  const { openFolder } = useFolderPath();

  const [buttonPosition, setButtonPosition] = useState<Position>();

  /**
   * Refs for 'targetFolder' because we don't need extra
   * re-rendering on setting these values. Re-rendering goes via
   * contextMenuOpen (showing context menu).
   */
  const targetFolder = useRef(null);

  const contextMenuIsOpen =
    (searchParams.get('folderPath') &&
      searchParams.get('action') === 'showContextMenu') ||
    false;

  const onSelectFolder = useCallback(
    async (folder: Folder, path: string[]) => {
      targetFolder.current = folder;
      openFolder(path.join('/'));
    },
    [openFolder]
  );

  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: targetFolder.current?.id,
  });

  const onContextMenu = (
    event: React.MouseEvent,
    folder: Folder,
    path: string[]
  ) => {
    targetFolder.current = folder;
    setButtonPosition({
      left: event.clientX,
      top: event.clientY,
    });
    openContextMenu(event, path, folder);
  };

  // If current action is renameFolder, the (re)-name input is on screen. Prevent context menu from opening.
  const checkIsValidAction = () => !searchParams.is('action', 'renameFolder');

  const openContextMenu = (
    event: React.MouseEvent,
    path: string[],
    folder?: Folder
  ) => {
    event && event.preventDefault();
    if (checkIsValidAction()) {
      targetFolder.current = folder;
      searchParams.set({
        action: 'showContextMenu',
        folderPath: encodeURIComponent(path.join('/')),
      });
    }
  };

  const closeContextMenu = () => {
    setButtonPosition(undefined);
    if (searchParams.get('action') === 'showContextMenu') {
      searchParams.delete('action');
    }
  };

  const { menuItems: standardMenuItems } = useStandardFolderActions(
    targetFolder.current
  );

  const [
    applyTemplateMenuItem,
    deleteFolderMenuItem,
    renameFolderMenuItem,
    editFolderPermissionsMenuItem,
    ...otherItems
  ] = standardMenuItems;

  const createNewFolder = () => {
    searchParams.set({ action: 'createFolder' });
    closeContextMenu();
  };

  const downloadZip = () => {
    searchParams.set({ action: 'downloadZip' });
    closeContextMenu();
  };

  const showPropertiesModal = () => {
    searchParams.set({
      action: 'showFolderProperties',
      folder: encodeURIComponent(targetFolder.current.name),
    });
    closeContextMenu();
  };

  enum SearchFolderType {
    ExcludeSubfolders = 'folderId',
    IncludeSubfolders = 'parentFolderIds',
  }

  const searchInFolder = (searchType: SearchFolderType) => {
    const folderId = targetFolder.current.id;
    if (!folderId) return;
    resetInitialItems();
    searchParams.set({
      filters: `${searchType}:${folderId}${RELOAD_FLAG}`,
      search: '',
    });
    closeContextMenu();
  };

  const findInFolderSubMenu: ActionProps[] = [
    {
      ariaLabel: 'Include subfolders',
      dataTestId: 'include-subfolders',
      disabled: !hasFolderPermission('ReadFolder'),
      key: 'include-subfolders',
      label: t('Include subfolders'),
      onClick: () => searchInFolder(SearchFolderType.IncludeSubfolders),
      startIcon: 'addQuestionsSet',
    },
    {
      ariaLabel: 'Exclude subfolders',
      dataTestId: 'exclude-subfolders',
      disabled: !hasFolderPermission('ReadFolder'),
      key: 'exclude-subfolders',
      label: t('Exclude subfolders'),
      onClick: () => searchInFolder(SearchFolderType.ExcludeSubfolders),
      startIcon: 'squareMinus',
    },
  ];

  const findInFolder: ActionProps = {
    ...ACTION_SEARCH_IN_THIS_FOLDER,
    disabled: !hasFolderPermission('ReadFolder'),
    subItems: findInFolderSubMenu,
  };

  const addFolderMenuItem: ActionProps = {
    ...ACTION_CREATE,
    ariaLabel: 'Create subfolder',
    dataTestId: 'add-subfolder',
    disabled: !hasFolderPermission('CreateSubFolder'),
    key: 'add-subfolder',
    label: t('Create subfolder'),
    onClick: createNewFolder,
    startIcon: 'folderNew',
  };

  const downloadFolderMenuItem: ActionProps = {
    ...ACTION_DOWNLOAD,
    ariaLabel: 'Download zip',
    dataTestId: 'download-zip',
    disabled: !hasFolderPermission('ReadFolder'),
    key: 'download-folder',
    label: t('Download zip'),
    onClick: downloadZip,
  };

  const showFolderProperties: ActionProps = {
    ...ACTION_PROPERTIES,
    disabled: !hasFolderPermission('ReadFolder'),
    onClick: showPropertiesModal,
  };

  const menuItems: ActionProps[] = [
    addFolderMenuItem,
    downloadFolderMenuItem,
    renameFolderMenuItem,
    applyTemplateMenuItem,
    editFolderPermissionsMenuItem,
    showFolderProperties,
    deleteFolderMenuItem,
    findInFolder,
    ...otherItems,
  ];

  return {
    buttonPosition,
    closeContextMenu,
    contextMenuIsOpen,
    createNewFolder,
    menuItems,
    onContextMenu,
    onSelectFolder,
    targetFolder: targetFolder.current,
  };
};
