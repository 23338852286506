import styled from 'styled-components';

import { LinearProgress } from '@pro4all/shared/mui-wrappers';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { Select } from '@pro4all/shared/ui/inputs';

// No overflow for better mobile experience while zooming
export const FloatingModalContainer = styled('div')`
  -ms-overflow-style: none;
  height: auto;
  min-height: 80%;
  overflow: hidden;
  scrollbar-width: none;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PreviewImage = styled('img')`
  max-height: 100%;
  max-width: 100%;
`;

export const Loader = styled(LinearProgress)`
  && {
    left: 18px;
    position: absolute;
    width: calc(100% - 36px);
    z-index: 1;
  }
`;

// Since we use fullscreen mode, apply some margin
export const FloatingModalWithMargin = styled(FloatingModal)`
  && {
    margin: 18px;
  }
`;

export const PageCounter = styled('span')`
  color: #848484;
`;

// z-index 101 cause Sphereviewer has a toolbar that has z-index 100
export const ViewmodeSelect = styled(Select)`
  && {
    position: relative;
    top: 46px;
    left: 6px;
    width: 160px;
    z-index: 101;
  }
`;
