import React from 'react';

import { useIsMobileScreen } from '@pro4all/shared/themes';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';

import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

import * as Styled from './ImagePreview.styles';
import { ImagePreviewProps } from './ImagePreview.types';
import { useDocumentPreviewPane } from './useDocumentPreviewPane';

export const ImagePreview: React.FC<ImagePreviewProps> = ({
  open = false,
  onClose,
  totalPreviewPages = 0,
  previewImages = [],
  sphereModeEnabled = false,
  slidesLoaded = false,
  title = 'Preview',
}) => {
  const isMobileScreen = useIsMobileScreen();

  const { currentSlide, documentPreviewPane } = useDocumentPreviewPane({
    open,
    previewImages,
    slidesLoaded,
    sphereModeEnabled,
    totalPreviewPages,
  });

  return (
    <FloatingModal
      data-testid="preview-document"
      fullScreen
      maxWidth="md"
      onClose={onClose}
      open={open}
      sx={{
        borderRadius: isMobileScreen ? '0' : 'initial',
      }}
    >
      <FloatingModal.Header>
        <MiddleEllipsis
          endLength={8}
          text={
            `${title}` +
            ` ${
              totalPreviewPages > 1
                ? [
                    '[',
                    currentSlide.index + 1,
                    '/',
                    totalPreviewPages,
                    ']',
                  ].join('')
                : ''
            }`
          }
        />
      </FloatingModal.Header>
      <Styled.FloatingModalContainer>
        {documentPreviewPane}
      </Styled.FloatingModalContainer>
    </FloatingModal>
  );
};
