import React from 'react';
import { useTranslation } from 'react-i18next';

import { SavedAnswers } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SelectedIdName } from '@pro4all/shared/types';
import { useTableCheck } from '@pro4all/shared/ui/table';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

export const SavedAnswersActionBar = () => {
  const { t } = useTranslation();
  const { ACTION_CREATE, ACTION_DELETE } = useCentralActions();
  const { searchParams } = useRouting();
  const { checkedRows } = useTableCheck<SavedAnswers>();
  const savedAnswersSelected: SelectedIdName[] = checkedRows.map((row) => ({
    id: row.id,
    name: row.name,
  }));

  const { setLocalStorageItem: setSavedAnswersNameToDeleteInLs } =
    useLocalStorage<SelectedIdName[]>({
      key: `prostream-saved-answers-name-to-delete`,
    });

  const mainActions: ActionProps[] = [
    {
      ...ACTION_CREATE,
      ariaLabel: t('Create saved answer'),
      dataTestId: 'create-saved-answer',
      key: 'create-saved-answer',
      label: t('Create saved answers'),
      onClick: () => searchParams.set('action', 'selectTemplate'),
    },
  ];

  const altActions: ActionProps[] = [
    {
      ...ACTION_DELETE,
      dataTestId: 'delete-saved-answer',
      key: 'delete-saved-answer',
      onClick: () => {
        setSavedAnswersNameToDeleteInLs(savedAnswersSelected);
        searchParams.set({
          deleteSavedAnswers: 'true',
        });
      },
    },
  ];

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="saved-answers-action-bar"
      mainActions={mainActions}
      search
    />
  );
};
