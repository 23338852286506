import { ActionProps } from './actionTypes';
import { useSortActions } from './useSortActions';

export const useGroupedActions = () => {
  const { sortActions } = useSortActions();

  const getGroupedActions = ({ actions }: { actions: ActionProps[] }) => {
    const sortedActions = sortActions(
      actions?.filter((action) => !action.disabled) || []
    );
    return sortedActions.reduce<Record<string, typeof actions>>(
      (groups, action) => {
        const key = action.actionGroupKey || 'ungrouped';
        if (!groups[key]) groups[key] = [];
        groups[key].push(action);
        return groups;
      },
      {}
    );
  };

  return { getGroupedActions };
};
