import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useDeleteReportConfigurations } from '../hooks/useDeleteReportConfigurations';

export const ReportConfigurationsActionBar = () => {
  const { t } = useTranslation();
  const {
    params: { projectId },
    searchParams,
  } = useRouting();
  const { hasAppPermission } = useAppPermissions();
  const { ACTION_CREATE, ACTION_DELETE } = useCentralActions();
  const userCannotUpdateOrg = !hasAppPermission('OrganizationUpdate');
  const userCannotUpdateProject = !hasAppPermission('ProjectUpdate');
  const deleteReportConfigurations = useDeleteReportConfigurations();

  const disableButton = projectId
    ? userCannotUpdateOrg && userCannotUpdateProject
    : userCannotUpdateOrg;

  const mainActions: ActionProps[] = [
    {
      ...ACTION_CREATE,
      ariaLabel: t('Create report configuration'),
      dataTestId: `new-report-configuration`,
      disabled: disableButton,
      key: `new-report-configuration`,
      label: t('Create report configuration'),
      onClick: () => {
        searchParams.delete('id');
        searchParams.set('action', 'createReportConfiguration');
      },
    },
  ];

  const altActions: ActionProps[] = [
    {
      ...ACTION_DELETE,
      dataTestId: `delete-report-configuration`,
      disabled: disableButton,
      key: `delete-report-configuration`,
      onClick: () => deleteReportConfigurations(),
    },
  ];

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="report-configurations-action-bar"
      mainActions={mainActions}
      search
    />
  );
};
