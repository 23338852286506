import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/actions';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Typography } from '@pro4all/shared/mui-wrappers';
import { Button } from '@pro4all/shared/ui/buttons';

import * as Styled from './Ai.styles';

export function AiFrame({
  actions = [],
  children,
  maxHeight,
  label,
  showDisclaimer = false,
}: PropsWithChildren<{
  actions?: ActionProps[];
  label: string;
  maxHeight?: number;
  showDisclaimer?: boolean;
}>) {
  const { t } = useTranslation();

  return (
    <>
      <Styled.AiMainWrapper>
        <Styled.AiHeaderWrapper>
          <Styled.AiHeader>{label}</Styled.AiHeader>
          <Styled.AiHeaderIconWrapper>
            <Styled.AiHeaderIcon />
          </Styled.AiHeaderIconWrapper>
        </Styled.AiHeaderWrapper>
        <Box
          sx={{
            maxHeight: maxHeight ? maxHeight : '100%',
            mx: 0,
            my: 1,
            overflowY: 'auto',
          }}
        >
          {children}
        </Box>
        <Styled.AiButtons>
          {actions.map((action) => (
            <Button
              color={action.color}
              disabled={action.disabled}
              key={action.key}
              onClick={action.onClick}
              startIcon={action.startIcon}
              variant="text"
            >
              {action.label}
            </Button>
          ))}
        </Styled.AiButtons>
      </Styled.AiMainWrapper>
      {showDisclaimer ? (
        <Styled.AiDisclaimer>
          <Styled.AiHeaderIconWrapper>
            <Styled.AiHeaderIcon />
          </Styled.AiHeaderIconWrapper>
          <Typography variant="body2">
            {t('Always double check if all generated data is accurate')}
          </Typography>
        </Styled.AiDisclaimer>
      ) : null}
    </>
  );
}
