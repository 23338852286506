const pdfViewerExtensions = [
  'pdf',
  'msg',
  'txt',
  'xps',
  'oxps',
  'xml',
  'xod',
  'xaml',
];

export const isSupportedPdfViewerExtension = (extension: string) => {
  if (extension.startsWith('.')) extension = extension.substring(1);
  return pdfViewerExtensions.includes(extension.toLowerCase());
};
