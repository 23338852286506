import {
  ConversionStatus,
  useThreeDConversionStatusesQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

export const use3DDocuments = ({
  is3D = true,
  activatePolling = false,
  versionIds,
}: {
  activatePolling?: boolean;
  is3D?: boolean;
  versionIds: string[];
}) => {
  const { goTo } = useRouting();

  const { data, loading, refetch, startPolling, stopPolling } =
    useThreeDConversionStatusesQuery({
      pollInterval: activatePolling ? 60000 * ApiConfig.pollEnabled : undefined,
      skip: !is3D || versionIds.length === 0,
      variables: { versionIds },
    });

  const urns =
    data?.threeDConversionStatuses
      ?.filter((docStatus) => docStatus?.status === ConversionStatus.Success)
      ?.map((docStatus) => docStatus?.fileUrn) || [];

  const multipleSelected =
    data && data.threeDConversionStatuses
      ? data?.threeDConversionStatuses?.length > 1
      : false;

  const atLeastOneConvertedTo3D = data?.threeDConversionStatuses?.some(
    (doc) => doc?.status === ConversionStatus.Success
  );

  const showActionIfMultipleSelected = multipleSelected
    ? atLeastOneConvertedTo3D
    : true;

  const conversionStatus =
    data && data.threeDConversionStatuses
      ? multipleSelected && atLeastOneConvertedTo3D
        ? ConversionStatus.Success
        : data?.threeDConversionStatuses[0]
        ? data?.threeDConversionStatuses[0]?.status
        : ConversionStatus.NotStarted
      : ConversionStatus.NotStarted;

  const view3D = () => {
    goTo({
      searchParams: {
        fullscreen: true,
        urn: JSON.stringify(urns),
      },
    });
  };

  return {
    conversionStatus,
    loading,
    refetch,
    showActionIfMultipleSelected,
    startPolling,
    stopPolling,
    urns,
    view3D,
  };
};
