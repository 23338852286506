import React from 'react';

import { Button as MuiButton } from '@pro4all/shared/mui-wrappers';
import { ActionProps } from '@pro4all/shared/actions';
import { ContextMenu } from '@pro4all/shared/ui/context-menu';
import { iconsMap } from '@pro4all/shared/ui/icons';

import { useContextMenuPosition } from '../hooks/useContextMenuPosition';

export const Button = ({
  ariaLabel,
  color = 'primary',
  onClick,
  dataTestId,
  disabled = false,
  endIcon,
  label,
  startIcon,
  tabIndex,
  type = 'button',
  subItems,
  variant = 'outlined',
  ...rest
}: ActionProps) => {
  const backwardsCompatibleProps = {
    ...(ariaLabel && { 'aria-label': ariaLabel }),
    ...(dataTestId && { 'data-testid': dataTestId }),
    ...(label && { children: label }),
  };

  const { closeContextMenu, isContextMenuOpen, openContextMenu, position } =
    useContextMenuPosition();

  return (
    <>
      <MuiButton
        color={color}
        disableElevation
        disabled={disabled}
        endIcon={endIcon && React.createElement(iconsMap[endIcon])}
        onClick={subItems && subItems.length > 0 ? openContextMenu : onClick}
        size="small"
        startIcon={startIcon && React.createElement(iconsMap[startIcon])}
        tabIndex={tabIndex}
        type={type}
        variant={variant}
        {...backwardsCompatibleProps}
        {...rest}
      />
      <ContextMenu
        data-testid="context-menu-sub-items"
        initialPosition={position}
        menuItems={subItems}
        onClose={closeContextMenu}
        open={isContextMenuOpen}
      />
    </>
  );
};
