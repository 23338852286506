import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { Group, User } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/actions';
import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import {
  Avatar,
  Box,
  Chip,
  Popover,
  Typography,
} from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors, StylingDefaults } from '@pro4all/shared/themes';
import { IdentityTagType } from '@pro4all/shared/types';
import { AvatarSizeXS } from '@pro4all/shared/ui/avatar';
import { getInitials } from '@pro4all/shared/utils';

import { getIdentityUrl } from './getIdentityUrl';
import { GroupCard } from './GroupCard';
import { ProjectCard } from './ProjectCard';
import { useGetUsersAction } from './useGetUsersAction';
import { UserCard } from './UserCard';

type IdentityTagProps = {
  hasBorder?: boolean;
  id: string;
  label: string;
  onDelete?: (event: any) => void;
  totalUsers?: number;
  type: IdentityTagType;
};

export const IdentityTag = ({
  hasBorder = false,
  id,
  label,
  onDelete,
  totalUsers,
  type,
}: IdentityTagProps) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const open = Boolean(anchorEl);

  const debouncedOnMouseEnter = useDebouncedCallback(
    (target: HTMLDivElement) => {
      setAnchorEl(target);
    },
    300
  );

  useEffect(() => {
    // Calculate the URL
    const url = getIdentityUrl({ id, type });

    // Validate the URL by preloading the image
    const validateUrl = () => {
      setIsLoading(true);
      const img = new Image();
      img.src = url;

      img.onload = () => {
        setAvatarSrc(url); // Valid URL
        setIsLoading(false);
      };

      img.onerror = () => {
        setAvatarSrc(null); // Invalid URL
        setIsLoading(false);
      };
    };

    validateUrl();
  }, [id, type]);

  const { getShowUsersAction } = useGetUsersAction();
  const cardIcons: ActionProps[] = [
    getShowUsersAction({ id, totalUsers: totalUsers || 0 }),
  ];

  const { setLocalStorageItem: setUserInLs } = useLocalStorage<User>({
    key: StorageKeys.SET_USER,
  });
  const { setLocalStorageItem: setGroupInLs } = useLocalStorage<Group>({
    key: StorageKeys.SET_GROUP,
  });

  if (!label) return null;

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
      }}
    >
      <Chip
        avatar={
          <Avatar
            // TODO: Wouter wants to disable the onClick for now, because the modal is too minimal now for production.
            // We will enrich the modal in the future and then re-enable this onClick again.
            // onClick={(event) => {
            //   if (type === 'Group') {
            //     const group: Group = {
            //       displayName: label,
            //       id,
            //       totalUsers,
            //     };
            //     setGroupInLs(group);
            //     searchParams.set({ showGroup: id });
            //   } else {
            //     const user: User = {
            //       displayName: label,
            //       email,
            //       id,
            //       isAdmin,
            //       organization: { id: organizationId, name: organizationName },
            //     };
            //     setUserInLs(user);
            //     searchParams.set({ showUser: id });
            //   }
            //   event.stopPropagation();
            // }}
            onMouseEnter={(event) => debouncedOnMouseEnter(event.currentTarget)}
            onMouseLeave={() => {
              debouncedOnMouseEnter.cancel(); // Cancel the debounce on mouse leave.
              setAnchorEl(null);
            }}
            src={isLoading ? undefined : avatarSrc || undefined} // Leave the src empty on loading or an invalid URL
            sx={{
              // '&:hover': {
              //   opacity: 0.5,
              // },
              backgroundColor: customColors.grey500,
              fontSize: `${AvatarSizeXS.fontSize}px`,
              height: `${AvatarSizeXS.avatarSize}px`,
              width: `${AvatarSizeXS.avatarSize}px`,
              // cursor: 'pointer',
              // transition: 'opacity 0.3s',
            }}
          >
            <Typography sx={{ color: 'white' }}>
              {/* Somehow the color prop is overwritten, so we control it via `Typography` */}
              {/* Show the initials at least initially as a fallback during the image loading/validating */}
              {getInitials({ fullName: label })}
            </Typography>
          </Avatar>
        }
        label={
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            {label}
            {/* TODO: re-implement this after BE has finished endpoints to fetch all direct and nested users for a group. */}
            {/* {type === 'Group' && (
              <IconButton
                color="default"
                disableBorder
                iconName="info"
                onClick={() => searchParams.set({ showUsersFromGroup: id })}
              />
            )} */}
          </Box>
        }
        onDelete={onDelete}
        sx={{
          alignItems: 'center',
          border: hasBorder ? undefined : 'none',
          borderRadius: StylingDefaults.borderRadiusSmall,
          display: 'flex',
        }}
        variant="outlined"
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        id={id}
        open={open}
        sx={{ pointerEvents: 'none' }}
      >
        <Box sx={{ p: 2 }}>
          {type === IdentityTagType.Group && (
            <GroupCard
              // cardIcons={cardIcons} TODO: re-implement this after BE has finished endpoints to fetch all direct and nested users for a group.
              group={{
                displayName: label,
                id,
                totalUsers,
              }}
            />
          )}
          {type === IdentityTagType.User && (
            <UserCard label={label} userId={id} />
          )}
          {type === IdentityTagType.Project && (
            <ProjectCard label={label} projectId={id} />
          )}
        </Box>
      </Popover>
    </Box>
  );
};
