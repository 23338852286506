import { useDocument } from '@pro4all/documents/data-access';
import { Document, DocumentVersion, Folder } from '@pro4all/graphql';
import { useCardToggle } from '@pro4all/shared/ui/card-toggle';

import { DocumentProperties } from '../document-properties/DocumentProperties';

export const SectionProperties = ({
  folder,
  itemFallback,
}: {
  folder?: Folder;
  itemFallback: Document | DocumentVersion | null;
}) => {
  const { CardToggle, expanded } = useCardToggle({
    identifier: 'props',
    isFallback: true,
  });
  const { document, version } = useDocument();

  return (
    <CardToggle icon="info" label="Properties">
      {expanded ? (
        <DocumentProperties
          document={document ? (itemFallback as Document) : null}
          folder={folder}
          version={version ? (itemFallback as DocumentVersion) : null}
        />
      ) : null}
    </CardToggle>
  );
};
