import { useCardToggle } from '@pro4all/shared/ui/card-toggle';
import { useLinkedTasks } from '@pro4all/workflow/data-access';

import { TaskList } from '../shared';

export const SectionDocumentTasks = () => {
  const { CardToggle, expanded } = useCardToggle({ identifier: 'links' });
  const { linkedTasks, loading } = useLinkedTasks();

  const count = loading ? undefined : linkedTasks ? linkedTasks.length : 0;

  return (
    <CardToggle count={count} icon="assignmentOutlined" label="Document tasks">
      {expanded ? <TaskList /> : null}
    </CardToggle>
  );
};
