import { useTranslation } from 'react-i18next';

import { IntegrationWithConnection } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/actions';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useTableContextMenuRowContext } from '@pro4all/shared/ui/table';

export function useConnectionsActions() {
  const { hasAppPermission } = useAppPermissions();
  const { searchParams } = useRouting();
  const { contextMenuRow } =
    useTableContextMenuRowContext<IntegrationWithConnection>();
  const { id } = contextMenuRow?.integrationConnection || {};
  const { integrationType, id: integrationId } = contextMenuRow || {};

  const { t } = useTranslation();

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Delete'),
      dataTestId: 'delete-connection',
      disabled:
        !hasAppPermission('ProjectUpdate') ||
        !contextMenuRow?.integrationConnection,
      key: 'delete-connection',
      label: t('Delete'),
      onClick: () => searchParams.set({ action: 'deleteConnection', id }),
      startIcon: 'delete',
    },
    {
      ariaLabel: t('Edit'),
      dataTestId: 'edit-connection',
      disabled:
        !hasAppPermission('ProjectUpdate') ||
        Boolean(contextMenuRow?.integrationConnection),
      key: 'edit-connection',
      label: t('Edit'),
      onClick: () =>
        searchParams.set({
          action: 'editConnection',
          id: integrationId,
          integrationType,
        }),
      startIcon: 'edit',
    },
  ];

  return { altActions };
}
