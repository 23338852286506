import React, { createContext, useContext, useMemo, useState } from 'react';

import { useLocalStorage } from '@pro4all/shared/hooks';

interface SidePanelContextValue {
  setSidePanelIsFullscreen: (isModal: boolean) => void;
  sidePanelIsFullscreen: boolean;
}

export const SidePanelContext = createContext({} as SidePanelContextValue);

export const useSidePanelContext = () =>
  useContext<SidePanelContextValue>(SidePanelContext) || {
    setSidePanelIsFullscreen: null,
    sidePanelIsFullscreen: null,
  };

export const SidePanelContextProvider: React.FC = ({ children }) => {
  const { localStorageItem: sidenavIsModalLocalStorage } =
    useLocalStorage<boolean>({
      key: `sidenav-modal`,
    });

  const [sidePanelIsFullscreen, setSidePanelIsFullscreen] = useState(
    sidenavIsModalLocalStorage.constructor === Object
      ? false
      : sidenavIsModalLocalStorage
  );

  const value = useMemo(
    () => ({
      setSidePanelIsFullscreen,
      sidePanelIsFullscreen,
    }),
    [setSidePanelIsFullscreen, sidePanelIsFullscreen]
  );

  return (
    <SidePanelContext.Provider value={value}>
      {children}
    </SidePanelContext.Provider>
  );
};
