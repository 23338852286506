import React from 'react';

import {
  ReportConfiguration,
  useReportConfigurationQuery,
} from '@pro4all/graphql';
import { MapLinkingContextProvider } from '@pro4all/quality-control/ui/maps';
import { ReportingMain } from '@pro4all/quality-control/ui/report-options';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ReportConfigurationSidebarProps } from '@pro4all/shared/types';

export const ReportConfigurationSidebar = ({
  reportConfigurations,
}: ReportConfigurationSidebarProps) => {
  const { searchParams } = useRouting();
  const idSelectedReportConfiguration = searchParams.get('id');
  const createReportConfiguration = searchParams.is(
    'action',
    'createReportConfiguration'
  );

  // First get detailed data of the selected report configuration
  const { data } = useReportConfigurationQuery({
    fetchPolicy: 'network-only',
    skip: !idSelectedReportConfiguration,
    variables: {
      id: idSelectedReportConfiguration,
    },
  });

  const reportConfiguration = data?.reportConfiguration as ReportConfiguration;

  return data || createReportConfiguration ? (
    <MapLinkingContextProvider>
      <ReportingMain
        createReportConfiguration={createReportConfiguration}
        reportConfiguration={reportConfiguration}
        reportConfigurationSidebar
        reportConfigurations={reportConfigurations}
      />
    </MapLinkingContextProvider>
  ) : null;
};
