import { Trans, useTranslation } from 'react-i18next';

import { ObjectNode, ObjectProjectStatus } from '@pro4all/graphql';
import { useTheme } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

import * as Styled from './customIcons.styles';

export const useGetCustomIcons = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getCustomIcons = (item: ObjectNode) => {
    const { project, projectStatus, tbqType, tbqTypeAbstract } = item;

    const hasProject = projectStatus === ObjectProjectStatus.Linked;
    const hasTBQ = Boolean(tbqType);

    return (
      <Styled.Row>
        {hasProject && (
          <Styled.Tooltip
            title={t<string>('This object is linked to {{- projectName}}', {
              projectName: project?.name,
            })}
          >
            <Styled.LiteIcon
              color={theme.palette.primary.main}
              iconName="objectLinkActive"
            />
          </Styled.Tooltip>
        )}

        {hasTBQ && (
          <Styled.Tooltip
            title={
              <Trans
                i18nKey="TBQ.IsObject"
                values={{ type: `$t(TBQ.${tbqTypeAbstract})` }}
              >
                This object is a TBQ <strong>BuildingType</strong>.
              </Trans>
            }
          >
            <Styled.LiteIcon color={customColors.grey600} iconName="tbq" />
          </Styled.Tooltip>
        )}
      </Styled.Row>
    );
  };

  return { getCustomIcons };
};
