import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { FileUploadOverlay } from '@pro4all/shared/ui/file-upload';

const FileUploadOverlayWrapper = styled.div`
  height: 100%;
`;

type FileUploadWrapperProps = {
  children: React.ReactNode;
  getRootProps?: () => void;
  isActive: boolean;
};

export const FileUploadWrapper = ({
  children,
  getRootProps,
  isActive,
}: FileUploadWrapperProps) => {
  const rootProps = getRootProps ? getRootProps() : {};
  return (
    <FileUploadOverlayWrapper {...rootProps}>
      <FileUploadOverlay
        isActive={isActive}
        message="Let go to upload a new version"
        topMarginMessage={160}
        withStickyHeader={false}
      >
        <Box sx={{ width: '100%' }}>{children}</Box>
      </FileUploadOverlay>
    </FileUploadOverlayWrapper>
  );
};
