import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useDocument } from '@pro4all/documents/data-access';
import { Loader } from '@pro4all/shared/ui/loader';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { Sidebar } from '@pro4all/shared/ui/sidebar';

import { FileUploadWrapper } from './FileUploadWrapper';
import {
  SectionAiKeywords,
  SectionAiSummary,
  SectionComments,
  SectionDocumentTasks,
  SectionLog,
  SectionProperties,
  SectionTags,
  SectionVersions,
} from './sections';
import { DocumentDetailsSidebarProps } from './types';

const SidebarWrapper = styled.div<{ $fullHeight?: boolean }>`
  width: 100%;
  height: ${({ $fullHeight }) => ($fullHeight ? '100vh' : 'auto')};
`;

export const DocumentDetailsSidebar = ({
  folder,
  getRootProps,
  isActive,
  itemFallback,
  loadingFolderPermissions,
  onClose,
  sidebarActions,
}: DocumentDetailsSidebarProps) => {
  const { t } = useTranslation();

  const { document, error, loading } = useDocument();

  const title = `${itemFallback?.name} ${
    document?.isExpected ? `(${t('Expected document')}) ` : ''
  }`;

  const loaderMode = <Loader />;
  const documentDetailsMode = (
    <FileUploadWrapper getRootProps={getRootProps} isActive={isActive}>
      <SidebarWrapper $fullHeight={!document}>
        <ResponseWrapper error={error} isLoading={loading}>
          <Sidebar.Header title={title}>
            <Sidebar.SidebarActionBar
              showDivider
              sidebarActions={sidebarActions || []}
            />
          </Sidebar.Header>
          {itemFallback && (
            <>
              <SectionProperties folder={folder} itemFallback={itemFallback} />
              <SectionLog />
              <SectionVersions />
              <SectionAiSummary folder={folder} />
              <SectionAiKeywords folder={folder} />
              <SectionTags />
              <SectionDocumentTasks />
              <SectionComments />
            </>
          )}
        </ResponseWrapper>
      </SidebarWrapper>
    </FileUploadWrapper>
  );

  return (
    <Sidebar onClose={onClose ? onClose : () => null} open wide>
      {itemFallback === undefined || loadingFolderPermissions
        ? loaderMode
        : documentDetailsMode}
    </Sidebar>
  );
};
