import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const AddCollection: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g>
      <path d="M3.5 16.5C3.5 17.05 3.95 17.5 4.5 17.5H12.5C13 17.5 13.5 17.9477 13.5 18.5C13.5 19.0523 13 19.5 12.5 19.5H3.5C2.4 19.5 1.5 18.6 1.5 17.5V6.5C1.5 6 2 5.5 2.5 5.5C3 5.50009 3.5 6 3.5 6.5V16.5Z" />
      <path d="M20.5 5.5C21.05 5.5 21.5 5.95 21.5 6.5V10.5C21.5 11 21.0523 11.5 20.5 11.5C19.9477 11.5 19.5 11 19.5 10.5V6.5C19.5 5.95 19.95 5.5 20.5 5.5Z" />
      <path d="M5.5 11.5C5.5 10.9477 5.94772 10.5 6.5 10.5H9.5C10.0523 10.5 10.5 10.9477 10.5 11.5V14.5C10.5 15.0523 10.0523 15.5 9.5 15.5H6.5C5.94772 15.5 5.5 15.0523 5.5 14.5V11.5Z" />
      <path d="M12.5 11.5C12.5 10.9477 12.9477 10.5 13.5 10.5H15.0858C15.9767 10.5 16.4229 11.5771 15.7929 12.2071L14.2071 13.7929C13.5771 14.4229 12.5 13.9767 12.5 13.0858V11.5Z" />
      <path d="M5.5 4.5C5.5 3.94772 5.94772 3.5 6.5 3.5H9.5C10.0523 3.5 10.5 3.94772 10.5 4.5V7.5C10.5 8.05228 10.0523 8.5 9.5 8.5H6.5C5.94772 8.5 5.5 8.05228 5.5 7.5V4.5Z" />
      <path d="M16.625 19.125H18.875V21.375C18.875 21.9937 19.3813 22.5 20 22.5C20.6187 22.5 21.125 21.9937 21.125 21.375V19.125H23.375C23.9937 19.125 24.5 18.6187 24.5 18C24.5 17.3813 23.9937 16.875 23.375 16.875H21.125V14.625C21.125 14.0063 20.6187 13.5 20 13.5C19.3813 13.5 18.875 14.0063 18.875 14.625V16.875H16.625C16.0063 16.875 15.5 17.3813 15.5 18C15.5 18.6187 16.0063 19.125 16.625 19.125Z" />
    </g>
  </SvgIcon>
);
