import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useDeleteGroups } from '../useDeleteGroups';

export const ProjectGroupsActionBar: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const { hasAppPermission } = useAppPermissions();
  const userCannotUpdateProject = !hasAppPermission('ProjectUpdate');
  const deleteGroups = useDeleteGroups();

  const { ACTION_CREATE, ACTION_DELETE } = useCentralActions();

  const altActions: ActionProps[] = [
    {
      ...ACTION_DELETE,
      dataTestId: 'delete-group',
      disabled: userCannotUpdateProject,
      key: 'delete-group',
      onClick: deleteGroups,
    },
  ];

  const mainActions: ActionProps[] = [
    {
      ...ACTION_CREATE,
      ariaLabel: t('Create group'),
      dataTestId: 'create-group',
      disabled: userCannotUpdateProject,
      key: 'create-group',
      label: t('Create group'),
      onClick: () => {
        searchParams.delete('name');
        searchParams.set({ action: 'createGroup' });
      },
      startIcon: 'groupAdd',
    },
  ];

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="groups-action-bar"
      mainActions={mainActions}
      search
    />
  );
};
