import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Folder } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useApplyTemplateToFolder = (folder: Folder) => {
  const { searchParams } = useRouting();
  const { ACTION_APPLY_METADATA_TEMPLATE } = useCentralActions();

  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });

  const openMetaSidebar = () => {
    searchParams.set({
      action: 'applyTemplate',
      folder: folder?.name,
    });
  };

  const applyTemplateMenuItem: ActionProps = {
    ...ACTION_APPLY_METADATA_TEMPLATE,
    disabled: !hasFolderPermission('UpdateFolder'),
    onClick: openMetaSidebar,
  };

  return { applyTemplateMenuItem };
};
