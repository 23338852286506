import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/actions';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Icon } from '@pro4all/shared/ui/icons';
import { IconName } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';

import * as Styled from './ItemCard.styles';
import { BasePropsHierarchyItem, CallBackProps, ItemProps } from './types';
import { useItemCardDragAndDrop } from './useItemCardDragAndDrop';

type ItemCardProps<HierarchyItem extends BasePropsHierarchyItem> = {
  hasChildren: boolean;
  itemActions: ActionProps[];
} & Pick<CallBackProps<HierarchyItem>, 'callbackDrag' | 'callbackDrop'> &
  Pick<
    ItemProps<HierarchyItem>,
    'editItem' | 'item' | 'items' | 'level' | 'moveItem'
  >;

const ItemCardEditComponent = <HierarchyItem extends BasePropsHierarchyItem>({
  callbackDrag,
  callbackDrop,
  editItem,
  hasChildren,
  item,
  items,
  itemActions,
  level,
  moveItem,
}: ItemCardProps<HierarchyItem>) => {
  const { t } = useTranslation();

  const {
    dragCard,
    dropOnCard,
    dropOnCardBottomMargin,
    hoverOverCard,
    hoverOverCardBottomMargin,
    levelDropZone,
  } = useItemCardDragAndDrop({
    callbackDrag,
    callbackDrop,
    item,
    items,
    level,
    moveItem,
  });

  const expandCollapseTooltip = item.expanded ? t('Collapse') : t('Expand');

  return (
    <>
      {dragCard(
        dropOnCard(
          <div>
            {hoverOverCard && <Styled.DropZone level={levelDropZone} />}
            <Styled.Item editMode level={level}>
              <Styled.Header
                onClick={() =>
                  hasChildren && editItem({ ...item, expanded: !item.expanded })
                }
                selected={false}
              >
                <Styled.Title>
                  <Text variant="h5">{item.name}</Text>
                </Styled.Title>
                <Styled.ActionsWrapper>
                  <Styled.Actions>
                    {itemActions.map((action, index) => (
                      <Tooltip
                        key={index}
                        placement="bottom"
                        title={action.label || ''}
                      >
                        <IconButton
                          aria-label={action.ariaLabel}
                          color="default"
                          disableBorder
                          disabled={action.disabled}
                          iconName={action.startIcon as IconName}
                          onClick={action.onClick}
                        />
                      </Tooltip>
                    ))}
                  </Styled.Actions>
                  <Styled.ExpandCollapse>
                    {hasChildren && (
                      <Tooltip placement="bottom" title={expandCollapseTooltip}>
                        <Icon
                          iconName={item.expanded ? 'expandLess' : 'expandMore'}
                        />
                      </Tooltip>
                    )}
                  </Styled.ExpandCollapse>
                </Styled.ActionsWrapper>
              </Styled.Header>
            </Styled.Item>
          </div>
        )
      )}
      {dropOnCardBottomMargin(
        <div>
          {hoverOverCardBottomMargin && (
            <Styled.DropZone bottomMarginDropZone level={level + 1} />
          )}
          <Styled.ItemBottomMargin level={level} />
        </div>
      )}
    </>
  );
};

const skipUpdate = <HierarchyItem extends BasePropsHierarchyItem>(
  prevProps: ItemCardProps<HierarchyItem>,
  nextProps: ItemCardProps<HierarchyItem>
) => {
  const pasteActionPrev = prevProps.itemActions.find(
    (action) => action.key === 'paste-item'
  );
  const pasteActionNext = nextProps.itemActions.find(
    (action) => action.key === 'paste-item'
  );

  return (
    pasteActionPrev?.disabled === pasteActionNext?.disabled && // Paste action might be turned from enabled to disabled or vice versa.
    prevProps.item.name === nextProps.item.name && // Name of the item might have been changed.
    prevProps.item.expanded === nextProps.item.expanded && // Item might have been expanded or collapsed.
    prevProps.item.previousNodeId === nextProps.item.previousNodeId && // Item might be moved.
    prevProps.item.parentNodeId === nextProps.item.parentNodeId && // Item might be moved to another parent.
    prevProps.hasChildren === nextProps.hasChildren && // Item might have children or all children are removed.
    prevProps.level === nextProps.level // Item might be moved to a different level which has impact on the indent.
  );
};

export const ItemCardEdit = React.memo(
  ItemCardEditComponent,
  skipUpdate
) as typeof ItemCardEditComponent;
