import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import {
  Member,
  ScopeType,
  useGroupsAndUsersIncludeQuery,
} from '@pro4all/graphql';
import { useMemberOptions } from '@pro4all/identity/ui';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useAi } from '@pro4all/shared/hooks';
import { Box, Typography } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FormFooter } from '@pro4all/shared/ui/form';
import {
  FormikCheckbox,
  FormikDate,
  FormikForm,
  FormikInput,
  FormikSearchableMultiSelect,
  FormikTextarea,
} from '@pro4all/shared/ui/formik';
import { useSelectUsersAndGroupsDropdownSources } from '@pro4all/shared/ui/identity-card';
import { DiscardWrapper } from '@pro4all/shared/ui/messages';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { FormFields, ProjectType } from './types';
import { useInitialValues } from './useInitialValues';
import { useProjectFormConfig } from './useProjectFormConfig';
import { useSubmit } from './useSubmit';

export const ProjectForm = ({
  project,
  showCancel = true,
}: {
  project?: ProjectType;
  showCancel?: boolean;
}) => {
  const { t } = useTranslation();
  const { searchParams, params } = useRouting();
  const createMode = searchParams.is('action', 'createProject');
  const inputRef = useRef<HTMLInputElement>(null);

  const { data } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      fetchInvitedUsers: true,
      includeActive: true,
      includeEmail: true,
      includeInvited: true,
    },
  });
  const members = (data?.groupsAndUsers as Member[]) || [];

  const options = useMemberOptions(members, {
    includeInactive: false,
  });

  const { getField, validationSchema } = useProjectFormConfig();
  const nameField = getField('name');
  const projectNumberField = getField('projectNumber');
  const descriptionField = getField('description');
  const startDateField = getField('startDate');
  const deadlineField = getField('deadline');
  const adminsField = getField('admins');
  const membersField = getField('members');
  const enableAiField = getField('settings.ai_admin_settings');

  const {
    noOptionsText,
    optionsToPassIn,
    renderCustomOption,
    renderCustomInput,
  } = useSelectUsersAndGroupsDropdownSources({
    options,
  });

  const onSubmit = useSubmit();

  const { aiEnabledProject, aiEnabledOverall } = useAi(ScopeType.Project);

  const initialValues = useInitialValues({
    aiEnabledProject,
    objectId: params.objectId,
    project,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onReset={() => inputRef?.current?.focus()}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formikHelpers) => {
        const { dirty, errors, isSubmitting, values } = formikHelpers;
        const iaLabelDuplicated = t(
          "Enable Prostream AI for organisation '{{orgName}}'",
          {
            orgName: values[nameField.name as keyof FormFields],
          }
        );
        const changeNameOnProject =
          values[nameField.name as keyof FormFields].length > 0 && !showCancel;
        return (
          <DiscardWrapper dirty={dirty}>
            <FormikForm>
              <FormWrapper>
                <Box sx={{ mt: 1 }}>
                  <FormikInput
                    autoFocus
                    inputRef={inputRef}
                    label={nameField.label}
                    name={nameField.name}
                    placeholder={t('Name')}
                  />
                </Box>
                <FormikInput
                  label={projectNumberField.label}
                  name={projectNumberField.name}
                />
                <FormikTextarea
                  id={descriptionField.name}
                  label={descriptionField.label}
                  name={descriptionField.name}
                  rows={4}
                />
                <FormikDate
                  label={startDateField.label}
                  name={startDateField.name}
                />
                <FormikDate
                  label={deadlineField.label}
                  name={deadlineField.name}
                />
                {createMode && (
                  <>
                    <FormikSearchableMultiSelect
                      label={adminsField.label}
                      name={adminsField.name}
                      noOptionsText={noOptionsText}
                      options={optionsToPassIn}
                      placeholder={t('Admins')}
                      renderCustomInput={renderCustomInput}
                      renderCustomOption={renderCustomOption}
                    />
                    <FormikSearchableMultiSelect
                      label={membersField.label}
                      name={membersField.name}
                      noOptionsText={noOptionsText}
                      options={optionsToPassIn}
                      placeholder={t('Members')}
                      renderCustomInput={renderCustomInput}
                      renderCustomOption={renderCustomOption}
                    />
                  </>
                )}
                {aiEnabledOverall && (
                  <>
                    <FormikCheckbox
                      label={
                        showCancel || changeNameOnProject
                          ? iaLabelDuplicated
                          : enableAiField.label
                      }
                      name={enableAiField.name}
                    />
                    <Typography>
                      {t(
                        "Enable the AI features for this project. This does not implicate your other organisation's projects."
                      )}
                    </Typography>
                  </>
                )}
              </FormWrapper>
              <FormFooter
                disableSubmit={isSubmitDisabled({
                  dirty,
                  errors,
                  isSubmitting,
                })}
                onClose={() => {
                  searchParams.clear();
                }}
                pb={3}
                pt={2}
                px={3}
                showCancel={showCancel}
              />
            </FormikForm>
          </DiscardWrapper>
        );
      }}
    </Formik>
  );
};
