import React from 'react';
import styled from 'styled-components';

import { Task } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Loader } from '@pro4all/shared/ui/loader';

import { TaskList } from './TaskList';

interface TaskListDisplayProps {
  getTaskType: (task: Task) => string;
  loading: boolean;
  noTasksMessage: string;
  onTaskClick: (taskId: string) => void;
  taskTypeEnum: Record<string, string>; // Enum type for task type (e.g., DocumentAction, TaskType)
  tasks: Task[];
}

export const TaskListDisplay: React.FC<TaskListDisplayProps> = ({
  loading,
  tasks,
  noTasksMessage,
  taskTypeEnum,
  getTaskType,
  onTaskClick,
}) =>
  loading ? (
    <LoadingBox>
      <Loader />
    </LoadingBox>
  ) : (
    <TaskList<Task>
      getTaskType={getTaskType}
      isCollapsible
      noTasksMessage={noTasksMessage}
      onTaskClick={onTaskClick}
      taskTypeEnum={taskTypeEnum}
      tasks={tasks}
    />
  );

export default TaskListDisplay;

const LoadingBox = styled(Box)`
  height: auto;
  padding-top: ${({ theme }) => theme.spacing(4)};
  display: flex;
  justify-content: center;
`;
