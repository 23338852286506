import { useDocument } from '@pro4all/documents/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';

import { DocumentTags } from '../document-properties/tags/DocumentTags';

export const Tags = () => {
  const { document } = useDocument();
  const { searchParams } = useRouting();
  const versionId = searchParams.get('versionid');

  return document && !versionId ? <DocumentTags document={document} /> : null;
};
