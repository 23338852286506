import { useDocument } from '@pro4all/documents/data-access';
import { Folder } from '@pro4all/graphql';
import { Loader } from '@pro4all/shared/ui/loader';

import { Properties } from './shared/Properties';

export const useDocumentFullscreenLeft = ({ folder }: { folder?: Folder }) => {
  const { loading } = useDocument();
  const leftContent = loading ? <Loader /> : <Properties folder={folder} />;

  return { leftContent };
};
