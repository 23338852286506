import { useEffect, useRef, useState } from 'react';

import { DocumentService } from '@pro4all/documents/data-access';
import {
  ConversionStatus,
  Document,
  DocumentVersion,
  Maybe,
  useThreeDConversionStatusesQuery,
} from '@pro4all/graphql';
import { PreviewImg } from '@pro4all/shared/ui/image-preview';
import {
  isSupportedPdfViewerExtension,
  use3DExtension,
} from '@pro4all/shared/utils';

export const useSetDocumentPreview = ({
  document,
  open,
  version,
}: {
  document: Document | null;
  open: boolean;
  version: DocumentVersion | null;
}) => {
  const isMounted = useRef(false);
  const [previewImages, setPreviewImages] = useState<PreviewImg[]>([]);
  const [documentIsImage, setDocumentIsImage] = useState(false);
  const [slidesLoaded, setSlidesLoaded] = useState(false);
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [show3DViewer, setShow3DViewer] = useState(false);
  const [urn, setUrn] = useState<string>('');

  const { isSupported3DExtension } = use3DExtension();

  const currentVersion = version
    ? version
    : document?.versions?.find((version) => version?.id === document.versionId);
  const totalPreviewPages = currentVersion?.totalPreviewPages || 0;

  const documentId =
    (document && document.id) || (version && version.documentId) || '';

  const versionId =
    (document && document.versionId) || (version && version.id) || '';

  const extension =
    document && document.extension
      ? document.extension
      : version && version.extension
      ? version.extension
      : '';

  const { data } = useThreeDConversionStatusesQuery({
    skip: !isSupported3DExtension(extension),
    variables: { versionIds: versionId },
  });

  useEffect(() => {
    const urns =
      data?.threeDConversionStatuses
        ?.filter((docStatus) => docStatus?.status === ConversionStatus.Success)
        ?.map((docStatus) => docStatus?.fileUrn) || [];
    const urn = urns[0] ? urns[0] : '';
    setUrn(urn);
  }, [data]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  useEffect(() => {
    const documentVersion: Maybe<DocumentVersion> | undefined =
      document?.versions?.find(
        (version) => version?.id && version?.id === versionId
      );

    let loading = false;

    const getNextImage = async () => {
      loading = true;

      const previewImg: PreviewImg = {
        src: documentIsImage
          ? await DocumentService.getBlobForVersion({
              documentId: document?.id || '',
              id: documentVersion?.id || '',
            })
          : await DocumentService.getPreviewImage({
              documentId,
              page: previewImages.length,
              versionId,
            }),
      };

      // Is the component still mounted after our async call?
      // Otherwise setting the state throws an error
      if (isMounted.current) {
        loading = false;
        const slides = [...previewImages, previewImg];
        setPreviewImages(slides);
        if (slides.length === totalPreviewPages) {
          setSlidesLoaded(true);
        }
      }
    };

    documentVersion?.totalPreviewPages === 1 &&
    documentVersion?.extension &&
    ['jpg', 'jpeg', 'png'].indexOf(documentVersion?.extension.toLowerCase()) !==
      -1
      ? setDocumentIsImage(true)
      : setDocumentIsImage(false);

    if (documentVersion?.extension) {
      const extension = documentVersion.extension.toLowerCase();
      setShowPdfViewer(isSupportedPdfViewerExtension(extension));
      setShow3DViewer(
        isSupported3DExtension(extension) &&
          documentVersion?.threeDConversionState?.status ===
            ConversionStatus.Success
      );
    }

    if (
      !showPdfViewer &&
      !show3DViewer &&
      isMounted.current &&
      open &&
      !loading &&
      previewImages.length < totalPreviewPages
    ) {
      getNextImage();
    }
  }, [
    document,
    documentId,
    documentIsImage,
    isMounted,
    isSupported3DExtension,
    open,
    show3DViewer,
    showPdfViewer,
    previewImages,
    totalPreviewPages,
    versionId,
  ]);

  return {
    currentVersion,
    documentIsImage,
    previewImages,
    setPreviewImages,
    show3DViewer,
    showPdfViewer,
    slidesLoaded,
    totalPreviewPages,
    urn,
  };
};
