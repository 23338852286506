import { Folder } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';

import {
  SectionAiKeywords,
  SectionAiSummary,
  SectionComments,
  SectionDocumentTasks,
  SectionLog,
  SectionTags,
  SectionVersions,
} from './sections';

export const useDocumentFullscreenRight = ({ folder }: { folder?: Folder }) => {
  const rightContent = (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <SectionLog />
      <SectionVersions />
      <SectionAiSummary folder={folder} />
      <SectionAiKeywords folder={folder} />
      <SectionTags />
      <SectionDocumentTasks />
      <SectionComments />
    </Box>
  );

  return { rightContent };
};
