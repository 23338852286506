import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { actionGroups, useGroupedActions } from '@pro4all/shared/actions';

import { ResponsiveActionProps } from '../layoutFrameTypes';

export const useResponsiveActions = ({ actions }: ResponsiveActionProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const totalWidthPreviousRef = useRef(0);
  const [mediumGroups, setMediumGroups] = useState<string[]>([]);
  const [smallGroups, setSmallGroups] = useState<string[]>([]);
  const { getGroupedActions } = useGroupedActions();

  const groupedActions = getGroupedActions({
    actions: actions ? actions : [],
  });

  const calculateResponsiveness = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    const containerWidth = containerRef.current.offsetWidth;
    if (containerWidth === totalWidthPreviousRef.current) {
      return; // No change in container width, no action needed
    }

    const totalWidth = Array.from(containerRef.current.children).reduce(
      (acc, child: any) => acc + child.offsetWidth,
      0
    );

    // Action groups in order of responsiveness switching
    const groupKeys = Object.keys(groupedActions);
    const groupKeysInOrder = groupKeys.sort((a, b) => {
      const groupA = actionGroups.find((group) => group.key === a);
      const groupB = actionGroups.find((group) => group.key === b);
      if (!groupA || !groupB) {
        throw new Error('Key not found in actionGroups.');
      }
      return groupA.groupOrderResponsiveness - groupB.groupOrderResponsiveness;
    });

    if (totalWidth > containerWidth) {
      // Switch from large to medium
      for (const group of groupKeysInOrder) {
        if (
          groupedActions[group] &&
          groupedActions[group].length > 1 &&
          !mediumGroups.includes(group) &&
          !smallGroups.includes(group) // Not yet in small
        ) {
          setMediumGroups((prev) => [...prev, group]);
          totalWidthPreviousRef.current = totalWidth;
          return;
        }
      }

      // Switch from medium to small
      for (const group of groupKeysInOrder) {
        if (
          mediumGroups.includes(group) &&
          !smallGroups.includes(group) // Only medium groups can go to small.
        ) {
          setSmallGroups((prev) => [...prev, group]);
          setMediumGroups((prev) => prev.filter((g) => g !== group));
          totalWidthPreviousRef.current = totalWidth;
          return;
        }
      }
    } else if (
      containerWidth > totalWidthPreviousRef.current &&
      totalWidthPreviousRef.current > 0
    ) {
      // Switch from small to medium
      for (const group of [...groupKeysInOrder].reverse()) {
        if (smallGroups.includes(group)) {
          setSmallGroups((prev) => prev.filter((g) => g !== group));
          setMediumGroups((prev) => [...prev, group]);
          return;
        }
      }

      // Switch from medium to large
      for (const group of [...groupKeysInOrder].reverse()) {
        if (mediumGroups.includes(group)) {
          setMediumGroups((prev) => prev.filter((g) => g !== group));
          return;
        }
      }
    }
  }, [groupedActions, mediumGroups, smallGroups]);

  useEffect(() => {
    const calculateResponsivenessDebounced = debounce(() => {
      calculateResponsiveness();
    }, 100);

    const observer = new ResizeObserver(() =>
      calculateResponsivenessDebounced()
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
      calculateResponsiveness(); // Iniitially calculate
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
      calculateResponsivenessDebounced.cancel(); // Cancel debounce timer
    };
  }, [calculateResponsiveness]);

  return { containerRef, groupedActions, mediumGroups, smallGroups };
};
