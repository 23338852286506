import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/actions';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { ActionBar as ActionBarOld } from '@pro4all/shared/ui/action-bar';
import { Button } from '@pro4all/shared/ui/buttons';
import { DataViewContextToggle } from '@pro4all/shared/ui/data-view';
import {
  PredefinedFilter,
  PredefinedFilterSelect,
  useFilterContext,
} from '@pro4all/shared/ui/filtering';
import { ActionBar } from '@pro4all/shared/ui/layout';
import { TableSearch } from '@pro4all/shared/ui/table';
import { useTableCheck } from '@pro4all/shared/ui/table';

import { useExportActions } from './export-table/useExportActions';
import * as Styled from './TableActionBar.styles';

interface Props {
  altActions?: ActionProps[];
  dataTestid?: string;
  dataViewToggle?: boolean;
  mainActions?: ActionProps[];
  predefinedFilters?: PredefinedFilter[];
  search?: boolean;
  showDivider?: boolean;
  showExportButton?: boolean;
  showTableActions?: boolean;
}

// useTableCheck activates the checkboxes in the context. This way we only activate them when needed.
const TableActionBarWithChecks: React.FC<Props> = ({
  altActions = [],
  mainActions = [],
  search = false,
  showDivider = false,
  showTableActions = true,
}) => {
  const { anyRowsAreChecked } = useTableCheck();
  const hasFullScreenView = useFeatureFlag('fullscreen-view');

  return showTableActions ? (
    hasFullScreenView ? (
      <ActionBar
        actions={anyRowsAreChecked ? altActions : mainActions}
        showDivider={showDivider}
      />
    ) : (
      <ActionBarOld
        altActions={altActions}
        mainActions={mainActions}
        search={search}
        showAltBar={anyRowsAreChecked}
        showTableActions={showTableActions}
      />
    )
  ) : null;
};

export const TableActionBar: React.FC<Props> = ({
  altActions = [],
  dataViewToggle = false,
  mainActions = [],
  dataTestid = '',
  predefinedFilters,
  search = false,
  showDivider = false,
  showExportButton = true,
  showTableActions = true, // For the document select in the upload editor!
}) => {
  const { filters, removeAllFilters } = useFilterContext() || {};
  const { t } = useTranslation();

  const predefinedFilterEnabled = useFeatureFlag('predefined-table-filters');
  const hasFullScreenView = useFeatureFlag('fullscreen-view');

  const exportButton = useExportActions();

  // No actions, no checkedActions, no search and no dataviewToggle
  if (
    !mainActions?.length &&
    !altActions?.length &&
    !search &&
    !dataViewToggle
  ) {
    return null;
  }

  return (
    <Styled.Wrapper
      $hasFullScreenView={hasFullScreenView}
      data-testid={dataTestid}
    >
      <Box sx={{ flexGrow: '1' }}>
        {altActions.length ? (
          // Checkboxes are automatically added to the table in case there are row related actions (altActions).
          <TableActionBarWithChecks
            altActions={altActions}
            mainActions={mainActions}
            search={search}
            showDivider={showDivider}
            showTableActions={showTableActions}
          />
        ) : hasFullScreenView ? (
          <ActionBar actions={mainActions} showDivider={showDivider} />
        ) : (
          <ActionBarOld
            mainActions={mainActions}
            search={search}
            showTableActions={showTableActions}
          />
        )}
      </Box>
      {search && hasFullScreenView && (
        <Box
          sx={{
            alignSelf: 'end',
            display: { sm: 'flex', xs: 'none' },
            height: '100%',
          }}
        >
          <TableSearch />
        </Box>
      )}
      <Styled.GenericTableActionsWrapper>
        {predefinedFilters && predefinedFilterEnabled && (
          <Box sx={{ minWidth: '150px' }}>
            <PredefinedFilterSelect
              dataTestId={`${dataTestid}-predefined-filter`}
              predefinedFilters={predefinedFilters}
            ></PredefinedFilterSelect>
          </Box>
        )}
        {filters?.length ? (
          <Button
            color="inherit"
            data-testid="reset-filters"
            onClick={removeAllFilters}
            startIcon="reset"
          >
            {t('Clear filters')}
          </Button>
        ) : null}
        {showExportButton ? exportButton : null}
        {dataViewToggle && <DataViewContextToggle />}
      </Styled.GenericTableActionsWrapper>
    </Styled.Wrapper>
  );
};
