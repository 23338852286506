import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { Action, TrackingEvent } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { isSupportedPdfViewerExtension } from '@pro4all/shared/utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import { DocumentActionProps } from '../../types';

export const useDocumentToolsAction = ({
  allDocumentsExpected,
  sidebarRow,
}: Pick<DocumentActionProps, 'allDocumentsExpected' | 'sidebarRow'>) => {
  const { goTo } = useRouting();
  const { track } = useAnalytics();
  const { ACTION_OPEN_IN_APRYSE } = useCentralActions();

  const isSupported =
    sidebarRow?.extension &&
    isSupportedPdfViewerExtension(sidebarRow?.extension);

  const openDocumentTools = () => {
    track(TrackingEvent.DocumentViewed, {
      action: Action.SidebarButtonClicked,
    });
    goTo({
      searchParams: {
        annotate: true,
        fullscreen: true,
      },
    });
  };

  const documentToolsAction: ActionProps = {
    ...ACTION_OPEN_IN_APRYSE,
    disabled: !isSupported || allDocumentsExpected,
    onClick: openDocumentTools,
  };

  return documentToolsAction;
};
