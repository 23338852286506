import React from 'react';

import { actionGroups, ActionProps } from '@pro4all/shared/actions';
import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

import { ActionBarProps } from '../layoutFrameTypes';

import { Action, ActionModes } from './Action';
import { useResponsiveActions } from './useResponsiveActions';

export const ActionBar = ({ actions, showDivider = false }: ActionBarProps) => {
  const { containerRef, groupedActions, mediumGroups, smallGroups } =
    useResponsiveActions({
      actions,
    });

  return (
    <Box
      ref={containerRef}
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        overflowX: 'auto',
      }}
    >
      {Object.entries(groupedActions).map(([groupKey, groupActions], index) => {
        const isMedium = mediumGroups.includes(groupKey);
        const isSmall = smallGroups.includes(groupKey);
        const actionGroup = actionGroups.find((g) => g.key === groupKey);
        const groupLabel = actionGroup?.label || '';

        const actionGroupAction: ActionProps = {
          label: groupLabel,
          startIcon: actionGroup?.icon,
          subItems: groupActions,
        };

        return (
          <Box
            key={groupKey}
            sx={{
              alignItems: 'center',
              borderRight:
                index === Object.entries(groupedActions).length - 1 ||
                !showDivider
                  ? 'none'
                  : `1px solid ${customColors.grey400}`,
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 0,
              flexShrink: 0,
              height: '100%',
              justifyContent: 'space-between',
              overflowY: 'hidden',
            }}
          >
            <Box
              sx={{
                // display: isSmall ? 'flex' : isMedium ? 'block' : 'flex',
                display: 'flex',
                gap: 1,
              }}
            >
              {isSmall || isMedium ? (
                <Action
                  action={actionGroupAction}
                  key={groupLabel}
                  mode={ActionModes.Large}
                />
              ) : (
                groupActions?.map((action) => (
                  <Action
                    action={action}
                    key={action.label}
                    mode={isMedium ? ActionModes.Medium : ActionModes.Large}
                  />
                ))
              )}
            </Box>
            {/* {!isSmall && groupActions && groupActions.length > 1 && (
              <Box
                sx={{
                  fontSize: '12px',
                  mt: 1,
                  textAlign: 'center',
                }}
              >
                {groupLabel}
              </Box>
            )} */}
          </Box>
        );
      })}
    </Box>
  );
};
