import {
  useDocument,
  useUserFolderPermissions,
} from '@pro4all/documents/data-access';
import { FinalizationState, Folder, ScopeType } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useAi } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';

import { AiSummary } from '../document-properties/ai-summary/AiSummary';

export const AiDocumentSummary = ({ folder }: { folder?: Folder }) => {
  const { document, version } = useDocument();
  const {
    params: { projectId },
  } = useRouting();
  const hasAiSummaryFeatureFlag = useFeatureFlag('ai-document-summary');

  const { aiEnabledContext } = useAi(
    projectId ? ScopeType.Project : ScopeType.Organization
  );

  const { canUpdateDocumentOrVersion } = useUserFolderPermissions({
    folderId: folder?.id || document?.folderId || '',
  });

  const canEdit = canUpdateDocumentOrVersion({
    document: document ? document : null,
    version: version ? version : null,
  });

  const isFinalized = document
    ? document.state === FinalizationState.Finalized
    : version
    ? version.state === FinalizationState.Finalized
    : false;

  const versionIds = document
    ? [document.versionId || '']
    : version
    ? [version.id]
    : [];

  return (document || version) &&
    hasAiSummaryFeatureFlag &&
    aiEnabledContext &&
    Boolean(!document?.isExpected) ? (
    <AiSummary
      document={document}
      editable={canEdit}
      isFinalized={isFinalized}
      version={version}
      versionId={versionIds[0]}
    />
  ) : null;
};
