import { useCollectionFolder } from '@pro4all/documents/ui/folders';
import { Document } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';

interface Props {
  allDocumentsExpected: boolean;
  isVersion: boolean;
  selection: Document[];
}

export const useCollectionFolderActions = ({
  isVersion,
  selection,
  allDocumentsExpected,
}: Props) => {
  const { ACTION_COLLECTION_ADD, ACTION_COLLECTION_REMOVE } =
    useCentralActions();
  const { addDocuments, allInCollection, removeDocuments, isInCollection } =
    useCollectionFolder();

  const addDocumentToCollection: ActionProps = {
    ...ACTION_COLLECTION_ADD,
    disabled: isVersion || allInCollection(selection) || allDocumentsExpected,
    onClick: () => addDocuments(selection),
  };

  const removeDocumentsFromCollection: ActionProps = {
    ...ACTION_COLLECTION_REMOVE,
    disabled: isVersion || !isInCollection(selection),
    onClick: () => removeDocuments(selection.map((item) => item.id)),
  };

  return {
    addDocumentToCollection,
    removeDocumentsFromCollection,
  };
};
