import { useEffect, useState } from 'react';

import { useIsMobileScreen } from '@pro4all/shared/themes';
import { Resizable } from '@pro4all/shared/ui/general';

import { ForgeViewer } from './viewers/forge/ForgeViewer';
import { CollapseContainer, CollapseIcon } from './Documents3DViewer.styles';

type Use3DViewerResizerProps = {
  viewer: ForgeViewer | null;
};

export const use3DViewerResizer = ({ viewer }: Use3DViewerResizerProps) => {
  const isMobileScreen = useIsMobileScreen();
  const [showSideNav, setShowSideNav] = useState<boolean>(!isMobileScreen);
  const [sideNavWidth, setSideNavWidth] = useState<number>(300);

  useEffect(() => {
    setShowSideNav(!isMobileScreen);
  }, [isMobileScreen]);

  const toggleOpen = () => {
    setShowSideNav(!showSideNav);
    // Timeout required since collapsible
    setTimeout(() => {
      viewer?.resize();
    }, 200);
  };

  const handleSideNavResize = (width: number) => {
    setSideNavWidth(width);
  };

  const handleSideNavResizeEnd = () => {
    viewer?.resize();
  };

  const TogglePane = ({ children }: { children: React.ReactNode }) =>
    showSideNav ? (
      <Resizable
        initialWidth={sideNavWidth}
        onResize={handleSideNavResize}
        onResizeEnd={handleSideNavResizeEnd}
        threshold={0}
      >
        {children}
      </Resizable>
    ) : null;

  const CollapseBar = () => (
    <CollapseContainer onClick={toggleOpen}>
      <CollapseIcon
        iconName={showSideNav ? 'expandLess' : 'expandMore'}
      ></CollapseIcon>
    </CollapseContainer>
  );

  return { CollapseBar, TogglePane };
};
