import React, { useContext } from 'react';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  SubscriptionLevelsDataResponse,
  SubscriptionOrganizationDataResponse,
  SubscriptionOrganizationUsersDataResponse,
  useSubscriptionLevelsQuery,
  useSubscriptionOrganizationQuery,
  useSubscriptionOrganizationUsersQuery,
} from '@pro4all/graphql';

type SubscriptionContextValue = {
  dataLevels?: SubscriptionLevelsDataResponse;
  dataOrg?: SubscriptionOrganizationDataResponse;
  dataOrgUsers?: SubscriptionOrganizationUsersDataResponse;
  loadingLevels: boolean;
  loadingOrg: boolean;
  loadingOrgUsers: boolean;
};

const SubscriptionContext = React.createContext<SubscriptionContextValue>({
  dataLevels: undefined,
  dataOrg: undefined,
  dataOrgUsers: undefined,
  loadingLevels: false,
  loadingOrg: false,
  loadingOrgUsers: false,
});

export function useSubscriptionContext() {
  return useContext(SubscriptionContext);
}

export const SubscriptionContextProvider: React.FC = ({ children }) => {
  const isAuthenticated = AuthService.isAuthenticated();

  const { data: dataLevels, loading: loadingLevels } =
    useSubscriptionLevelsQuery({
      fetchPolicy: 'cache-and-network',
      skip: !isAuthenticated,
    });
  const { data: dataOrg, loading: loadingOrg } =
    useSubscriptionOrganizationQuery({
      fetchPolicy: 'cache-and-network',
      skip: !isAuthenticated,
    });
  const { data: dataOrgUsers, loading: loadingOrgUsers } =
    useSubscriptionOrganizationUsersQuery({
      fetchPolicy: 'cache-and-network',
      skip: !isAuthenticated,
    });

  const subscriptionContextValue = {
    dataLevels: dataLevels?.subscriptionLevels || undefined,
    dataOrg: dataOrg?.subscriptionOrganization || undefined,
    dataOrgUsers: dataOrgUsers?.subscriptionOrganizationUsers || undefined,
    loadingLevels,
    loadingOrg,
    loadingOrgUsers,
  };

  return (
    <SubscriptionContext.Provider value={subscriptionContextValue}>
      {children}
    </SubscriptionContext.Provider>
  );
};
