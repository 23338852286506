import { useTranslation } from 'react-i18next';
import { useClipboard } from 'use-clipboard-copy';

import { ActionProps } from '@pro4all/shared/actions';
import { Typography } from '@pro4all/shared/mui-wrappers';
import { DocumentAndVersionType } from '@pro4all/shared/types';
import { AiFrame, AiLoader } from '@pro4all/shared/ui/ai';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useAiSummary } from './useAISummary';

export interface AiSummaryProps {
  editable?: boolean;
  isFinalized: boolean;
  versionId?: string | undefined;
}

export const AiSummary = ({
  document,
  editable,
  isFinalized,
  version,
  versionId,
}: AiSummaryProps & DocumentAndVersionType) => {
  const { t } = useTranslation();
  const { copy } = useClipboard();
  const { track } = useAnalytics();

  const hasPreviousSummary = document
    ? Boolean(document.summary)
    : version
    ? Boolean(version.summary)
    : false;

  const currentSummary = document
    ? document.summary?.text || ''
    : version
    ? version.summary?.text || ''
    : '';

  const currentSummaryStatus = document
    ? document.summary?.status
    : version
    ? version.summary?.status
    : '';

  const statusNotCompleted = Boolean(
    hasPreviousSummary && currentSummaryStatus !== 'completed'
  );

  const { summary, createSummary, summaryLoading } = useAiSummary({
    currentSummary,
    versionId,
  });

  const handleCopy = () => {
    track('CopyDocumentAISummary');
    copy(summary);
  };

  const handleRefresh = () => {
    createSummary();
  };

  const actions: ActionProps[] = [
    {
      ariaLabel: t('Copy'),
      color: 'inherit',
      dataTestId: 'copy-summary',
      disabled: !summary || summaryLoading || statusNotCompleted,
      key: 'copy-summary',
      label: t('Copy'),
      onClick: handleCopy,
      startIcon: 'copyFile',
    },
    {
      ariaLabel: t('Generate new'),
      color: 'inherit',
      dataTestId: 'generate-new-summary',
      disabled: !editable || summaryLoading || isFinalized,
      key: 'generate-new-summary',
      label: t('Generate new'),
      onClick: handleRefresh,
      startIcon: 'ai',
    },
  ];

  return (
    <AiFrame
      actions={actions}
      label={t('AI summary')}
      maxHeight={300}
      showDisclaimer
    >
      {summaryLoading || statusNotCompleted ? (
        <AiLoader />
      ) : (
        <Typography variant="body1">
          {summary
            ? summary
            : t(
                'Press generate to create the first summary. This may take a few minutes.'
              )}
        </Typography>
      )}
    </AiFrame>
  );
};
