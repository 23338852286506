import React from 'react';

import { Icon } from '@pro4all/shared/ui/icons';
import { Checkbox, Radio } from '@pro4all/shared/ui/inputs';
import { Text } from '@pro4all/shared/ui/typography';

import * as Styled from './ItemCard.styles';
import { BasePropsHierarchyItem, CallBackProps, ItemProps } from './types';

type ItemCardProps<HierarchyItem extends BasePropsHierarchyItem> = {
  hasChildren: boolean;
} & Pick<
  CallBackProps<HierarchyItem>,
  'callbackCustomIcons' | 'callbackClick'
> &
  Pick<
    ItemProps<HierarchyItem>,
    'editItem' | 'item' | 'level' | 'multipleAnswers' | 'selectItems'
  >;

const ItemCardSelectComponent = <HierarchyItem extends BasePropsHierarchyItem>({
  callbackCustomIcons,
  callbackClick,
  editItem,
  hasChildren,
  item,
  level,
  multipleAnswers = false,
  selectItems,
}: ItemCardProps<HierarchyItem>) => {
  const select = multipleAnswers ? (
    <Checkbox checked={item.selected || false} />
  ) : !callbackClick ? (
    <Radio checked={item.selected || false} />
  ) : null;

  const customIcons = callbackCustomIcons ? callbackCustomIcons(item) : null;

  return (
    <>
      <Styled.Item editMode={false} level={level}>
        <Styled.Header
          onClick={() => {
            callbackClick && callbackClick(item);
            selectItems({ ids: [item.id], multipleAnswers });
          }}
          selected={item.selected || false}
        >
          <Styled.Title>
            <Text variant="h5">{item.name}</Text>
          </Styled.Title>
          <Styled.ActionsWrapper>
            {select}
            {customIcons}
            <Styled.ExpandCollapse
              onClick={(event) => {
                event.stopPropagation();
                hasChildren && editItem({ ...item, expanded: !item.expanded });
              }}
            >
              {hasChildren && (
                <Icon iconName={item.expanded ? 'expandLess' : 'expandMore'} />
              )}
            </Styled.ExpandCollapse>
          </Styled.ActionsWrapper>
        </Styled.Header>
      </Styled.Item>
      <Styled.ItemBottomMargin level={level} />
    </>
  );
};

const skipUpdate = <HierarchyItem extends BasePropsHierarchyItem>(
  prevProps: ItemCardProps<HierarchyItem>,
  nextProps: ItemCardProps<HierarchyItem>
) =>
  prevProps.item.expanded === nextProps.item.expanded &&
  prevProps.item.selected === nextProps.item.selected; // Item might have been expanded or collapsed.

export const ItemCardSelect = React.memo(
  ItemCardSelectComponent,
  skipUpdate
) as typeof ItemCardSelectComponent;
