import { useDocument } from '@pro4all/documents/data-access';
import { useCardToggle } from '@pro4all/shared/ui/card-toggle';

import { Versions } from '../shared';

export const SectionVersions = () => {
  const { CardToggle, expanded } = useCardToggle({ identifier: 'versions' });
  const { document } = useDocument();
  const count =
    document && document.versions ? document.versions.length - 1 : 0;

  return document?.isExpected ? null : (
    <CardToggle count={count} icon="versions" label="Older versions">
      {expanded ? <Versions /> : null}
    </CardToggle>
  );
};
