import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useDeleteGroups } from '../useDeleteGroups';

import { AddGroupsToProjectSidebar } from './AddGroupsToProjectSidebar';

export const OrganizationGroupsActionBar: React.FC = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const { hasAppPermission } = useAppPermissions();
  const { ACTION_CREATE, ACTION_DELETE, ACTION_ADD_TO_PROJECT } =
    useCentralActions();
  const userCannotUpdateOrg = !hasAppPermission('OrganizationUpdate');
  const deleteGroups = useDeleteGroups();

  const altActions: ActionProps[] = [
    {
      ...ACTION_ADD_TO_PROJECT,
      disabled: userCannotUpdateOrg,
      onClick: () => searchParams.set({ action: 'addGroupsToProject' }),
    },
    {
      ...ACTION_DELETE,
      dataTestId: 'delete-groups',
      disabled: userCannotUpdateOrg,
      onClick: deleteGroups,
    },
  ];

  const mainActions: ActionProps[] = [
    {
      ...ACTION_CREATE,
      ariaLabel: t('Create group'),
      dataTestId: 'create-group',
      disabled: userCannotUpdateOrg,
      key: 'create-group',
      label: t('Create group'),
      onClick: () => {
        searchParams.delete('name');
        searchParams.set({ action: 'createGroup' });
      },
      startIcon: 'groupAdd',
    },
  ];

  return (
    <>
      <TableActionBar
        altActions={altActions}
        dataTestid="groups-action-bar"
        mainActions={mainActions}
        search
      />
      <AddGroupsToProjectSidebar />
    </>
  );
};
