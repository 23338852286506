import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Sidebar } from '@pro4all/shared/ui/sidebar';

import { ProjectForm } from './ProjectForm';

interface Props {
  onSubmit?: (id: string) => void;
}

export const ProjectSidebar: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const createMode = searchParams.is('action', 'createProject');
  const duplicateMode = searchParams.is('action', 'duplicateProject');
  const handleClose = () => searchParams.clear();

  return (
    <Sidebar
      onClose={handleClose}
      open={Boolean(createMode) || Boolean(duplicateMode)}
      wide
    >
      <Sidebar.Header
        icon="project"
        title={
          createMode
            ? t('Create project')
            : duplicateMode
            ? t('Duplicate project')
            : t('Edit project')
        }
      />

      {React.Children.count(children) > 0 && <Box px={3}>{children}</Box>}
      {(createMode || duplicateMode) && <ProjectForm />}
    </Sidebar>
  );
};
