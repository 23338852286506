import React from 'react';
import { NavLink, NavLinkProps, useRouteMatch } from 'react-router-dom';

import { Routes } from '@pro4all/shared/config';
import { NavGroupIds, useSideNavContext } from '@pro4all/shared/contexts';
import {
  Box,
  CountColor,
  ListItemIcon,
  ListItemText,
} from '@pro4all/shared/mui-wrappers';
import {
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { generateRoute } from '@pro4all/shared/routing-utils';
import { Collapsible } from '@pro4all/shared/ui/collapsible';
import { Icon, IconName } from '@pro4all/shared/ui/icons';
import { useToggle } from '@pro4all/shared/ui/toggle';
import { Text } from '@pro4all/shared/ui/typography';

import { Count, StyledTooltip } from './Styled';

interface Props extends NavLinkProps {
  count?: number;
  countColor?: CountColor;
  dataTestId?: string;
  iconName?: IconName;
  id: NavGroupIds;
  label: string;
  route: keyof typeof Routes;
  to: string;
}

export const NavGroup: React.FC<Props> = ({
  children,
  count = 0,
  dataTestId,
  exact = false,
  iconName,
  id,
  label,
  onClick,
  route,
  to,
  countColor = 'default',
}) => {
  const {
    params: { projectId },
  } = useRouting();
  const base = projectId
    ? generateRoute('project', { params: { projectId } })
    : '';
  const configRouteMatch = useRouteMatch({
    exact: false,
    path: `${base}${Routes.config}`,
  });

  const { toggle, toggled } = useToggle();

  const {
    sideNavState,
    sideNavState: { isSideNavOpen },
    setSideNavState,
  } = useSideNavContext();

  const groupIsExpanded = sideNavState[id];

  const toggleOpenNavGroup = () => {
    updateLsLastVisitedProjectRoute({
      projectId: projectId || '',
      route,
    });
    setSideNavState({
      ...sideNavState,
      [id]: !sideNavState[id],
    });
  };

  const showHeaderActiveColor = configRouteMatch && !groupIsExpanded;

  return (
    <StyledTooltip
      className="nav-group-tooltip"
      open={toggled}
      placement="right"
      title={isSideNavOpen ? '' : label}
    >
      <Box
        aria-label={label}
        className={`nav-group ${groupIsExpanded && 'expanded'} ${
          showHeaderActiveColor && 'active'
        }`}
        data-testid={dataTestId}
        display="flex"
        flex={1}
        onClick={toggleOpenNavGroup}
        onMouseEnter={() => toggle(true)}
        onMouseLeave={() => toggle(false)}
        role="button"
      >
        <NavLink
          className="nav-link"
          data-testid={dataTestId}
          exact={exact}
          onClick={onClick}
          to={to}
        >
          {iconName && (
            <ListItemIcon>
              <Icon iconName={iconName} />
            </ListItemIcon>
          )}
          {label && isSideNavOpen && (
            <Box pl="6px">
              <Text variant="h6">
                <ListItemText disableTypography primary={label} />
              </Text>
            </Box>
          )}
          {!groupIsExpanded && count > 0 ? (
            <Count countColor={countColor} label={count} size="small" />
          ) : null}
        </NavLink>
        {isSideNavOpen && (
          <Icon
            className="chevron"
            iconName={groupIsExpanded ? 'keyboardArrowUp' : 'keyboardArrowDown'}
            onClick={toggleOpenNavGroup}
          />
        )}
      </Box>
      <Box className="group-container" flex="auto">
        <Collapsible open={groupIsExpanded}>{children}</Collapsible>
      </Box>
    </StyledTooltip>
  );
};
