import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/actions';

export const useActionHelpCenter = () => {
  const { t } = useTranslation();

  const helperCenter: ActionProps = {
    ariaLabel: 'Help center',
    color: 'inherit',
    dataTestId: 'help-center',
    key: 'help-center',
    label: t('Help center'),
    onClick: () =>
      window.open(
        'https://support.prostream.app/kb/guide/nl/problemen-met-inloggen-4nCJvhYH9N/Steps/3513210',
        '_self'
      ),
    startIcon: 'helpOutline',
  };

  return helperCenter;
};
