import { useDocument } from '@pro4all/documents/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';
import { isDefined } from '@pro4all/shared/utils';

import { VersionList } from '../document-properties/versions-list/VersionList';

export const Versions = () => {
  const { document } = useDocument();
  const { searchParams } = useRouting();
  const versionId = searchParams.get('versionid');

  return document && !versionId ? (
    <VersionList versions={document?.versions?.filter(isDefined) || []} />
  ) : null;
};
