import { useTranslation } from 'react-i18next';

import { IconName } from '@pro4all/shared/ui/icons';

export type ActionGroup = {
  groupOrder: number;
  groupOrderResponsiveness: number;
  icon: IconName;
  key: string;
  label: string;
};

//: ActionGroup[]
export const actionGroups = [
  {
    groupOrder: 5,
    groupOrderResponsiveness: 90,
    icon: 'assignmentOutlined',
    key: 'actionGroupCreate',
    label: 'Create',
  },
  {
    groupOrder: 10,
    groupOrderResponsiveness: 80,
    icon: 'edit',
    key: 'actionGroupEdit',
    label: 'Edit',
  },
  {
    groupOrder: 20,
    groupOrderResponsiveness: 70,
    icon: 'file',
    key: 'actionGroupFile',
    label: 'File',
  },
  {
    groupOrder: 30,
    groupOrderResponsiveness: 60,
    icon: 'approveFile',
    key: 'actionGroupStatus',
    label: 'Status',
  },
  {
    groupOrder: 40,
    groupOrderResponsiveness: 50,
    icon: 'addCollection',
    key: 'actionGroupAdd',
    label: 'Add',
  },
  {
    groupOrder: 50,
    groupOrderResponsiveness: 40,
    icon: 'openInNewRounded',
    key: 'actionGroupOpen',
    label: 'Open',
  },
  {
    groupOrder: 60,
    groupOrderResponsiveness: 30,
    icon: 'assignmentOutlined',
    key: 'actionGroupCreateOther',
    label: 'Create',
  },
  {
    groupOrder: 70,
    groupOrderResponsiveness: 20,
    icon: 'filterNone',
    key: 'actionGroupCopy',
    label: 'Copy',
  },
  {
    groupOrder: 80,
    groupOrderResponsiveness: 10,
    icon: 'cube',
    key: 'actionGroupConvert',
    label: 'Convert',
  },
  {
    groupOrder: 90,
    groupOrderResponsiveness: 9,
    icon: 'moreHoriz',
    key: 'actionGroupOther',
    label: 'Other',
  },
  {
    groupOrder: 100,
    groupOrderResponsiveness: 8,
    icon: 'delete',
    key: 'actionGroupDelete',
    label: 'Delete',
  },
] as const;

// Type to have the prop `actionGroupKey` typed properly
export type ActionGroupKey = typeof actionGroups[number]['key'];

export const useCentralActionGroups = () => {
  const { t } = useTranslation();

  // Translate labels during runtime
  const centralActionGroups: ActionGroup[] = actionGroups.map((group) => ({
    ...group,
    label: t(group.label),
  }));

  return centralActionGroups;
};
