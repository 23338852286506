import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

export const DocumentVersionNumber = styled(Text).attrs(() => ({
  'data-testid': 'document-version-number',
}))<{
  isExpected: boolean;
}>`
  && {
    ${({ isExpected }) => isExpected && `color: ${customColors.grey500};`}
  }
`;
