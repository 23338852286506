import React, { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { ProfilePictureEntity } from '@pro4all/shared/types';
import { Avatar, AvatarSize } from '@pro4all/shared/ui/avatar';
import { Button, IconButton } from '@pro4all/shared/ui/buttons';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { Text } from '@pro4all/shared/ui/typography';

import { AvatarInput } from './AvatarInput';
import { FileUpload } from './FileUpload';
import { useFileUploadContext } from './FileUploadProvider';
import { useFetchProfilePicture } from './useFetchProfilePicture';
import { useFileRejections } from './useFileRejections';
import { useProfilePictureUpload } from './useProfilePictureUpload';

type FileUploadInputProps = {
  avatarFallbacklabel: string;
  id: string;
  labelEntity: string;
  profilePictureEntity: ProfilePictureEntity;
};

export const AvatarProfile = ({
  avatarFallbacklabel,
  id,
  labelEntity,
  profilePictureEntity,
}: FileUploadInputProps) => {
  const { t } = useTranslation();
  const [openProfilePictureUploadDialog, setOpenProfilePictureUploadDialog] =
    useState(false);

  const { isUploading } = useFileUploadContext() || {};

  const editorRef = useRef<AvatarEditor>(null);

  const { handleRemoveProfilePicture, handleUploadProfilePicture } =
    useProfilePictureUpload({
      profilePictureEntity,
    });

  const handleUpload = () => {
    if (editorRef.current) {
      // Get the image as a Blob in a squared format.
      editorRef.current.getImage().toBlob((blob) => {
        if (blob) {
          const file = new File([blob], image?.name || 'avatar.png', {
            type: blob.type,
          });
          handleUploadProfilePicture([file]);
        }
      });
      setOpenProfilePictureUploadDialog(false);
    }
  };

  const maxSize = 5242880;

  const { currentProfileImage, currentProfileImageUrl } =
    useFetchProfilePicture({
      id,
      profilePictureEntity,
    });

  const [image, setImage] = useState<File | undefined>(currentProfileImage);

  const acceptedFileTypes = ['.jpeg', '.jpg', '.png'];

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setImage(acceptedFiles[0]);
    }
  };

  const onDropRejected = useFileRejections({ acceptedFileTypes, maxSize });

  const [imageUrl, setImageUrl] = useState<string | undefined>(
    currentProfileImageUrl
  );

  useEffect(() => {
    setImage(currentProfileImage);
    setImageUrl(currentProfileImageUrl);
  }, [currentProfileImage]); // Do not add `currentProfileImageUrl` because it changes synchronously, we have to wait for `currentProfileImage` that changes asynchronously.

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}
      >
        <Box
          sx={{
            mb: 1,
            position: 'relative',
            width: 'fit-content',
          }}
        >
          <Avatar
            name={avatarFallbacklabel}
            size={AvatarSize.AvatarSizeXXL}
            src={imageUrl}
          />
          <IconButton
            className="camera-icon"
            iconName="photoCamera"
            onClick={() => setOpenProfilePictureUploadDialog(true)}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: '60px', // For the icon size
              },
              '&:hover': {
                backgroundColor: customColors.grey500,
                opacity: 1,
              },
              color: customColors.white,
              height: '100%',
              left: '50%',
              opacity: 0,
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              transition: 'opacity 0.3s',
              width: '100%',
            }}
          />
        </Box>
        <Box>
          <Text variant="body1">
            {t('Upload {{labelEntity}} picture', {
              labelEntity: t(labelEntity),
            })}
          </Text>
        </Box>
      </Box>

      {/* Main purpose of the FileUpload wrapper is to get `openFileInput` */}
      <FileUpload
        accept={acceptedFileTypes}
        maxSize={maxSize}
        multiple={false}
        onDrop={onDrop}
        onDropRejected={onDropRejected}
      >
        {({ openFileInput }) => (
          <Dialog
            additionalActions={
              currentProfileImage ? (
                <Button
                  data-testid="select-file"
                  disabled={isUploading}
                  onClick={openFileInput}
                  type="button"
                  variant="outlined"
                >
                  {t('Replace')}
                </Button>
              ) : undefined
            }
            buttonVariant="outlined"
            fitWidth
            name="uploadProfilePictureDialog"
            onClose={() => setOpenProfilePictureUploadDialog(false)}
            onSubmit={handleUpload}
            open={openProfilePictureUploadDialog}
            showCloseIcon
            submitDisabled={!image || isUploading}
            submitLabel="Update"
            title={t('Profile picture')}
          >
            <Box
              sx={{
                display: 'flex',
                height: '140px',
                justifyContent: 'center',
                width: '550px',
              }}
            >
              <AvatarInput
                acceptedFileTypes={acceptedFileTypes}
                currentProfileImage={image}
                editorRef={editorRef}
                maxSize={maxSize}
                onDrop={onDrop}
                removeCallback={handleRemoveProfilePicture}
              />
            </Box>
          </Dialog>
        )}
      </FileUpload>
    </Box>
  );
};
