import { useDocument } from '@pro4all/documents/data-access';
import { Folder, ScopeType } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useAi } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useCardToggle } from '@pro4all/shared/ui/card-toggle';

import { AiKeywords } from '../shared';

export const SectionAiKeywords = ({ folder }: { folder?: Folder }) => {
  const { CardToggle, expanded } = useCardToggle({ identifier: 'aikeywords' });
  const { document } = useDocument();
  const hasAiGenerateKeywordsFeatureFlag = useFeatureFlag('generate-keywords');
  const {
    params: { projectId },
  } = useRouting();

  const { aiEnabledContext } = useAi(
    projectId ? ScopeType.Project : ScopeType.Organization
  );

  const count = document && document.keywords ? document.keywords.length : 0;

  return hasAiGenerateKeywordsFeatureFlag && aiEnabledContext ? (
    <CardToggle count={count} icon="aiKeywords" label="AI keywords">
      {expanded && <AiKeywords folder={folder} />}
    </CardToggle>
  ) : null;
};
