import React, { useRef } from 'react';

import { ObjectNode } from '@pro4all/graphql';
import { HierarchyEditor } from '@pro4all/shared/hierarchy-editor';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FormFooter } from '@pro4all/shared/ui/form';

import { ItemListWrapper } from './styles';
import { useSubmit } from './useSubmit';

export const ObjectsEdit = () => {
  const scrollableDivRef = useRef<HTMLDivElement>(
    document.createElement('div')
  );
  const { searchParams } = useRouting();
  const onSubmit = useSubmit();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <ItemListWrapper ref={scrollableDivRef}>
        <HierarchyEditor<ObjectNode>
          maxItemsRendered={1000}
          scrollableDivRef={scrollableDivRef}
        />
      </ItemListWrapper>
      <FormFooter
        disableSubmit={false}
        onClose={() => searchParams.delete('action')}
        onSubmit={() => {
          onSubmit();
          searchParams.delete('action');
        }}
        onSubmitWithoutClose={onSubmit}
        pt={2}
        showSubmitWithoutClose
        sticky
      />
    </Box>
  );
};
