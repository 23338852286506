import { useDocument } from '@pro4all/documents/data-access';
import { useCardToggle } from '@pro4all/shared/ui/card-toggle';

import { Tags } from '../shared';

export const SectionTags = () => {
  const { CardToggle, expanded } = useCardToggle({ identifier: 'tags' });
  const { document } = useDocument();
  const count = document && document.tags ? document.tags.length : 0;

  return (
    <CardToggle count={count} icon="tag" label="Tags">
      {expanded && <Tags />}
    </CardToggle>
  );
};
