import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/actions';
import { useActionLogin } from '@pro4all/shared/ui/actions';
import { InfoPage } from '@pro4all/shared/ui/infopages';

export const PageFail: React.FC = () => {
  const { t } = useTranslation();
  const actionLogin = useActionLogin({});
  const { goTo } = useRouting();

  const actionRegister: ActionProps = {
    ariaLabel: 'Register',
    color: 'inherit',
    dataTestId: 'register',
    key: 'register',
    label: t('Register'),
    onClick: () => goTo('registration'),
    startIcon: 'howToReg',
  };

  return (
    <InfoPage
      actions={[actionLogin, actionRegister]}
      description={t(
        "You should be able to log into your account, or register for a new one. If you've received an invitation, please ask for a new one."
      )}
      title={t('Account already confirmed or no longer exists')}
      type="error"
    />
  );
};
