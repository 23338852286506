import { useTranslation } from 'react-i18next';

import { SavedAnswers, SavedAnswersInstance } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SelectedIdName } from '@pro4all/shared/types';
import { useTableContextMenuRowContext } from '@pro4all/shared/ui/table';

export const useSavedAnswersActions = ({
  returnAll = false,
  selectedSavedAnswersInstance,
}: {
  returnAll?: boolean;
  selectedSavedAnswersInstance?: SavedAnswersInstance;
}) => {
  const { searchParams } = useRouting();
  const { contextMenuRow } = useTableContextMenuRowContext<SavedAnswers>();
  const routeCreate = searchParams.is('action', 'createSavedAnswer');
  const routeView = searchParams.is('action', 'viewSavedAnswer');

  const { ACTION_DELETE, ACTION_EDIT } = useCentralActions();

  const { t } = useTranslation();

  const { setLocalStorageItem: setSavedAnswersNameToDeleteInLs } =
    useLocalStorage<SelectedIdName[]>({
      key: `prostream-saved-answers-name-to-delete`,
    });

  const deleteAction: ActionProps = {
    ...ACTION_DELETE,
    dataTestId: 'delete-saved-answers',
    key: 'delete-saved-answer',
    onClick: () => {
      const { id, name } = selectedSavedAnswersInstance || contextMenuRow || {};
      if (id && name) {
        setSavedAnswersNameToDeleteInLs([{ id, name }]);
        searchParams.set({
          deleteSavedAnswers: 'true',
        });
      }
    },
  };

  const editAction: ActionProps = {
    ...ACTION_EDIT,
    dataTestId: 'edit-saved-answers',
    key: 'edit-saved-answers',
    onClick: () => {
      const { id } = selectedSavedAnswersInstance || contextMenuRow || {};
      searchParams.set({
        action: 'editSavedAnswer',
        id,
      });
    },
  };

  const altActions =
    returnAll || routeView
      ? [editAction, deleteAction]
      : routeCreate
      ? []
      : [deleteAction];

  return altActions;
};
