import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useTableContextMenuRowContext } from '@pro4all/shared/ui/table';
import { useTableCheck } from '@pro4all/shared/ui/table';

import { useActivateUsers } from '../organization/actions/useActivateUsers';
import { useDeactivateUsers } from '../organization/actions/useDeactivateUsers';
import { useResendEmail } from '../utils/useResendEmail';

export const useUsersActions = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  const { userId, userOrganizationName } = useOrganizationContext();

  const activateUsers = useActivateUsers();
  const deactivateUsers = useDeactivateUsers();

  const {
    ACTION_EDIT_ROLES,
    ACTION_INVITE_COLLEAGUES,
    ACTION_INVITE_EXISTING_USERS,
    ACTION_ADD_TO_PROJECT,
    ACTION_RESEND_EMAIL,
    ACTION_ACTIVATE_USER,
    ACTION_DEACTIVATE_USER,
  } = useCentralActions();

  const hasInviteExistingUsersFeatureFlag = useFeatureFlag(
    'inviteexistingusers'
  );

  const resend = useResendEmail();

  const { noRowsAreChecked, checkedRows } = useTableCheck<User>();
  const { contextMenuRow } = useTableContextMenuRowContext<User>();

  // It is necessary to store the selected user in the context
  // menu in a state to avoid losing the selected
  // user after the `Edit Role` or `Add to Project` modal is opened.
  const [userToEdit, setUserToEdit] = useState<User>(null);

  const { hasAppPermission } = useAppPermissions();
  const userCannotUpdateOrg = !hasAppPermission('OrganizationUpdate');
  const userCannotUpdateProject = !hasAppPermission('ProjectUpdate');

  const handleOpenEditRole = () => {
    if (contextMenuRow) {
      setUserToEdit(contextMenuRow);
    }
    searchParams.set('action', 'editRoles');
  };

  const handleCloseEditRole = () => {
    setUserToEdit(null);
    searchParams.clear();
  };

  const handleOpenAddToProject = () => {
    if (contextMenuRow) {
      setUserToEdit(contextMenuRow);
    }
    searchParams.set({ action: 'addUsersToProject' });
  };

  const handleCloseAddToProject = () => {
    setUserToEdit(null);
    searchParams.clear();
  };

  const invalidUserActiveSelection = (): boolean => {
    const validUsers = checkedRows.filter(
      (user) => user.active && user.id !== userId
    );

    if (validUsers.length && !contextMenuRow) {
      return false;
    } else {
      // If we have contextMenuRow, check if its me. If we don't have context menu row, for sure it is not a valid selection
      const userIsNotValid = contextMenuRow
        ? [contextMenuRow.id].find((user) => user === userId)
        : true;

      return Boolean(userIsNotValid);
    }
  };

  const resendAction: ActionProps = {
    ...ACTION_RESEND_EMAIL,
    disabled: userCannotUpdateOrg,
    onClick: resend,
  };

  const activateAction: ActionProps = {
    ...ACTION_ACTIVATE_USER,
    disabled: userCannotUpdateOrg,
    onClick: activateUsers,
  };

  const deactivateAction: ActionProps = {
    ...ACTION_DEACTIVATE_USER,
    disabled: userCannotUpdateOrg || invalidUserActiveSelection(),
    onClick: deactivateUsers,
  };

  const commonActions: ActionProps[] = [
    {
      ...ACTION_ADD_TO_PROJECT,
      disabled: userCannotUpdateProject,
      onClick: handleOpenAddToProject,
    },
    {
      ...ACTION_EDIT_ROLES,
      dataTestId: 'edit-user-roles',
      disabled: userCannotUpdateOrg,
      key: 'edit-user-roles',
      onClick: handleOpenEditRole,
    },
  ];

  const altActions: ActionProps[] = [
    ...commonActions,
    {
      ...resendAction,
      disabled:
        resendAction.disabled ||
        (!noRowsAreChecked && checkedRows.every((user) => !user.invited)),
    },
    {
      ...activateAction,
      disabled:
        activateAction.disabled ||
        (!noRowsAreChecked && checkedRows.every((user) => user.active)),
    },
    {
      ...deactivateAction,
      disabled:
        deactivateAction.disabled ||
        (!noRowsAreChecked && checkedRows.every((user) => !user.active)),
    },
  ];

  const contextMenuActions: ActionProps[] = [
    ...commonActions,
    contextMenuRow && contextMenuRow.active ? deactivateAction : null,
    ...(contextMenuRow && !contextMenuRow.active
      ? [activateAction, resendAction]
      : []),
  ]
    .filter(Boolean)
    .filter((action) => !action.disabled);

  const mainActions: ActionProps[] = [
    {
      ...ACTION_INVITE_COLLEAGUES,
      ariaLabel: t('Invite {- myOrganization} colleagues', {
        myOrganization: userOrganizationName,
      }),
      dataTestId: 'new-user',
      disabled: userCannotUpdateOrg,
      key: 'new-user',
      label: t('Invite {{- myOrganization}} colleagues', {
        myOrganization: userOrganizationName,
      }),
      onClick: () => searchParams.set({ action: 'inviteUsers' }),
    },
    {
      ...ACTION_INVITE_EXISTING_USERS,
      ariaLabel: t('Invite existing users'),
      dataTestId: 'new-user',
      disabled: userCannotUpdateOrg || !hasInviteExistingUsersFeatureFlag,
      key: 'invite-existing-users',
      label: t('Invite existing users'),
      onClick: () => searchParams.set({ action: 'inviteExistingUsers' }),
    },
  ];

  return {
    altActions,
    contextMenuActions,
    handleCloseAddToProject,
    handleCloseEditRole,
    mainActions,
    userToEdit,
  };
};
