import { useTranslation } from 'react-i18next';

import { Integration } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useTableContextMenuRowContext } from '@pro4all/shared/ui/table';

export function useIntegrationsActions() {
  const { hasAppPermission } = useAppPermissions();
  const { ACTION_CREATE, ACTION_DELETE } = useCentralActions();
  const { searchParams } = useRouting();
  const { contextMenuRow } = useTableContextMenuRowContext<Integration>();
  const { id } = contextMenuRow || {};

  const { t } = useTranslation();

  const mainActions: ActionProps[] = [
    {
      ...ACTION_CREATE,
      ariaLabel: t('Create integration'),
      dataTestId: 'create-integration',
      disabled: !hasAppPermission('OrganizationUpdate'),
      key: 'create-integration',
      label: t('Create integration'),
      onClick: () => searchParams.set('action', 'addIntegration'),
    },
  ];

  const altActions: ActionProps[] = [
    {
      ...ACTION_DELETE,
      dataTestId: 'delete-integration',
      disabled: !hasAppPermission('OrganizationUpdate'),
      key: 'delete-integration',
      onClick: () => searchParams.set({ action: 'deleteIntegration', id }),
    },
  ];

  return { altActions, mainActions };
}
