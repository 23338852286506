import React, { useEffect, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Task, TaskType } from '@pro4all/graphql';
import { DrawingsMain } from '@pro4all/quality-control/ui/floor-plan';
import {
  AnswersPercentageContextProvider,
  ResultSidebar,
  ResultsMain,
} from '@pro4all/quality-control/ui/results';
import { Routes } from '@pro4all/shared/config';
import {
  PhotoProvider,
  useObjectDetailContext,
} from '@pro4all/shared/contexts';
import { useHierarchyEditorContext } from '@pro4all/shared/hierarchy-editor';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SnagFormSearchContextProvider } from '@pro4all/shared/snags-and-forms-search-context';
import { Loader } from '@pro4all/shared/ui/loader';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import * as Messages from '../../Messages';
import { ProjectStatus } from '../../projects';
import { TBQTasks, TBQType } from '../../tbq';
import { OsidLogs } from '../../tbq/osid/OsidLogs';
import { TBQResolveTasks } from '../../tbq/tasks/list/TBQResolveTasks';

import { Tabs } from './Tabs';

export const ObjectDetail: React.FC = () => {
  const {
    editItem,
    state: { items },
  } = useHierarchyEditorContext();
  const { id, loading, object } = useObjectDetailContext();
  const hasBeenSetInitially = useRef(false);

  useEffect(() => {
    if (items.length > 0) {
      const currentItem = items.find((item) => item.id === id);
      if (currentItem && hasBeenSetInitially.current === false) {
        // User reloads application with a route that contains an objectId, this object then should be selected automatically.
        editItem({ ...currentItem, selected: true });
        hasBeenSetInitially.current = true;
      } else if (!currentItem) {
        // User clicked on navigation item `Objects`.
        // There may be one item that has the selected flag set to true, this one must revert to false.
        const selectedItem = items.find((item) => item.selected);
        if (selectedItem) {
          editItem({ ...selectedItem, selected: false });
        }
      }
    }
  }, [editItem, id, items]);

  const routeProjectId = useRouting().params.projectId;
  if (!id) return <Messages.NoObject />;
  if (!object && loading) return <Loader />;
  if (!object) return <Messages.NotFound />;

  const { tasks = [] } = object;
  const routeOrObjectProjectId = routeProjectId ?? object.projectId;
  const procedureId = object?.project?.mainProcedure?.id;

  return (
    <>
      <Box sx={{ height: '100%', overflow: 'auto' }}>
        {routeOrObjectProjectId && <Tabs />}
        <Route
          path={[
            Routes.objectTBQ,
            Routes.objectDrawings,
            Routes.objectResults,
            Routes.objectTasks,
            Routes.projectObjectTbq,
            Routes.projectObjectDrawings,
            Routes.projectObjectResults,
            Routes.projectObjectTasks,
          ]}
        >
          <ProjectStatus />
        </Route>
        <Switch>
          <Route path={[Routes.objectTBQ, Routes.projectObjectTbq]}>
            <TBQType />
            <OptimisticResponseProvider<Task>>
              <TBQTasks
                procedureId={procedureId}
                projectId={routeOrObjectProjectId ?? ''}
                tasks={
                  tasks?.filter(
                    (t) =>
                      t.type === TaskType.Tbq || t.type === TaskType.TbqScan
                  ) ?? []
                }
              />
            </OptimisticResponseProvider>
          </Route>
          <Route path={[Routes.objectDrawings, Routes.projectObjectDrawings]}>
            <DrawingsMain projectId={routeOrObjectProjectId ?? ''} />
          </Route>
          <Route path={[Routes.objectResults, Routes.projectObjectResults]}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >
              <SnagFormSearchContextProvider>
                <ResultsMain projectId={routeOrObjectProjectId ?? ''} />
              </SnagFormSearchContextProvider>
            </Box>
          </Route>
          <Route path={[Routes.objectTasks, Routes.projectObjectTasks]}>
            <OptimisticResponseProvider<Task>>
              <TBQResolveTasks
                procedureId={procedureId ?? ''}
                projectId={routeOrObjectProjectId ?? ''}
              />
            </OptimisticResponseProvider>
          </Route>
          <Route
            path={[Routes.objectTBQOsidLog, Routes.projectObjectTBQOsidLog]}
          >
            <OsidLogs />
          </Route>
        </Switch>
      </Box>
      <PhotoProvider>
        <AnswersPercentageContextProvider>
          <ResultSidebar />
        </AnswersPercentageContextProvider>
      </PhotoProvider>
    </>
  );
};
