import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { useDocument } from '@pro4all/documents/data-access';
import { useFolderByPathQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FullScreen } from '@pro4all/shared/ui/full-screen';
import { ContentRow } from '@pro4all/shared/ui/layout';
import { Loader } from '@pro4all/shared/ui/loader';

import { ViewerContainer } from './Documents3DViewer.styles';
import { use3DViewer } from './use3DViewer';
import { use3DViewerResizer } from './use3DViewerResizer';
import { use3DViewerTabs } from './use3DViewerTabs';

export const Documents3DViewer = () => {
  const { params, searchParams } = useRouting();

  const { data, loading: loadingFolder } = useFolderByPathQuery({
    fetchPolicy: 'cache-and-network',
    variables: { path: params?.path ?? '/', projectId: params?.projectId },
  });
  const { document, loading: loadingDocument, version } = useDocument();

  const folderIdByPath: string =
    data?.folder?.id !== undefined ? data?.folder?.id : '';

  const folderId = document?.folderId || version?.folderId || folderIdByPath;

  const { loading: loadingFolderPermissions } = useUserFolderPermissions({
    folderId,
  });

  const { activeNodes, nodeProperties, treeRootNodes, viewContainer, viewer } =
    use3DViewer({
      loadingDocument,
      loadingFolder,
    });

  const { viewerTabs } = use3DViewerTabs({
    activeNodes,
    nodeProperties,
    treeRootNodes,
    viewer,
  });

  const { CollapseBar, TogglePane } = use3DViewerResizer({ viewer });

  if (loadingDocument || loadingFolder || loadingFolderPermissions)
    return <Loader />;

  return (
    <FullScreen
      onClose={() => {
        searchParams.delete('urn');
        searchParams.delete('fullscreen');
      }}
    >
      <ContentRow>
        <TogglePane>{viewerTabs}</TogglePane>
        <CollapseBar />
        <ViewerContainer ref={viewContainer} />
        <link
          href="https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css"
          rel="stylesheet"
          type="text/css"
        />
      </ContentRow>
    </FullScreen>
  );
};
