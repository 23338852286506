import {
  Document,
  DocumentVersion,
  useDocumentQuery,
  useVersionQuery,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useDocument = () => {
  const { searchParams } = useRouting();
  const documentId = searchParams.get('id');
  const versionId = searchParams.get('versionid');

  /**
   * The construction we built quite some time ago is a bit nasty.
   * We have a skip if there's no documentId. Why? Because if we close the sidebar,
   * this DocumentSidebar component is still mounted, but there's no documentId of course.
   * And we don't want to query if there's no documentId. To prevent 400s.
   * Now the nasty stuff: A combination of the props 'skip' and 'pollInterval' causes the polling
   * not to restart after skip previously has been true for the same documentId.
   * So if we refresh the screen, open a doc in the sidebar, polling starts.
   * Then we close the sidebar, polling stops.
   * Then we re-open the sidebar for the same document, polling does NOT restart again.
   * This causes issues, because for instance the 'Drawing'-icon depends on polling.
   * A fix for this is to ommit the pollInterval prop and add an explicit startPolling().
   */
  const {
    data: dataDocument,
    error: errorDocument,
    loading: loadingDocument,
    refetch: refetchDocument,
    startPolling: startPollingDocument,
  } = useDocumentQuery({
    fetchPolicy: 'cache-and-network',
    skip: Boolean(!documentId || versionId),
    variables: { id: documentId || '' },
  });

  const {
    data: dataVersion,
    error: errorVersion,
    loading: loadingVersion,
    refetch: refetchVersion,
    startPolling: startPollingVersion,
  } = useVersionQuery({
    fetchPolicy: 'cache-first',
    skip: Boolean(!versionId),
    variables: { id: versionId || '' },
  });

  if (documentId) startPollingDocument(60000);
  if (versionId) startPollingVersion(60000);

  const document = !versionId ? (dataDocument?.document as Document) : null;
  const version = versionId ? (dataVersion?.version as DocumentVersion) : null;
  const error = versionId ? errorVersion : errorDocument;
  const loading = versionId // If we have a version or document (regardless it is taken from Apollo cache), we do not show the loader anymore.
    ? loadingVersion && !version
    : loadingDocument && !document;
  const refetch = versionId ? refetchVersion : refetchDocument;

  return { document, error, loading, refetch, version };
};
