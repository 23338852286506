import { Folder, ScopeType } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useAi } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useCardToggle } from '@pro4all/shared/ui/card-toggle';

import { AiDocumentSummary } from '../shared';

export const SectionAiSummary = ({ folder }: { folder?: Folder }) => {
  const { CardToggle, expanded } = useCardToggle({ identifier: 'aisummary' });
  const hasAiSummaryFeatureFlag = useFeatureFlag('ai-document-summary');
  const {
    params: { projectId },
  } = useRouting();
  const { aiEnabledContext } = useAi(
    projectId ? ScopeType.Project : ScopeType.Organization
  );

  return hasAiSummaryFeatureFlag && aiEnabledContext ? (
    <CardToggle icon="aiSummary" label="AI summary">
      {expanded && <AiDocumentSummary folder={folder} />}
    </CardToggle>
  ) : null;
};
