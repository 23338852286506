import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { ObjectInput, useUpdateNodesMutation } from '@pro4all/graphql';
import { useGetUpdatedItems } from '@pro4all/shared/hierarchy-editor';
import { useShowMessages } from '@pro4all/shared/ui/messages';

export const useSubmit = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { showSingleError } = useShowMessages();
  const [updateNodes] = useUpdateNodesMutation();
  const getUpdatedItems = useGetUpdatedItems();

  const toNode = (item: ObjectInput) => {
    const {
      createdAt,
      createdBy,
      deletedAt,
      deletedBy,
      description,
      id,
      index,
      name,
      organizationId,
      parentNodeId,
      previousNodeId,
      projectId,
    } = item;
    return {
      createdAt,
      createdBy,
      deletedAt,
      deletedBy,
      description,
      id,
      index,
      name,
      organizationId,
      parentNodeId,
      previousNodeId,
      projectId,
    };
  };

  const onSubmit = async () => {
    try {
      const nodes = getUpdatedItems().map(toNode) as ObjectInput[];
      await updateNodes({ variables: { nodes } });
      enqueueSnackbar(t('Objects updated'));
    } catch (e) {
      showSingleError(e);
    }
  };

  return onSubmit;
};
