import { Document, DocumentVersion, Folder } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/actions';

export enum SidebarContent {
  document = 'Document',
  version = 'Version',
}

type DocumentProps = {
  documentId?: Document['id'];
  folder?: Folder;
  getRootProps?: () => void;
  isActive: boolean;
  isDragActive?: boolean;
  isSmartFolder?: boolean;
  itemFallback: Document | DocumentVersion | null;
  loadingFolderPermissions: boolean;
  onClose?: () => void;
  sidebarActions?: ActionProps[];
  sidebarContent?: SidebarContent;
  versionId?: Document['versionId'];
};

export type DocumentDetailsProps = Pick<
  DocumentProps,
  | 'documentId'
  | 'folder'
  | 'getRootProps'
  | 'isDragActive'
  | 'isSmartFolder'
  | 'onClose'
  | 'sidebarActions'
  | 'sidebarContent'
  | 'versionId'
>;

export type DocumentDetailsSidebarProps = Pick<
  DocumentProps,
  | 'folder'
  | 'getRootProps'
  | 'isActive'
  | 'itemFallback'
  | 'loadingFolderPermissions'
  | 'onClose'
  | 'sidebarActions'
>;

export type DocumentDetailsFullscreenProps = Pick<
  DocumentProps,
  | 'documentId'
  | 'folder'
  | 'getRootProps'
  | 'isActive'
  | 'itemFallback'
  | 'sidebarActions'
>;
