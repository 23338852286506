import React from 'react';

import { use3DDocuments } from '@pro4all/documents/ui/3d';
import { Folder } from '@pro4all/graphql';
import { DocumentAndVersionType } from '@pro4all/shared/types';

import { Properties } from '../shared';

import { Thumbnail } from './general/Thumbnail';

export const DocumentProperties = ({
  document,
  folder,
  version,
}: {
  folder: Folder | undefined;
} & DocumentAndVersionType) => {
  const versionIds = document
    ? [document.versionId || '']
    : version
    ? [version.id]
    : [];

  const { urns } = use3DDocuments({ versionIds });

  return (
    <>
      <Thumbnail
        document={document || null}
        hide={Boolean(document?.isExpected)}
        urn={urns[0] || undefined}
        version={version || null}
      />
      <Properties folder={folder} />
    </>
  );
};
