import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { Routes } from '@pro4all/shared/config';
import { ActionProps } from '@pro4all/shared/actions';

import { usePubliclySharedDocsContext } from '../../publicly-shared-docs/PubliclySharedDocsContext';
import { useSharedDocsContext } from '../../shared-docs/SharedDocsContext';

export const useRevokeLinkButtonProps = () => {
  const { t } = useTranslation();
  const publicDocsContext = usePubliclySharedDocsContext();
  const sharedDocsContext = useSharedDocsContext();

  const isPublic = Boolean(
    useRouteMatch({
      path: Routes.publiclySharedDocs,
    })
  );

  const revokeLink = () => {
    isPublic
      ? publicDocsContext?.handleRevoke()
      : sharedDocsContext?.handleRevoke();
  };

  const revokeLinkButtonProps: ActionProps = {
    ariaLabel: t('Revoke link'),
    dataTestId: 'revoke-link-button',
    key: 'revoke-link-button',
    label: t('Revoke link'),
    onClick: revokeLink,
    startIcon: 'removeCircle',
  };

  return { revokeLinkButtonProps };
};
