import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Folder } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useRenameFolderAction = (folder: Folder) => {
  const { searchParams } = useRouting();
  const { ACTION_RENAME } = useCentralActions();

  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });

  const handleRenameFolder = () => {
    searchParams.set({ action: 'renameFolder' });
  };

  const renameFolderMenuItem: ActionProps = {
    ...ACTION_RENAME,
    disabled: !hasFolderPermission('UpdateFolder'),
    onClick: handleRenameFolder,
  };

  return { renameFolderMenuItem };
};
