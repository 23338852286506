import { useTranslation } from 'react-i18next';

import { ActionProps } from './actionTypes';

export const useCentralActions = () => {
  const { t } = useTranslation();

  const ACTION_CREATE: ActionProps = {
    actionGroupKey: 'actionGroupCreate',
    ariaLabel: t('Create'),
    dataTestId: 'create-entity',
    key: 'create-entity',
    label: t('Create'),
    startIcon: 'add',
  };

  const ACTION_EDIT: ActionProps = {
    actionGroupKey: 'actionGroupEdit',
    ariaLabel: t('Edit'),
    dataTestId: 'edit',
    key: 'edit',
    label: t('Edit'),
    startIcon: 'edit',
  };

  const ACTION_UPLOAD: ActionProps = {
    actionGroupKey: 'actionGroupFile',
    ariaLabel: t('Upload'),
    dataTestId: 'upload',
    key: 'upload',
    label: t('Upload'),
    startIcon: 'upload',
  };

  const ACTION_DOWNLOAD: ActionProps = {
    actionGroupKey: 'actionGroupFile',
    ariaLabel: t('Download'),
    dataTestId: 'download',
    key: 'download',
    label: t('Download'),
    startIcon: 'download',
  };

  const ACTION_SHARE: ActionProps = {
    actionGroupKey: 'actionGroupFile',
    ariaLabel: t('Share'),
    dataTestId: 'share',
    key: 'share',
    label: t('Share'),
    startIcon: 'share',
  };

  const ACTION_APPROVE: ActionProps = {
    actionGroupKey: 'actionGroupStatus',
    ariaLabel: t('Approve'),
    dataTestId: 'approve',
    key: 'approve',
    label: t('Approve'),
    startIcon: 'checkCircleOutlined',
  };

  const ACTION_FINALIZE: ActionProps = {
    actionGroupKey: 'actionGroupStatus',
    ariaLabel: t('Finalize'),
    dataTestId: 'finalize',
    key: 'finalize',
    label: t('Finalize'),
    startIcon: 'finalize',
  };

  const ACTION_REJECT: ActionProps = {
    actionGroupKey: 'actionGroupStatus',
    ariaLabel: t('Reject'),
    dataTestId: 'reject',
    key: 'reject',
    label: t('Reject'),
    startIcon: 'notInterested',
  };

  const ACTION_APPROVE_WITHDRAW: ActionProps = {
    actionGroupKey: 'actionGroupStatus',
    ariaLabel: t('Withdraw approve'),
    dataTestId: 'withdraw-approve',
    key: 'withdraw-approve',
    label: t('Withdraw approve'),
    startIcon: 'withdrawApproval',
  };

  const ACTION_FINALIZE_WITHDRAW: ActionProps = {
    actionGroupKey: 'actionGroupStatus',
    ariaLabel: t('Withdraw finalize'),
    dataTestId: 'withdraw-finalize',
    key: 'withdraw-finalize',
    label: t('Withdraw finalize'),
    startIcon: 'undoFinalize',
  };

  const ACTION_REJECT_WITHDRAW: ActionProps = {
    actionGroupKey: 'actionGroupStatus',
    ariaLabel: t('Withdraw reject'),
    dataTestId: 'withdraw-reject',
    key: 'withdraw-reject',
    label: t('Withdraw reject'),
    startIcon: 'withdrawRejection',
  };

  const ACTION_PUBLISH: ActionProps = {
    actionGroupKey: 'actionGroupStatus',
    ariaLabel: t('Publish'),
    dataTestId: 'publish',
    key: 'publish',
    label: t('Publish'),
    startIcon: 'publish',
  };

  const ACTION_UNLOCK: ActionProps = {
    actionGroupKey: 'actionGroupStatus',
    ariaLabel: t('Unlock'),
    dataTestId: 'unlock',
    key: 'unlock',
    label: t('Unlock'),
    startIcon: 'lockOpen',
  };

  const ACTION_UNLOCK_REQUEST: ActionProps = {
    actionGroupKey: 'actionGroupStatus',
    ariaLabel: t('Request unlock'),
    dataTestId: 'request-unlock',
    key: 'request-unlock',
    label: t('Request unlock'),
    startIcon: 'lockOpen',
  };

  const ACTION_ADD_QR: ActionProps = {
    actionGroupKey: 'actionGroupAdd',
    ariaLabel: t('QR codes'),
    dataTestId: 'add-qr',
    key: 'add-qr',
    label: t('Add QR code'),
    startIcon: 'stamp',
  };

  const ACTION_COLLECTION_ADD: ActionProps = {
    actionGroupKey: 'actionGroupAdd',
    ariaLabel: t('Add to collection'),
    dataTestId: 'add-to-collection',
    key: 'add-to-collection',
    label: t('Add to collection'),
    startIcon: 'collectionBin',
  };

  const ACTION_COLLECTION_REMOVE: ActionProps = {
    actionGroupKey: 'actionGroupAdd',
    ariaLabel: t('Remove from collection'),
    dataTestId: 'remove-from-collection',
    key: 'remove-from-collection',
    label: t('Remove from collection'),
    startIcon: 'removeCollectionBin',
  };

  const ACTION_OPEN_IN_OFFICE: ActionProps = {
    actionGroupKey: 'actionGroupOpen',
    dataTestId: 'open-office',
    key: 'open-office',
  };

  const ACTION_OPEN_IN_CDE: ActionProps = {
    actionGroupKey: 'actionGroupOpen',
    dataTestId: 'open-cde',
    key: 'open-cde',
    startIcon: 'solibriIcon',
  };

  const ACTION_OPEN_IN_APRYSE: ActionProps = {
    actionGroupKey: 'actionGroupOpen',
    ariaLabel: t('Open in Apryse'),
    dataTestId: 'open-in-apryse',
    key: 'open-in-apryse',
    label: t('Open in Apryse'),
    startIcon: 'removeRedEye',
  };

  const ACTION_OPEN_3D: ActionProps = {
    actionGroupKey: 'actionGroupOpen',
    ariaLabel: 'View 3D model',
    label: t('View 3D model'),
    startIcon: 'cube',
  };

  const ACTION_OPEN_DRAWING: ActionProps = {
    actionGroupKey: 'actionGroupOpen',
    ariaLabel: t('Go to drawing'),
    dataTestId: 'document-go-to-drawing',
    key: 'document-go-to-drawing',
    label: t('Go to drawing'),
    startIcon: 'link',
  };

  const ACTION_OPEN_IN_SEARCH: ActionProps = {
    actionGroupKey: 'actionGroupOpen',
    ariaLabel: t('Open in search'),
    dataTestId: 'open-in-search',
    key: 'open-in-search',
    label: t('Open in search'),
    startIcon: 'openSearch',
  };

  const ACTION_OPEN_IN_FOLDER: ActionProps = {
    actionGroupKey: 'actionGroupOpen',
    ariaLabel: t('Open in folder'),
    dataTestId: 'open-in-folder',
    key: 'open-in-folder',
    label: t('Open in folder'),
    startIcon: 'openInFolder',
  };

  const ACTION_CREATE_DOCUMENT_TASK: ActionProps = {
    actionGroupKey: 'actionGroupCreateOther',
    ariaLabel: t('Create task'),
    label: t('Create task'),
  };

  const ACTION_CREATE_KEYWORDS: ActionProps = {
    actionGroupKey: 'actionGroupCreateOther',
    ariaLabel: t('Generate keywords'),
    dataTestId: 'generate-keywords',
    key: 'generate-keywords',
    label: t('Generate keywords'),
    startIcon: 'generateKeywords',
  };

  const ACTION_SEND_MESSAGE: ActionProps = {
    actionGroupKey: 'actionGroupCreateOther',
    ariaLabel: t('Create message'),
    dataTestId: 'create-message',
    key: 'create-message',
    label: t('Create message'),
    startIcon: 'prostreamChat',
  };

  const ACTION_COPY: ActionProps = {
    actionGroupKey: 'actionGroupCopy',
    ariaLabel: t('Copy'),
    dataTestId: 'copy',
    key: 'copy',
    label: t('Copy'),
    startIcon: 'copyDocuments',
  };

  const ACTION_CUT: ActionProps = {
    actionGroupKey: 'actionGroupCopy',
    ariaLabel: t('Cut'),
    dataTestId: 'cut',
    key: 'cut',
    label: t('Cut'),
    startIcon: 'cut',
  };

  const ACTION_PASTE: ActionProps = {
    actionGroupKey: 'actionGroupCopy',
    ariaLabel: t('Paste'),
    dataTestId: 'paste',
    key: 'paste',
    label: t('Paste'),
    startIcon: 'clipboard',
  };

  const ACTION_PASTE_DOCUMENTS: ActionProps = {
    actionGroupKey: 'actionGroupCopy',
    ariaLabel: 'Paste documents',
    dataTestId: 'paste-documents',
    key: 'paste-documents',
    label: t('Paste documents'),
    startIcon: 'clipboard',
  };

  const ACTION_CONVERT_TO_DRAWING: ActionProps = {
    actionGroupKey: 'actionGroupConvert',
  }; // Toggle of three possible actions, 1. convert, 2. being converted, 3. converted

  const ACTION_CONVERT_TO_3D: ActionProps = {
    actionGroupKey: 'actionGroupConvert',
  };

  const ACTION_DELETE: ActionProps = {
    actionGroupKey: 'actionGroupDelete',
    ariaLabel: t('Delete'),
    dataTestId: 'delete',
    key: 'delete',
    label: t('Delete'),
    startIcon: 'delete',
  };

  const ACTION_CREATE_EXPECTED_DOCUMENT: ActionProps = {
    actionGroupKey: 'actionGroupFile',
    ariaLabel: t('Expected document'),
    dataTestId: 'new-expected-document',
    key: 'new-expected-document',
    label: t('Expected document'),
    startIcon: 'document',
  };

  const ACTION_MARK_READ: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Mark as read'),
    dataTestId: 'mark-as-read',
    key: 'mark-as-read',
    label: t('Mark as read'),
    startIcon: 'markUnread',
  };
  const ACTION_MARK_UNREAD: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Mark as unread'),
    dataTestId: 'mark-as-unread',
    key: 'mark-as-unread',
    label: t('Mark as unread'),
    startIcon: 'mailOutline',
  };

  const ACTION_DISCARD_DRAFT: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Discard draft'),
    dataTestId: 'discard-draft',
    key: 'discard-draft',
    label: t('Discard draft'),
    startIcon: 'delete',
  };

  const ACTION_IMPORT: ActionProps = {
    actionGroupKey: 'actionGroupCreate',
    ariaLabel: t('Import'),
    dataTestId: 'import',
    key: 'import',
    label: t('Import'),
    startIcon: 'upload',
  };

  const ACTION_EXPORT: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Export'),
    dataTestId: 'export',
    key: 'export',
    label: t('Export'),
    startIcon: 'export',
  };

  const ACTION_ADD_TO_PROJECT: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Add to project'),
    dataTestId: 'add-to-project',
    key: 'add-to-project',
    label: t('Add to project'),
    startIcon: 'project',
  };

  const ACTION_INVITE_COLLEAGUES: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    startIcon: 'personAdd',
  };

  const ACTION_INVITE_EXISTING_USERS: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    startIcon: 'send',
  };

  const ACTION_EDIT_ROLES: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Edit roles'),
    label: t('Edit roles'),
    startIcon: 'verifiedUser',
  };

  const ACTION_RESEND_EMAIL: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Send invite'),
    dataTestId: 'resend-email',
    key: 'resend-email',
    label: t('Send invite'),
    startIcon: 'mail',
  };

  const ACTION_ACTIVATE_USER: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Activate'),
    dataTestId: 'activate-user',
    key: 'activate-user',
    label: t('Activate'),
    startIcon: 'activeUser',
  };

  const ACTION_DEACTIVATE_USER: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Deactivate'),
    dataTestId: 'deactivate-user',
    key: 'deactivate-user',
    label: t('Deactivate'),
    startIcon: 'inactiveUser',
  };

  const ACTION_CREATE_REPORT: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Create report'),
    dataTestId: 'new-report',
    key: 'new-report',
    label: t('Create report'),
    startIcon: 'document',
  };

  const ACTION_RESOLVE_TASK: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Resolve task'),
    dataTestId: 'resolve',
    key: 'resolve',
    label: t('Resolve task'),
    startIcon: 'taskSucceeded',
  };

  const ACTION_DUPLICATE_PROJECT: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Duplicate project'),
    dataTestId: 'duplicate-project',
    key: 'duplicate-project',
    label: t('Duplicate project'),
    startIcon: 'projectCreate',
  };

  const ACTION_INVITE_EXTERNAL_USERS: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Invite external'),
    dataTestId: 'add-externals-to-project',
    key: 'add-externals-to-project',
    label: t('Invite external'),
    startIcon: 'mail',
  };

  const ACTION_DUPLICATE_TASK: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Duplicate'),
    dataTestId: 'duplicate-task',
    key: 'duplicate-task',
    label: t('Duplicate task'),
    startIcon: 'copyFile',
  };

  const ACTION_CREATE_SAVED_ANSWERS: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Create saved answers'),
    dataTestId: 'create-saved-answers',
    key: 'create-saved-answers',
    label: t('Create saved answers'),
    startIcon: 'bookmark',
  };

  const ACTION_APPLY_SAVED_ANSWERS: ActionProps = {
    actionGroupKey: 'actionGroupOther',
    ariaLabel: t('Apply saved answers'),
    dataTestId: 'apply-saved-answers',
    key: 'apply-saved-answers',
    label: t('Apply saved answers'),
    startIcon: 'bookmarkAdd',
  };

  const ACTION_RENAME: ActionProps = {
    actionGroupKey: 'actionGroupEdit',
    ariaLabel: t('Rename'),
    dataTestId: 'rename-entity',
    key: 'rename-entity',
    label: t('Rename'),
    startIcon: 'edit',
  };

  const ACTION_HIDE_SHOW_EMPTY_FOLDERS: ActionProps = {
    actionGroupKey: 'actionGroupOther',
  };

  const ACTION_APPLY_METADATA_TEMPLATE: ActionProps = {
    actionGroupKey: 'actionGroupEdit',
    ariaLabel: 'Apply meta data template',
    dataTestId: 'apply-template',
    key: 'apply-template',
    label: t('Apply meta data template'),
    startIcon: 'metaData',
  };

  const ACTION_EDIT_PERMISSIONS: ActionProps = {
    actionGroupKey: 'actionGroupEdit',
    ariaLabel: 'Edit permissions',
    dataTestId: 'edit-permissions',
    key: 'edit-permissions',
    label: t('Edit permissions'),
    startIcon: 'security',
  };

  const ACTION_PROPERTIES: ActionProps = {
    actionGroupKey: 'actionGroupEdit',
    ariaLabel: 'Show properties',
    dataTestId: 'show-properties',
    key: 'show-properties',
    label: t('Properties'),
    startIcon: 'info',
  };

  const ACTION_SEARCH_IN_THIS_FOLDER: ActionProps = {
    actionGroupKey: 'actionGroupOpen',
    ariaLabel: 'Search in this folder',
    dataTestId: 'parent-search',
    key: 'parent-search',
    label: t('Search in this folder'),
    startIcon: 'search',
  };

  const ACTION_PLACE_AND_LINK_PROJECT: ActionProps = {
    actionGroupKey: 'actionGroupCreate',
    ariaLabel: 'Place and link project',
    dataTestId: 'link-project-to-folder',
    key: 'link-project-to-folder',
    label: t('Place and link project'),
    startIcon: 'objectLinkActive',
  };

  ACTION_CREATE['actionOrder'] = 5;
  ACTION_EDIT['actionOrder'] = 10;
  ACTION_RENAME['actionOrder'] = 12;
  ACTION_UPLOAD['actionOrder'] = 20;
  ACTION_DOWNLOAD['actionOrder'] = 30;
  ACTION_SHARE['actionOrder'] = 35;
  ACTION_APPROVE['actionOrder'] = 40;
  ACTION_FINALIZE['actionOrder'] = 50;
  ACTION_REJECT['actionOrder'] = 60;
  ACTION_APPROVE_WITHDRAW['actionOrder'] = 70;
  ACTION_FINALIZE_WITHDRAW['actionOrder'] = 80;
  ACTION_REJECT_WITHDRAW['actionOrder'] = 90;
  ACTION_PUBLISH['actionOrder'] = 100;
  ACTION_UNLOCK['actionOrder'] = 110;
  ACTION_UNLOCK_REQUEST['actionOrder'] = 120;
  ACTION_ADD_QR['actionOrder'] = 130;
  ACTION_COLLECTION_ADD['actionOrder'] = 140;
  ACTION_COLLECTION_REMOVE['actionOrder'] = 150;
  ACTION_OPEN_IN_OFFICE['actionOrder'] = 160;
  ACTION_OPEN_IN_CDE['actionOrder'] = 170;
  ACTION_OPEN_IN_APRYSE['actionOrder'] = 180;
  ACTION_OPEN_3D['actionOrder'] = 185;
  ACTION_OPEN_DRAWING['actionOrder'] = 188;
  ACTION_OPEN_IN_SEARCH['actionOrder'] = 190;
  ACTION_OPEN_IN_FOLDER['actionOrder'] = 195;
  ACTION_CREATE_DOCUMENT_TASK['actionOrder'] = 200;
  ACTION_CREATE_KEYWORDS['actionOrder'] = 205;
  ACTION_SEND_MESSAGE['actionOrder'] = 210;
  ACTION_COPY['actionOrder'] = 220;
  ACTION_CUT['actionOrder'] = 230;
  ACTION_PASTE['actionOrder'] = 235;
  ACTION_PASTE_DOCUMENTS['actionOrder'] = 236;
  ACTION_CONVERT_TO_DRAWING['actionOrder'] = 240;
  ACTION_CONVERT_TO_3D['actionOrder'] = 250;
  ACTION_DELETE['actionOrder'] = 260;
  ACTION_CREATE_EXPECTED_DOCUMENT['actionOrder'] = 270;
  ACTION_MARK_READ['actionOrder'] = 280;
  ACTION_MARK_UNREAD['actionOrder'] = 290;
  ACTION_DISCARD_DRAFT['actionOrder'] = 300;
  ACTION_IMPORT['actionOrder'] = 310;
  ACTION_EXPORT['actionOrder'] = 320;
  ACTION_ADD_TO_PROJECT['actionOrder'] = 330;
  ACTION_INVITE_COLLEAGUES['actionOrder'] = 340;
  ACTION_INVITE_EXISTING_USERS['actionOrder'] = 350;
  ACTION_EDIT_ROLES['actionOrder'] = 360;
  ACTION_RESEND_EMAIL['actionOrder'] = 370;
  ACTION_ACTIVATE_USER['actionOrder'] = 380;
  ACTION_DEACTIVATE_USER['actionOrder'] = 390;
  ACTION_CREATE_REPORT['actionOrder'] = 400;
  ACTION_RESOLVE_TASK['actionOrder'] = 410;
  ACTION_DUPLICATE_PROJECT['actionOrder'] = 420;
  ACTION_INVITE_EXTERNAL_USERS['actionOrder'] = 430;
  ACTION_DUPLICATE_TASK['actionOrder'] = 440;
  ACTION_CREATE_SAVED_ANSWERS['actionOrder'] = 450;
  ACTION_APPLY_SAVED_ANSWERS['actionOrder'] = 460;
  ACTION_HIDE_SHOW_EMPTY_FOLDERS['actionOrder'] = 470;
  ACTION_APPLY_METADATA_TEMPLATE['actionOrder'] = 480;
  ACTION_EDIT_PERMISSIONS['actionOrder'] = 490;
  ACTION_PROPERTIES['actionOrder'] = 500;
  ACTION_SEARCH_IN_THIS_FOLDER['actionOrder'] = 510;
  ACTION_PLACE_AND_LINK_PROJECT['actionOrder'] = 520;

  return {
    ACTION_ACTIVATE_USER,
    ACTION_ADD_QR,
    ACTION_ADD_TO_PROJECT,
    ACTION_APPLY_METADATA_TEMPLATE,
    ACTION_APPLY_SAVED_ANSWERS,
    ACTION_APPROVE,
    ACTION_APPROVE_WITHDRAW,
    ACTION_COLLECTION_ADD,
    ACTION_COLLECTION_REMOVE,
    ACTION_CONVERT_TO_3D,
    ACTION_CONVERT_TO_DRAWING,
    ACTION_COPY,
    ACTION_CREATE,
    ACTION_CREATE_DOCUMENT_TASK,
    ACTION_CREATE_EXPECTED_DOCUMENT,
    ACTION_CREATE_KEYWORDS,
    ACTION_CREATE_REPORT,
    ACTION_CREATE_SAVED_ANSWERS,
    ACTION_CUT,
    ACTION_DEACTIVATE_USER,
    ACTION_DELETE,
    ACTION_DISCARD_DRAFT,
    ACTION_DOWNLOAD,
    ACTION_DUPLICATE_PROJECT,
    ACTION_DUPLICATE_TASK,
    ACTION_EDIT,
    ACTION_EDIT_PERMISSIONS,
    ACTION_EDIT_ROLES,
    ACTION_EXPORT,
    ACTION_FINALIZE,
    ACTION_FINALIZE_WITHDRAW,
    ACTION_HIDE_SHOW_EMPTY_FOLDERS,
    ACTION_IMPORT,
    ACTION_INVITE_COLLEAGUES,
    ACTION_INVITE_EXISTING_USERS,
    ACTION_INVITE_EXTERNAL_USERS,
    ACTION_MARK_READ,
    ACTION_MARK_UNREAD,
    ACTION_OPEN_3D,
    ACTION_OPEN_DRAWING,
    ACTION_OPEN_IN_APRYSE,
    ACTION_OPEN_IN_CDE,
    ACTION_OPEN_IN_FOLDER,
    ACTION_OPEN_IN_OFFICE,
    ACTION_OPEN_IN_SEARCH,
    ACTION_PASTE,
    ACTION_PASTE_DOCUMENTS,
    ACTION_PLACE_AND_LINK_PROJECT,
    ACTION_PROPERTIES,
    ACTION_PUBLISH,
    ACTION_REJECT,
    ACTION_REJECT_WITHDRAW,
    ACTION_RENAME,
    ACTION_RESEND_EMAIL,
    ACTION_RESOLVE_TASK,
    ACTION_SEARCH_IN_THIS_FOLDER,
    ACTION_SEND_MESSAGE,
    ACTION_SHARE,
    ACTION_UNLOCK,
    ACTION_UNLOCK_REQUEST,
    ACTION_UPLOAD,
  };
};
