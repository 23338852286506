import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import { useResetMfaMutation } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Typography } from '@pro4all/shared/mui-wrappers';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikInput } from '@pro4all/shared/ui/formik';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

const StyledSupport = styled.div`
  color: pink;
`;

interface FormValues {
  email: string;
}

export function Support() {
  const { t } = useTranslation();
  const supportEnabled = useFeatureFlag('support-page');
  const [resetMfa] = useResetMfaMutation();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: FormValues = { email: '' };

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      const response = await resetMfa({ variables: { email: values.email } });
      if (response.data?.resetMFA) {
        enqueueSnackbar(t('ResetMFA successful'), { variant: 'success' });
      } else {
        enqueueSnackbar(t('ResetMFA failed'), { variant: 'error' });
      }
    } catch {
      enqueueSnackbar(t('ResetMFA failed'), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return !supportEnabled ? (
    <BigMessageNoAccess />
  ) : (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting, dirty, submitForm }) => (
        <StyledSupport>
          <FormWrapper>
            <Typography variant="h1">{t('Support page')}</Typography>
            <Typography variant="h2">{t('Reset MFA')}</Typography>

            <FormikInput
              id="email"
              label={t('Email')}
              name="email"
              placeholder={t('Enter your email')}
            />
          </FormWrapper>
          <FormFooter
            disableSubmit={!dirty || isSubmitting}
            onSubmit={submitForm}
            pb={3}
            pt={2}
            px={3}
            showCancel={false}
            submitLabel={t('Reset MFA')}
          ></FormFooter>
        </StyledSupport>
      )}
    </Formik>
  );
}

export default Support;
