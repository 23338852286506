import React, { useState } from 'react';
import { DropzoneState } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  QcPermissionCategory,
  useDeleteVisualContextsMutation,
  useRestoreVisualContextsMutation,
  VisualContext,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { Action } from '@pro4all/shared/config';
import { useObjectDetailContext } from '@pro4all/shared/contexts';
import { Button } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ItemChangedMessage } from '@pro4all/shared/ui/messages';
import {
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/table';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';
import { useAnalytics } from '@pro4all/shared/vendor';

import { DrawingsDeleteDialog } from './DrawingDeleteDialog';
import { DeleteVariant } from './types';

export type TDeleteDrawingsConfig = {
  includeSnagsAndForms?: boolean;
};
interface Props {
  openFileInput?: DropzoneState['open'];
  visualContexts: VisualContext[];
}

type selectedDrawings = 'all-owned' | 'none-owned' | 'mixed';

export const DrawingsActionBar: React.FC<Props> = ({
  openFileInput,
  visualContexts,
}) => {
  const { projectId: objectProjectId } = useObjectDetailContext();
  const { floorDeleteNone, floorDeleteOwn, floorDeleteAll, floorCreateAll } =
    useQCPermissions({
      category: QcPermissionCategory.Qcs,
    });

  const { ACTION_DELETE, ACTION_UPLOAD } = useCentralActions();

  const [showDialog, setShowDialog] = useState(false);

  const [deleteVariant, setDeleteVariant] = useState<DeleteVariant>(
    DeleteVariant.DELETE_SELECTED
  );

  const { track } = useAnalytics();
  const { params } = useRouting();

  const projectId = params.projectId ?? objectProjectId;

  const { t } = useTranslation();

  const { restoreItems, deleteItems } =
    useOptimisticResponseContext<VisualContext>();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { checkedRows } = useTableCheck<VisualContext>();
  const [deleteVisualContexts] = useDeleteVisualContextsMutation();
  const [restoreVisualContexts] = useRestoreVisualContextsMutation();

  const { userId } = useOrganizationContext();

  const mainActions = [
    floorCreateAll && {
      ...ACTION_UPLOAD,
      ariaLabel: t('Upload drawing'),
      dataTestId: 'upload-floor-plan',
      key: 'upload-floor-plan',
      label: t('Upload drawing'),
      onClick: () => {
        openFileInput();
        track(Action.CreateDrawingButtonClicked, {
          projectId: params.projectId,
        });
      },
      startIcon: 'upload',
    },
  ].filter(Boolean) as ActionProps[];

  const handleDrawingDeletionUndo = async (
    deleteIds: string[],
    includingSnagsAndForms: boolean
  ) => {
    closeSnackbar('undo-delete-drawings');
    const { data } = await restoreVisualContexts({
      variables: {
        includeSnagsAndForms: includingSnagsAndForms,
        projectId,
        visualContextIds: deleteIds,
      },
    });
    if (data.restoreVisualContexts.errors) {
      enqueueSnackbar(t('Something went wrong. Please try again.'));
    } else {
      const deleteMessage = includingSnagsAndForms
        ? 'Drawings and its associated snags and forms have been restored'
        : 'Drawings restored';
      restoreItems(deleteIds);
      enqueueSnackbar(t(deleteMessage));
    }
  };

  const deleteDrawings = async (
    drawings: VisualContext[],
    { includeSnagsAndForms }: TDeleteDrawingsConfig
  ) => {
    if (showDialog) setShowDialog(false);

    track(Action.DeleteDrawingButtonClicked, {
      ids: drawings.map(({ id }) => id),
      names: drawings.map(({ name }) => name),
      projectId,
    });

    const { data } = await deleteVisualContexts({
      variables: {
        includeSnagsAndForms: Boolean(includeSnagsAndForms),
        projectId,
        visualContextIds: drawings.map(({ id }) => id),
      },
    });

    if (data.deleteVisualContexts.errors) {
      enqueueSnackbar(t('Something went wrong. Please try again.'));
    } else {
      const deleteIds = drawings.map(({ id }) => id);

      deleteItems(deleteIds);

      const deleteMessage = includeSnagsAndForms
        ? 'Drawings and its associated snags and forms have been deleted'
        : 'Drawings deleted';

      enqueueSnackbar(
        <ItemChangedMessage
          action={
            <Button
              color="inherit"
              onClick={() =>
                handleDrawingDeletionUndo(deleteIds, includeSnagsAndForms)
              }
              size="small"
            >
              {t('Undo')}
            </Button>
          }
        >
          {t(deleteMessage)}
        </ItemChangedMessage>,
        {
          key: 'undo-delete-drawings',
        }
      );
    }
  };

  const verifyCheckedDrawings = (): selectedDrawings => {
    const flag = checkedRows.every((row) =>
      row.createdBy ? row.createdBy.id === userId : false
    )
      ? 'all-owned'
      : checkedRows.every((row) =>
          row.createdBy ? row.createdBy.id !== userId : false
        )
      ? 'none-owned'
      : 'mixed';

    return flag;
  };

  const altActions: ActionProps[] = [
    {
      ...ACTION_DELETE,
      dataTestId: 'delete-floor-plans',
      key: 'delete-floor-plans',
      onClick: async () => {
        const selectedType = verifyCheckedDrawings();
        if (floorDeleteAll || selectedType === 'all-owned') {
          setDeleteVariant(
            visualContexts.length === checkedRows.length
              ? DeleteVariant.DELETE_ALL
              : DeleteVariant.DELETE_SELECTED
          );
        } else if (floorDeleteOwn && selectedType === 'mixed') {
          setDeleteVariant(DeleteVariant.DELETE_ONLY_OWN);
        } else {
          setDeleteVariant(DeleteVariant.DELETE_NONE);
        }
        setShowDialog(true);
      },
    },
  ];

  return (
    <>
      <TableActionBar
        altActions={!floorDeleteNone && altActions}
        dataTestid="floor-plan-action-bar"
        mainActions={mainActions}
        search
      />

      <DrawingsDeleteDialog
        deleteDrawings={deleteDrawings}
        deleteVariant={deleteVariant}
        setShowDialog={setShowDialog}
        showDialog={showDialog}
      />
    </>
  );
};
