import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { Icon } from '@pro4all/shared/ui/icons';

import { ContentProps } from './layoutFrameTypes';

export const Content = ({
  columnContentLeft,
  columnContentMain,
  columnContentRight,
}: ContentProps) => {
  const { t } = useTranslation();
  const [isLeftOpen, setIsLeftOpen] = useState(true);

  return (
    <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
      <Box
        sx={{
          borderRight: `1px solid ${customColors.grey}`,
          display: 'flex',
          flexDirection: 'column',
          flexShrink: 0,
          overflowY: 'auto',
          width: isLeftOpen ? '300px' : '50px',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
          }}
        >
          {isLeftOpen && (
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Box sx={{ mr: 1 }}>
                <Icon iconName="info" />
              </Box>
              <Typography variant="body1">{t('Details')}</Typography>
            </Box>
          )}
          <IconButton
            className="chevron"
            color="inherit"
            disableBorder
            iconName={isLeftOpen ? 'chevronLeftRounded' : 'chevronRightRounded'}
            onClick={() => setIsLeftOpen(!isLeftOpen)}
          />
        </Box>
        {isLeftOpen && <Box>{columnContentLeft}</Box>}
      </Box>

      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
        }}
      >
        {columnContentMain}
      </Box>
      <Box
        sx={{
          borderLeft: `1px solid ${customColors.grey}`,
          flexShrink: 0,
          overflowY: 'auto',
          width: '600px',
        }}
      >
        {columnContentRight}
      </Box>
    </Box>
  );
};
