import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Tabs } from '@pro4all/shared/ui/general';
import { BigMessage } from '@pro4all/shared/ui/messages';

import { EntitySearch } from './entity-viewer/entity-search/EntitySearch';
import { EntityTree } from './entity-viewer/tree/EntityTree';
import { PropertyViewer } from './property-viewer/PropertyViewer';
import { ForgeViewer } from './viewers/forge/ForgeViewer';
import { ViewerNode, ViewerPropertyGroup } from './viewers/Viewer.types';
import {
  EntityTreeContainer,
  ViewerEntityContainer,
  ViewerPropertyContainer,
  ViewerSideNav,
} from './Documents3DViewer.styles';

type Use3DViewerTabsProps = {
  activeNodes: ViewerNode[];
  nodeProperties: ViewerPropertyGroup[];
  treeRootNodes: ViewerNode[] | undefined;
  viewer: ForgeViewer | null;
};

export const use3DViewerTabs = ({
  activeNodes,
  nodeProperties,
  treeRootNodes,
  viewer,
}: Use3DViewerTabsProps) => {
  const { t } = useTranslation();

  const [entityView, setEntityView] = useState<string>('tree');
  const [searchNodes, setSearchNodes] = useState<ViewerNode[] | undefined>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchDebounce, setSearchDebounce] = useState<number>();

  const searchNodeTreshold = 500;

  // Define viewer action handlers
  const handleOnSelect = (nodes: ViewerNode[]) => viewer?.select(nodes);
  const handleOnHide = (nodes: ViewerNode[]) => viewer?.hide(nodes);
  const handleOnIsolate = (nodes: ViewerNode[]) => viewer?.isolate(nodes);
  const handleOnFocus = (nodes: ViewerNode[]) => {
    viewer?.focus(nodes);
    viewer?.isolate(nodes);
  };
  const handleOnShowAll = () => viewer?.showAll();

  const handleSearch = (term: string) => {
    setSearchQuery(term);
    window.clearTimeout(searchDebounce);
    setSearchDebounce(
      window.setTimeout(async () => {
        const searchResults = await viewer?.search(term, 0, searchNodeTreshold);
        setSearchNodes(searchResults);
      }, 200)
    );
  };

  const tabs = (
    <Tabs
      onChange={(_, value) => setEntityView(value)}
      value={entityView}
      variant="fullWidth"
    >
      <Tab
        data-testid="viewer-entity-view-tree"
        label={t('Hierarchy')}
        value="tree"
      />
      <Tab
        data-testid="viewer-entity-view-type"
        label={t('Search')}
        value="search"
      />
    </Tabs>
  );

  const tree = (
    <EntityTreeContainer>
      {treeRootNodes &&
        treeRootNodes.map((treeRootNode) => (
          <EntityTree
            activeNodes={activeNodes}
            key={`${treeRootNode.model.id}-${treeRootNode.id}`}
            onFocus={handleOnFocus}
            onHide={handleOnHide}
            onIsolate={handleOnIsolate}
            onSelect={handleOnSelect}
            onShowAll={handleOnShowAll}
            rootNode={treeRootNode}
          ></EntityTree>
        ))}
    </EntityTreeContainer>
  );

  const search = (
    <EntitySearch
      activeNodes={activeNodes}
      displayWarningTreshhold={searchNodeTreshold}
      nodes={searchNodes}
      onFocus={handleOnFocus}
      onHide={handleOnHide}
      onIsolate={handleOnIsolate}
      onSearch={handleSearch}
      onSelect={handleOnSelect}
      onShowAll={handleOnShowAll}
      searchQuery={searchQuery}
    ></EntitySearch>
  );

  const viewerTabs = (
    <ViewerSideNav>
      <ViewerEntityContainer>
        {tabs}
        {entityView === 'tree' && tree}
        {entityView === 'search' && search}
      </ViewerEntityContainer>
      <ViewerPropertyContainer>
        {nodeProperties.length > 1 ? (
          <PropertyViewer propertyGroups={nodeProperties}></PropertyViewer>
        ) : (
          <BigMessage
            shapeName="flow1"
            title={t('Select an entity to show details')}
          />
        )}
      </ViewerPropertyContainer>
    </ViewerSideNav>
  );

  return { viewerTabs };
};
