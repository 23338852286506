import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';

import { AiIcon } from './AiIcon';

export const AiMainWrapper = styled.div`
  background: linear-gradient(#fefaee, #fefaee) padding-box,
    linear-gradient(
        125.2deg,
        #f5b05b 4.57%,
        #ffe7a8 23.45%,
        #f5c55b 44.53%,
        #c87a08 58.58%,
        #ffcd7b 72.63%,
        #f5b05b 92.39%
      )
      border-box;
  border: 2px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 0 ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(3)};
`;

export const AiHeaderWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(1)} 0;
`;

export const AiHeader = styled.h3`
  margin: 0;
`;

export const AiHeaderIconWrapper = styled.div`
  display: flex;
  width: 12px;
  height: inherit;
  align-items: flex-start;
`;

export const AiHeaderIcon = styled(AiIcon)`
  height: 12px;
  width: 12px;
`;

// Arbitary height to make sure the content shows 3,5 lines
export const AiContentWrapper = styled(Box)`
  margin: ${({ theme }) => theme.spacing(1)} 0;
`;

export const AiButtons = styled.div`
  display: flex;
`;

export const AiDisclaimer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;
