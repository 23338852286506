import { useMemo } from 'react';
import styled from 'styled-components';

import { Task } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { BigMessageNoTasks } from '@pro4all/shared/ui/messages';
import {
  Table as UITable,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';
import { TBQTaskSidebar } from '@pro4all/workflow/ui/task-sidebar';

import { useTBQTaskColumns } from '../useTBQTaskColumns';

import { TasksActionBar } from './TasksActionBar';

interface Props {
  procedureId?: string;
  projectId?: string;
  tasks: Task[];
}

export const TBQTasks = ({ projectId, procedureId, tasks }: Props) => {
  const { searchParams } = useRouting();

  const columns = useTBQTaskColumns();
  const taskId = searchParams.get('id') ?? undefined;

  const tasksProvider = useMemo(() => tasks || [], [tasks]);
  const {
    state: { items, itemsInitial },
    deleteItems,
  } = useOptimisticResponseContext<Task>();

  useSetItemsInLocalState<Task>(tasksProvider || []);

  if (!procedureId && !items.length && !itemsInitial.length)
    return (
      <Box sx={{ mt: '40px' }}>
        <BigMessageNoTasks />
      </Box>
    );

  return (
    <TableContextProvider
      checkable
      checkableSpacing
      columns={columns}
      id="table-tbq-tasks"
      items={items}
    >
      <FilterContextProvider<Task>>
        <TasksActionBar deleteItems={deleteItems} />
        <Table
          onRowClick={({ id }) => {
            searchParams.set({ action: 'viewTask', id });
          }}
          selectedId={taskId}
        />
        <TBQTaskSidebar
          procedureId={procedureId}
          projectId={projectId}
          tasks={items}
        />
      </FilterContextProvider>
    </TableContextProvider>
  );
};

const Table = styled(UITable)`
  && {
    margin-top: 2rem;
  }
`;
