export enum ActionType {
  TOGGLE_PERMISSIONS_SAVED = 'TOGGLE_PERMISSIONS_SAVED',
  TOGGLE_RELOAD_PROFILE_PICTURES = 'TOGGLE_RELOAD_PROFILE_PICTURES',
  TOGGLE_SHOW_FINAL_PERMISSIONS = 'TOGGLE_SHOW_FINAL_PERMISSIONS',
  TOGGLE_SHOW_HIGHEST_QC_PERMISSION = 'SHOW_HIGHEST_QC_PERMISSION',
  TOGGLE_SHOW_QC_GROUP_PERMISSIONS = 'SHOW_QC_GROUP_PERMISSIONS',
}

export type State = {
  permissionsSaved: boolean;
  reloadProfilePictures: boolean;
  showFinalPermissions: boolean;
  showHighestQCPermission: boolean;
  showQCGroupPermissions: boolean;
};

type ActionTogglePermissionsSaved = {
  type: ActionType.TOGGLE_PERMISSIONS_SAVED;
};

type ActionToggleReloadProfilePictures = {
  type: ActionType.TOGGLE_RELOAD_PROFILE_PICTURES;
};

type ActionToggleShowFinalPermissions = {
  type: ActionType.TOGGLE_SHOW_FINAL_PERMISSIONS;
};

type ActionToggleShowHighestQCPermission = {
  type: ActionType.TOGGLE_SHOW_HIGHEST_QC_PERMISSION;
};

type ActionToggleShowQCGroupPermissions = {
  type: ActionType.TOGGLE_SHOW_QC_GROUP_PERMISSIONS;
};

export type Action =
  | ActionTogglePermissionsSaved
  | ActionToggleReloadProfilePictures
  | ActionToggleShowFinalPermissions
  | ActionToggleShowQCGroupPermissions
  | ActionToggleShowHighestQCPermission
  | ActionToggleShowFinalPermissions;
