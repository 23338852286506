import { useTranslation } from 'react-i18next';

import { LockType } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/actions';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';

import { DocumentActionProps } from '../../types';

export const useEditMetaDataAction = ({
  editableDocuments,
  editableVersions,
  isSpecialFolder,
  isVersion,
}: Pick<
  DocumentActionProps,
  'editableDocuments' | 'editableVersions' | 'isSpecialFolder' | 'isVersion'
>) => {
  const { t } = useTranslation();
  const { goTo } = useRouting();

  const editableDocumentsWithoutLocked = editableDocuments.filter(
    (doc) => doc.lockType !== LockType.Prostream
  );

  const hasClient = useFeatureFlag('client');

  const editMetaData = () => {
    goTo({
      searchParams: { action: 'editMetaData', f: undefined, fullscreen: true },
      state: {
        documentIdsForEdit: isVersion
          ? editableVersions.map(({ documentId }) => documentId)
          : editableDocumentsWithoutLocked.map(({ id }) => id),
        isVersion,
        versionIdsForEdit: isVersion
          ? editableVersions.map(({ id }) => id)
          : [],
      },
    });
  };

  const editMetaDataAction: ActionProps = {
    ariaLabel: t('Edit meta data'),
    dataTestId: 'edit-meta-data',
    disabled: isVersion
      ? editableVersions.length === 0
      : editableDocumentsWithoutLocked.length === 0 || isSpecialFolder,
    key: 'edit-meta-data',
    label: t('Edit meta data'),
    onClick: editMetaData,
    startIcon: hasClient ? 'editMetadata' : 'edit',
  };

  return editMetaDataAction;
};
