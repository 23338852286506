import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/actions';
import { Button } from '@pro4all/shared/ui/buttons';
import { ContextMenu } from '@pro4all/shared/ui/context-menu';

import { useFilterMenuContext } from '../search-bar/FilterMenuContext';

import { SelectorButtonWrap, SelectorWrap } from './AddFilterButton.styles';
import { FilterList } from './FilterList';

type TAddFilterButtonProps = {
  color?: ActionProps['color'];
};

export const AddFilterButton: React.FC<TAddFilterButtonProps> = ({
  color = 'primary',
}) => {
  const { t } = useTranslation();
  const { open, onClick, setOpen } = useFilterMenuContext();

  return (
    <SelectorButtonWrap>
      <Button
        color={color}
        label={t('Add filter')}
        onClick={onClick}
        startIcon="add"
        variant="text"
      />
      <ContextMenu onClose={() => setOpen(false)} open={open} variant="menu">
        <SelectorWrap>
          <FilterList />
        </SelectorWrap>
      </ContextMenu>
    </SelectorButtonWrap>
  );
};
