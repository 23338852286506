import { useDocument } from '@pro4all/documents/data-access';
import { Folder } from '@pro4all/graphql';
import { isDefined } from '@pro4all/shared/utils';

import { GeneralProperties } from '../document-properties/general/GeneralProperties';

export const Properties = ({ folder }: { folder?: Folder }) => {
  const { document, version } = useDocument();
  const { fields: fieldDefinitions } = folder?.template ?? {};

  return (
    <GeneralProperties
      document={document || null}
      fieldDefinitions={fieldDefinitions?.filter(isDefined)}
      version={version || null}
    />
  );
};
