import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/actions';
import { Typography } from '@pro4all/shared/mui-wrappers';
import { Box } from '@pro4all/shared/mui-wrappers';
import { ActionBar } from '@pro4all/shared/ui/action-bar';
import { Button } from '@pro4all/shared/ui/buttons';
import { Main } from '@pro4all/shared/ui/layout';

import * as Styled from './FullScreenStyles';

export function FullScreen({
  headerActions,
  headerTitle,
  centralText,
  onClose,
  backButtonText,
  headerChildren,
  children,
}: PropsWithChildren<{
  backButtonText?: string;
  centralText?: string;
  headerActions?: ActionProps[];
  headerChildren?: React.ReactNode;
  headerTitle?: string;
  onClose?: () => void;
}>) {
  const { t } = useTranslation();

  return (
    <Main>
      <Styled.Container>
        <Styled.WrapperHeader>
          <Styled.HeaderLeft>
            {onClose && (
              <Button
                data-testid="close-instance"
                onClick={onClose}
                startIcon="arrowBack"
                variant="outlined"
              >
                {backButtonText ? t(backButtonText) : t('Go back')}
              </Button>
            )}
            {headerTitle && (
              <Styled.HeaderTitle color="textPrimary" variant="h5">
                {headerTitle}
              </Styled.HeaderTitle>
            )}
          </Styled.HeaderLeft>
          <Styled.HeaderMiddle>
            {centralText && (
              <Typography variant="body2">{centralText}</Typography>
            )}
          </Styled.HeaderMiddle>
          <Styled.HeaderRight>
            {headerActions && <ActionBar mainActions={headerActions} />}
          </Styled.HeaderRight>
        </Styled.WrapperHeader>
        {headerChildren && <Box>{headerChildren}</Box>}
        <Styled.WrapperContent>{children}</Styled.WrapperContent>
      </Styled.Container>
    </Main>
  );
}
