import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useDeleteHierarchicalLists } from '../hooks/useDeleteHierarchicalLists';

export const HierarchicalListsActionBar = ({
  includeOrgItems,
}: {
  includeOrgItems: boolean;
}) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { hasAppPermission } = useAppPermissions();
  const { ACTION_CREATE, ACTION_DELETE } = useCentralActions();
  const userCannotUpdateOrg = !hasAppPermission('OrganizationUpdate');
  const deleteHierarchicalLists = useDeleteHierarchicalLists({
    includeOrgItems,
  });

  const mainActions: ActionProps[] = [
    {
      ...ACTION_CREATE,
      ariaLabel: t('Create hierarchy'),
      dataTestId: 'create-hierarchy',
      disabled: userCannotUpdateOrg,
      key: 'create-hierarchy',
      label: t('Create hierarchy'),
      onClick: () => searchParams.set('action', 'createHierarchicalList'),
      startIcon: 'formatListNumbered',
    },
  ];

  const altActions: ActionProps[] = [
    {
      ...ACTION_DELETE,
      dataTestId: 'delete-hierarchy',
      disabled: userCannotUpdateOrg,
      key: 'delete-hierarchy',
      onClick: deleteHierarchicalLists,
    },
  ];

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="hierarchical-lists-action-bar"
      mainActions={mainActions}
      search
    />
  );
};
