import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { useContextMenuPosition } from '@pro4all/shared/ui/buttons';
import { ContextMenu } from '@pro4all/shared/ui/context-menu';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { ActionComponentProps } from '../layoutFrameTypes';

export enum ActionModes {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
}

interface ActionProps extends ActionComponentProps {
  mode: ActionModes;
}

export const Action = ({ action, mode }: ActionProps) => {
  const { closeContextMenu, isContextMenuOpen, openContextMenu, position } =
    useContextMenuPosition();

  const { disabled, label, onClick, startIcon, subItems } = action || {};

  if (disabled) {
    return null;
  }

  const isMedium = mode === ActionModes.Medium;

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const onClickTyped =
      onClick as unknown as React.MouseEventHandler<HTMLDivElement>;
    onClickTyped(event);
  };

  return (
    <Box
      onClick={subItems ? openContextMenu : handleClick}
      sx={{
        '&:hover': {
          backgroundColor: customColors.grey300,
          cursor: 'pointer',
        },
        alignItems: isMedium ? 'start' : 'center',
        display: 'inline-flex', // Gebruik inline-flex om de breedte dynamisch te maken
        flexDirection: isMedium ? 'row' : 'column',
        height: '76px',
        minWidth: '60px',
        p: 1,
      }}
    >
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: isMedium ? 'row' : 'column',
          }}
        >
          {startIcon && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: isMedium ? 'left' : 'center',
                mb: isMedium ? 0 : 1,
                mr: isMedium ? 1 : 0,
              }}
            >
              <Icon
                fontSize={isMedium ? 'small' : 'large'}
                iconName={startIcon}
              />
            </Box>
          )}
          <Tooltip placement="bottom" title={label}>
            <Box
              sx={{
                display: 'inline-block', // Zorgt dat het label minimale breedte heeft
                fontSize: '14px',
                maxWidth: label && label.length > 15 ? '90px' : 'fit-content', // Beperk de maximale breedte
                minWidth: '0', // Zorg ervoor dat de breedte niet altijd een minimum heeft
                overflow: 'hidden', // Verberg overtollige inhoud
                textAlign: 'center',
                textOverflow: 'ellipsis', // Voeg ellipsis toe als tekst niet past
                whiteSpace: 'normal !important', // Forceer tekstomloop
                width: 'auto', // Gebruik de ruimte die nodig is
                wordBreak: 'break-word', // Breek lange woorden
              }}
            >
              {label}
            </Box>
          </Tooltip>
        </Box>
        {subItems && (
          <Box sx={{ ml: 1 }}>
            <Icon iconName="expandMore" />
          </Box>
        )}
      </Box>

      <ContextMenu
        data-testid="context-menu-sub-items"
        initialPosition={position}
        menuItems={subItems}
        onClose={closeContextMenu}
        open={isContextMenuOpen}
      />
    </Box>
  );
};
