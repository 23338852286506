import { useDocument } from '@pro4all/documents/data-access';
import { DocumentHistoryTab } from '@pro4all/documents/ui/history';
import { Document, DocumentVersion } from '@pro4all/graphql';

export const Log = () => {
  const { document, version } = useDocument();

  return (document && document.versionId) || version ? (
    <DocumentHistoryTab
      documentOrVersion={
        version ? (version as DocumentVersion) : (document as Document)
      }
    />
  ) : null;
};
