import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/actions';
import { InfoPage } from '@pro4all/shared/ui/infopages';

export const PageNoProjectAccessLicenseExpired = () => {
  const { t } = useTranslation();
  const { goTo } = useRouting();

  const description = (
    <Trans
      defaults="It looks like this project doesn't have access to this part of the platform. If you're the admin, you can upgrade your license to get access."
      i18nKey="BigMessage.noActiveLicense"
    />
  );

  const actions: ActionProps[] = [
    {
      ariaLabel: 'My projects',
      color: 'inherit',
      dataTestId: 'my-projects',
      key: 'my-projects',
      label: t('My projects'),
      onClick: () => goTo('myOverview'),
      startIcon: 'project',
    },
    {
      ariaLabel: 'My tasks',
      color: 'inherit',
      dataTestId: 'my-tasks',
      key: 'my-tasks',
      label: t('My tasks'),
      onClick: () => goTo('myTasks'),
      startIcon: 'barChart',
    },
    {
      ariaLabel: 'Upgrade license',
      color: 'inherit',
      dataTestId: 'upgrade-license',
      key: 'upgrade-license',
      label: t('Upgrade license'),
      onClick: () => goTo('settingsOrganizationLicense'),
      startIcon: 'payment',
    },
  ];

  return (
    <InfoPage
      actions={actions}
      description={description}
      title={t('Expired license')}
      type="error"
    />
  );
};
