import React, { useCallback, useEffect } from 'react';

import { useRouting } from '@pro4all/shared/routing-utils';
import { LayoutFrame, ModalLayoutFrame } from '@pro4all/shared/ui/layout';

import { FileUploadWrapper } from './FileUploadWrapper';
import { DocumentDetailsFullscreenProps } from './types';
import { useDocumentFullscreenLeft } from './useDocumentFullscreenLeft';
import { useDocumentFullscreenMain } from './useDocumentFullscreenMain';
import { useDocumentFullscreenRight } from './useDocumentFullscreenRight';

export const DocumentDetailsFullscreen = ({
  documentId,
  folder,
  getRootProps,
  isActive,
  itemFallback,
  sidebarActions,
}: DocumentDetailsFullscreenProps) => {
  const { searchParams } = useRouting();

  const { leftContent } = useDocumentFullscreenLeft({ folder });
  const { mainContent, setPreviewImages } = useDocumentFullscreenMain();
  const { rightContent } = useDocumentFullscreenRight({ folder });

  const onCloseFullScreen = useCallback(() => {
    searchParams.delete('id');
    setPreviewImages([]);
  }, [searchParams, setPreviewImages]);

  useEffect(() => {
    // Initialize `previewImages` when navigating to another document.
    setPreviewImages([]);
  }, [documentId, setPreviewImages]);

  return (
    <ModalLayoutFrame>
      <FileUploadWrapper getRootProps={getRootProps} isActive={isActive}>
        <LayoutFrame
          actions={sidebarActions}
          columnContentLeft={leftContent}
          columnContentMain={mainContent}
          columnContentRight={rightContent}
          onClose={onCloseFullScreen}
          showDivider
          title={itemFallback?.name}
        />
      </FileUploadWrapper>
    </ModalLayoutFrame>
  );
};
