import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { useProjectContext } from '@pro4all/projects/ui/context';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { Routes } from '@pro4all/shared/config';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';

export function useProjectActions() {
  const { hasAppPermission } = useAppPermissions();
  const { ACTION_DUPLICATE_PROJECT } = useCentralActions();
  const { searchParams } = useRouting();
  const { isExternalUserInProject } = useProjectContext();

  const isMyProjectsRoute = Boolean(useRouteMatch(Routes.myProjects));

  const { t } = useTranslation();

  const mainActions: ActionProps[] = [
    {
      ...ACTION_DUPLICATE_PROJECT,
      disabled:
        !hasAppPermission('ProjectCreate') ||
        isMyProjectsRoute ||
        isExternalUserInProject,
      onClick: () => searchParams.set('action', 'duplicateProject'),
    },
  ];

  return { mainActions };
}
