import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/actions';

type Args = {
  onFocus: () => void;
  onHide: () => void;
  onIsolate: () => void;
  onShowAll: () => void;
};
type HookProps = (args: Args) => ActionProps[];

export const useContextMenuActions: HookProps = ({
  onHide,
  onIsolate,
  onFocus,
  onShowAll,
}) => {
  const { t } = useTranslation();

  return [
    {
      ariaLabel: `Hide`,
      dataTestId: 'hide-node',
      key: 'hide-node',
      label: t('Hide'),
      onClick: onHide,
      startIcon: 'visibilityOff',
    },
    {
      ariaLabel: `Isolate`,
      dataTestId: 'isolate-node',
      key: 'isolate-node',
      label: t('Isolate'),
      onClick: onIsolate,
      startIcon: 'centerFocusWeak',
    },
    {
      ariaLabel: `Focus`,
      dataTestId: 'focus-node',
      key: 'focus-node',
      label: t('Focus'),
      onClick: onFocus,
      startIcon: 'centerFocusStrong',
    },
    {
      ariaLabel: `Show all`,
      dataTestId: 'show-all-nodes',
      key: 'show-all-nodes',
      label: t('Show all'),
      onClick: onShowAll,
      startIcon: 'visibility',
    },
  ];
};
