import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';

export const DropZone = styled.div<{
  bottomMarginDropZone?: boolean;
  level: number;
}>`
  display: flex;
  min-height: ${({ theme }) => theme.spacing(5)};
  margin-left: ${({ level, theme }) => theme.spacing(level * 4)};
  margin-bottom: ${({ bottomMarginDropZone, theme }) =>
    bottomMarginDropZone ? 0 : theme.spacing(2)};
  margin-top: ${({ bottomMarginDropZone, theme }) =>
    bottomMarginDropZone ? theme.spacing(2) : 0};
  border: 1px dashed ${customColors.grey600};
`;

export const Item = styled.div<{
  editMode: boolean;
  level: number;
}>`
  display: flex;
  margin-left: ${({ level, theme }) => theme.spacing(level * 4)};
  &:hover {
    cursor: ${({ editMode }) => (editMode ? 'grab' : 'pointer')};
  }
`;

export const ItemBottomMargin = styled.div<{
  level: number;
}>`
  display: flex;
  margin-left: ${({ level, theme }) => theme.spacing(level * 4)};
  min-height: ${({ theme }) => theme.spacing(2)};
`;

export const Header = styled.div<{ selected: boolean }>`
  min-height: 44px;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  background-color: ${({ selected }) =>
    selected ? customColors.greenLight : customColors.grey200};
  border: ${({ selected }) =>
    selected
      ? `1px solid ${customColors.greenPrimary}`
      : `1px solid ${customColors.grey300}`};
  border-radius: 5px;
  &:hover {
    background-color: ${({ selected }) =>
      selected ? customColors.greenWhiteLight : customColors.grey300};
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionsWrapper = styled.div`
  display: flex;
`;

export const Actions = styled.div`
  align-items: center;
  display: none;
  ${Item}:hover &,
  ${Item}:focus & {
    display: flex;
  }
`;

export const ExpandCollapse = styled.div`
  display: flex;
  align-items: center;
  min-width: ${({ theme }) => theme.spacing(4)};
  margin-left: ${({ theme }) => theme.spacing(2)};
  &:hover {
    cursor: pointer;
  }
`;
