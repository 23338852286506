import React, { useState } from 'react';

import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Position } from '@pro4all/shared/ui/context-menu';

import { useCopyFolderTree } from '../actions/useCopyFolderTree';
import { useHideShowEmptyFolders } from '../actions/useHideShowEmptyFolders';
import { useImportFolderSpreadsheetAction } from '../actions/useImportFolderSpreadsheet';
import { usePasteFolderTree } from '../actions/usePasteFolderTree';
import { useFolderTreeContextOuter } from '..';

export const useGeneralMenuActions = () => {
  const { searchParams } = useRouting();

  const {
    state: { folderTree },
  } = useFolderTreeContextOuter();

  const disablePaste = folderTree.length === 0;

  const [position, setPosition] = useState<Position>();

  const { ACTION_CREATE } = useCentralActions();

  const { hasAppPermission } = useAppPermissions();

  const isOpen = searchParams.get('action') === 'showGeneralMenu';

  // If current action is renameFolder, the (re)-name input is on screen. Prevent context menu from opening.
  const validateAction = () => !searchParams.is('action', 'renameFolder');

  const handleOpen = (event: React.MouseEvent) => {
    event && event.preventDefault();
    setPosition({
      left: event.clientX,
      top: event.clientY,
    });

    if (validateAction()) {
      searchParams.set({
        action: 'showGeneralMenu',
      });
    }
  };

  const handleClose = () => {
    if (searchParams.get('action') === 'showGeneralMenu') {
      searchParams.delete('action');
    }
  };

  const createNewFolder = () => {
    searchParams.set({ action: 'createRootFolder' });
    handleClose();
  };

  const createRootFolderAction: ActionProps = {
    ...ACTION_CREATE,
    dataTestId: 'new-root-folder',
    disabled: !hasAppPermission('ProjectUpdate'),
    key: 'new-root-folder',
    onClick: (_) => createNewFolder(),
    startIcon: 'folderNew',
  };

  const { importFolderSpreadsheetAction } = useImportFolderSpreadsheetAction();
  const { copyFolderTreeAction } = useCopyFolderTree();
  const { pasteFolderTreeAction } = usePasteFolderTree();
  const { hideShowEmptyFoldersAction } = useHideShowEmptyFolders();

  const menuItems: ActionProps[] = [
    createRootFolderAction,
    importFolderSpreadsheetAction,
    { ...copyFolderTreeAction, disabled: disablePaste },
    pasteFolderTreeAction,
    hideShowEmptyFoldersAction,
  ].filter(Boolean);

  return {
    handleClose,
    handleOpen,
    isOpen,
    menuItems,
    position,
  };
};
