import React from 'react';

import { ActionProps } from '@pro4all/shared/actions';
import { Option } from '@pro4all/shared/types';

import { GroupCard } from './GroupCard';
import { useGetUsersAction } from './useGetUsersAction';
import { UserCard } from './UserCard';

export const useIdentityCardsJsx = () => {
  const { getShowUsersAction } = useGetUsersAction();

  const getGroupCardJsx = ({ option }: { option: Option }) => {
    const { groupOption, id, label } = option;
    const { totalUsers } = groupOption || {};

    const cardIcons: ActionProps[] = [
      getShowUsersAction({ id, totalUsers: totalUsers || 0 }),
    ];

    return (
      <GroupCard
        // cardIcons={cardIcons} TODO: re-implement this after BE has finished endpoints to fetch all direct and nested users for a group.
        group={{
          displayName: label,
          id,
          totalUsers,
        }}
      />
    );
  };

  const getUserCardJsx = ({ option }: { option: Option }) => {
    const { id } = option;
    return <UserCard userId={id} />;
  };

  return { getGroupCardJsx, getUserCardJsx };
};
