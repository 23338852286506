import { useDocument } from '@pro4all/documents/data-access';
import { DocumentComments } from '@pro4all/documents/ui/comments';
import { Document, DocumentVersion } from '@pro4all/graphql';

export const Comments = () => {
  const { document, version } = useDocument();

  return document || version ? (
    <DocumentComments
      documentOrVersion={
        version ? (version as DocumentVersion) : (document as Document)
      }
    />
  ) : null;
};
