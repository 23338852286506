import {
  AuthenticatedApiService,
  FilesData,
} from '@pro4all/authentication/src/services/authenticated-api-service';

export const PhotoBaseURL = 'https://graph.prostream.dev/quality/';

class Service {
  api: AuthenticatedApiService;

  constructor() {
    this.api = new AuthenticatedApiService(PhotoBaseURL);
  }

  getPhotoUrl = ({
    id,
    instanceId,
  }: {
    id: string;
    instanceId: string;
  }): Promise<string> => this.api.getImageUrl(`media/${instanceId}/${id}`);

  getThumbnailUrl = ({
    id,
    instanceId,
    timeout = 10000,
  }: {
    id: string;
    instanceId: string;
    timeout?: number;
  }): Promise<string> =>
    this.api.getImageUrl(
      `/media/${instanceId}/${id.split('/')[0]}/${id.split('/')[1]}/thumbnail`,
      { timeout }
    );

  uploadPhoto = async ({
    fieldId,
    file,
    instanceId,
    onProgress,
  }: {
    fieldId: string;
    file: File;
    instanceId: string;
    onProgress: (progress: number) => void;
  }) => {
    const url = `/media/${instanceId}/${fieldId}`;
    const response = await this.api.uploadFile<FilesData>({
      file,
      onProgress,
      url,
    });
    return response?.data?.files[0]?.id || '';
  };
}

export const PhotoService = new Service();
