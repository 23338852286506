import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { ReportRenderService } from '@pro4all/quality-control/data-access';
import { useGetReportImageUrls } from '@pro4all/shared/qc-report-assets';
import { useRouting } from '@pro4all/shared/routing-utils';
import { DownloadReportButtonProps } from '@pro4all/shared/types';
import { Button } from '@pro4all/shared/ui/buttons';

import { useReportOptionsContext } from '../ReportOptionsProvider';

export const DownloadReportButton = ({
  members,
  tasks,
}: DownloadReportButtonProps) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isProcessing, setIsProcessing] = useState(false);
  const token = AuthService.getAccessToken() as string;

  const {
    state: { customMode, reportOptions, miniMaps, reportDrawings },
  } = useReportOptionsContext() || {};

  const { background, backgroundFrontPage, companyLogo } =
    useGetReportImageUrls({ reportOptions, token });

  const {
    params: { projectId },
    state: { instanceIds, taskInstanceIds },
  } = useRouting<{
    instanceIds: string[];
    taskInstanceIds: string[];
  }>();

  const { projectName } = useProjectContext();

  if ((!instanceIds && !taskInstanceIds) || !projectId) {
    return null;
  }

  const onComplete = () => setIsProcessing(false);

  return (
    <Button
      disabled={isProcessing}
      onClick={async () => {
        setIsProcessing(true);
        try {
          await ReportRenderService.downloadReport(
            {
              background,
              backgroundFrontPage,
              companyLogo,
              customMode,
              instanceIds,
              lang: i18n.language,
              members,
              miniMaps,
              options: reportOptions,
              projectId,
              projectName,
              reportDrawings,
              tasks: tasks || [],
            },
            onComplete
          );
        } catch (e) {
          enqueueSnackbar(
            t('Something went wrong. Please try to download the report again.'),
            { variant: 'error' }
          );
        }
      }}
      startIcon="download"
      variant="contained"
    >
      {isProcessing ? `${t('Processing')}...` : t('Download PDF')}
    </Button>
  );
};
