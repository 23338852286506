import React from 'react';

import { useSideNavContext } from '@pro4all/shared/contexts';
import { DialogContent, Paper } from '@pro4all/shared/mui-wrappers';

type ModalLayoutFrameProps = {
  children?: React.ReactNode;
};

export const ModalLayoutFrame = ({ children }: ModalLayoutFrameProps) => {
  const {
    sideNavState: { isSideNavOpen },
  } = useSideNavContext();

  return (
    <Paper
      role="dialog"
      square
      sx={{
        '& .MuiDialogContent-root': {
          padding: 0,
        },
        border: 'none',
        left: isSideNavOpen ? 216 : 64,
        m: 0,
        p: 0,
        position: 'fixed',
        right: 0,
        top: 60,
        zIndex: 2,
      }}
      tabIndex={-1}
      variant="outlined"
    >
      <DialogContent>{children}</DialogContent>
    </Paper>
  );
};
