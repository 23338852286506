import React from 'react';

import { QcPermissionCategory, Task } from '@pro4all/graphql';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { Action } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useTableCheck } from '@pro4all/shared/ui/table';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';
import { usePredefinedFilters } from '@pro4all/shared/ui/tasks';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useCreateTask } from './useCreateTask';

export const TasksActionBar: React.FC = () => {
  const { createTaskButtonProps } = useCreateTask();
  const { checkedRows } = useTableCheck<Task>();
  const qcTasksReportFF = useFeatureFlag('quality-task-reports');

  const { ACTION_CREATE_REPORT } = useCentralActions();

  const { goTo, params } = useRouting();
  const { track } = useAnalytics();

  const predefinedFilters = usePredefinedFilters();

  const { qualityCreateAll, qualityCreateOwn } = useQCPermissions({
    category: QcPermissionCategory.Procedure,
  });

  const altActions = [
    qcTasksReportFF && {
      ...ACTION_CREATE_REPORT,
      onClick: () => {
        track(Action.CreateTaskReportButtonClicked, {
          itemAmount: checkedRows.length,
          projectId: params.projectId,
        });

        goTo({
          searchParams: { action: 'createReport' },
          state: {
            taskInstanceIds: checkedRows.map((row) => row.id),
          },
        });
      },
    },
  ].filter((action) => Boolean(action)) as ActionProps[];

  return (
    <TableActionBar
      altActions={altActions}
      dataTestid="qc-tasks-action-bar"
      dataViewToggle
      mainActions={
        [
          (qualityCreateAll || qualityCreateOwn) && createTaskButtonProps,
        ].filter(Boolean) as ActionProps[]
      }
      predefinedFilters={predefinedFilters}
      search
    />
  );
};
