import { ActionProps } from '@pro4all/shared/actions';
import { Sidebar } from '@pro4all/shared/ui/sidebar';

import { TbqQuestionSidebarTabs } from './TbqQuestionSidebarTabs';

interface HeaderAndActionBarProps {
  changeTabs: (value: string) => void;
  currentTab: string;
  sidebarActions: ActionProps[];
  title: string;
}

export const HeaderAndActionBar: React.FC<HeaderAndActionBarProps> = ({
  title,
  sidebarActions,
  currentTab,
  changeTabs,
}) => (
  <Sidebar.Header icon="tbqBrandcheck" title={title}>
    <Sidebar.SidebarActionBar sidebarActions={sidebarActions} />
    <TbqQuestionSidebarTabs
      onChange={(e, value) => changeTabs(value)}
      value={currentTab ?? ''}
    />
  </Sidebar.Header>
);
