import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/actions';

type Args = { onDelete: () => void; onEdit: () => void };
type HookProps = (args: Args) => ActionProps[];

export const useSubMenuActions: HookProps = ({ onDelete, onEdit }) => {
  const { t } = useTranslation();

  return [
    {
      ariaLabel: `Delete query`,
      dataTestId: 'delete-query',
      key: 'delete-query',
      label: t('Delete'),
      onClick: onDelete,
      startIcon: 'delete',
    },
    {
      ariaLabel: `Edit query`,
      dataTestId: 'edit-query',
      key: 'edit-query',
      label: t('Edit'),
      onClick: onEdit,
      startIcon: 'edit',
    },
  ];
};
