import axios from 'axios';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { Log, Profile, UserManager, WebStorageStateStore } from 'oidc-client';

import {
  IDENTITY_CONFIG,
  STORAGE_ID,
} from '@pro4all/authentication/src/config';
import { environment } from '@pro4all/authentication/src/environments';
import { StorageKeys } from '@pro4all/shared/config';

type TokenData = JwtPayload & { authenticationType: string };

const REFRESH_TOKEN_BEFORE_EXPIRATION = 30;

class AuthService {
  userManager: UserManager;

  enableLog = Boolean(
    window.localStorage.getItem(StorageKeys.ENABLE_CONSOLE_LOG) === 'true'
  );

  constructor() {
    this.logout.bind(this);
    this.userManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({
        store: window.localStorage,
      }),
    });

    // Logger
    Log.logger = console;
    Log.level = Log.ERROR;

    this.userManager.events.addUserLoaded(() => {
      // HACK: FusionAuth doesn't acccept an invalid token_hint on logout which
      // presents the user with an unhelpful error page when logging out.
      // Because oidc-client checks the token every 2000ms by default and
      // default expiry is set 1 second before actual token expiration, it has
      // a huge chance to logout with an invalid token. If we give the
      // UserManager a tiny bit more leeway in calling logout the user is
      // properly returned to the login page.
      this.userManager.getUser().then((user) => {
        if (this.enableLog) console.log('addUserLoaded', user, new Date());
        if (user) {
          user.expires_at = user.profile.exp - REFRESH_TOKEN_BEFORE_EXPIRATION;
          this.userManager.storeUser(user);
        } else {
          this.logout();
        }
      });

      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen();
      }
    });

    // Refresh token when it's about to expire. Only works when the user is
    // active on the page.
    this.userManager.events.addAccessTokenExpiring(async () => {
      const userData = this.getUserData();
      if (this.enableLog)
        console.log(
          'AccessTokenExpiring  pre-signinSilent().',
          userData,
          new Date()
        );
      await this.userManager.signinSilent();
    });

    // Refresh token when it's expired. Works when the user returns to the page
    this.userManager.events.addAccessTokenExpired(async () => {
      if (this.enableLog)
        console.log('AccessTokenExpired invoked.', new Date());
      let user;
      try {
        user = await this.userManager.signinSilent();
        if (this.enableLog) console.log('signinSilent succeeded.', new Date());
      } catch (e) {
        if (this.enableLog)
          console.error('caught error in token refresh:', e, new Date());
      }
      if (user) {
        if (this.enableLog) console.log('storeUser', new Date());
        await this.userManager.storeUser(user);
        window.location.reload();
      } else {
        this.logout();
      }
    });

    // Logout when silent renew fails
    this.userManager.events.addSilentRenewError((e) => {
      if (this.enableLog) console.log('addSilentRenewError', e, new Date());
      this.logout();
    });
  }

  signinRedirectAction() {
    if (this.enableLog) console.log('signinRedirectAction', new Date());
    const { pathname, search = '' } = window.location;
    localStorage.setItem('redirectUri', `${pathname}${search}`);
    this.userManager.signinRedirect({});
  }

  signinRedirectCallbackAction() {
    if (this.enableLog) console.log('signinRedirectCallbackAction', new Date());
    return this.userManager.signinRedirectCallback();
  }

  signinSilentCallbackAction() {
    if (this.enableLog) console.log('signinSilentCallbackAction', new Date());
    this.userManager.signinSilentCallback();
  }

  async signoutRedirectCallbackAction() {
    if (this.enableLog) console.log('signoutRedirectCallback', new Date());
    await this.userManager.signoutRedirectCallback();
    if (environment)
      window.location.replace(environment.authentication.publicUrl);
    this.userManager.clearStaleState();
  }

  async logout() {
    if (this.enableLog) console.log('logout', new Date());
    // Revoke the session.
    await this.revokeRefreshToken();
    // Redirect to the signout url.
    this.userManager.signoutRedirect({
      id_token_hint: this.getIdToken(),
    });
    this.userManager.clearStaleState();
  }

  // Called on logout, revoke the refresh token (terminate session) if it's valid.
  async revokeRefreshToken() {
    try {
      const { refresh_token, access_token } = this.getUserData();

      await axios.delete(`${IDENTITY_CONFIG.authority}/api/jwt/refresh`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        params: {
          token: refresh_token,
        },
        validateStatus: function (status) {
          return (status >= 200 && status < 300) || status === 404;
        },
      });
    } catch {
      // Ignore, don't bother the user.
    }
  }

  getUserData() {
    return JSON.parse(localStorage.getItem(STORAGE_ID) || '{}');
  }

  getProfile(): Profile & { tenantId: string; userId: string } {
    return this.getUserData()?.profile;
  }

  getAccessToken(redirectOnInvalid = true) {
    // Get and deserailize the User object from local storage.
    const { expires_at, access_token } = this.getUserData();
    const currentTimestamp = Math.floor(Date.now() / 1000);

    // Check if the token expiration is in the future, add the previously subtracted refresh margin.
    if (
      expires_at &&
      expires_at + REFRESH_TOKEN_BEFORE_EXPIRATION > currentTimestamp
    ) {
      return access_token;
    }

    if (redirectOnInvalid) {
      if (this.enableLog)
        console.log(
          'access token expired, starting authorization flow',
          new Date()
        );
      this.signinRedirectAction();
    }
    return undefined;
  }

  getAccessTokenDecoded() {
    const token = this.getAccessToken();
    return jwtDecode(token) as TokenData;
  }

  getIdToken() {
    return this.getUserData()?.id_token;
  }

  isAuthenticated() {
    return Boolean(this.getAccessToken(false));
  }

  navigateToScreen() {
    const uri = localStorage.getItem('redirectUri');
    window.location.replace(uri || '/');
  }

  parseJwt() {
    const base64Url = this.getAccessToken().split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
}

export default new AuthService();
