import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { downloadDocument } from '@pro4all/documents/ui/share';
import { DocumentDetails } from '@pro4all/documents/ui/sidebar';
import {
  Document,
  useSmartFolderQuery,
  useSmartFoldersQuery,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import {
  useDocumentActions,
  useDocumentSelection,
} from '@pro4all/shared/hooks/src/documents';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Position } from '@pro4all/shared/types';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { Loader } from '@pro4all/shared/ui/loader';
import { BigMessageNoDocumentsInSmartFolder } from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';
import { TaskSidebar } from '@pro4all/workflow/ui/task-sidebar';

import { useColumns } from './hooks/useColumns';
interface Props {
  folderName: string;
  setActionLoading?: Dispatch<SetStateAction<boolean>>;
}

export const SmartFolderActionBar = ({
  setActionLoading,
}: {
  setActionLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const { smartFolderActions } = useDocumentActions({
    isSpecialFolderProp: true,
    setLoading: setActionLoading,
  });

  return (
    <TableActionBar
      altActions={smartFolderActions}
      dataTestid="smart-folder-action-bar"
      search
    />
  );
};

export const SmartFolderTable: React.FC<Props> = ({ folderName }) => {
  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  const {
    data: smartFoldersObject,
    stopPolling: stopPollingFolders,
    startPolling: startPollingFolders,
  } = useSmartFoldersQuery({
    pollInterval: 1000,
    variables: {
      projectId,
    },
  });

  const folderId = (smartFoldersObject?.smartFolders || []).find(
    (smartFolder) => smartFolder.name === folderName
  )?.id;

  const {
    data,
    error,
    loading,
    startPolling: startPollingFolder,
  } = useSmartFolderQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 1000,
    skip: !folderId,
    variables: { id: folderId },
  });

  /* Check if we have the current data folder to stop polling 
    and re-start polling with a longer interval in the current Folder */
  if (data && data.smartFolder.name === folderName) {
    stopPollingFolders();
    startPollingFolder(60000 * ApiConfig.pollEnabled);
  }

  /* Re-start Folders list and current folder if our folder name change */
  useEffect(() => {
    startPollingFolders(1000);
    startPollingFolder(1000);
  }, [folderName, startPollingFolders, startPollingFolder]);

  const [actionLoading, setActionLoading] = useState(false);

  const documents = useMemo(
    () => data?.smartFolder.documents || [],
    [data?.smartFolder.documents]
  );

  useSetItemsInLocalState<Document>(documents);

  const {
    deselectDocument,
    selectDocument,
    selectedDocument,
    changeToAnotherFolder,
  } = useDocumentSelection(documents);

  const documentId = searchParams.get('id');

  useEffect(() => {
    changeToAnotherFolder();
  }, [folderId, changeToAnotherFolder]);

  const columns = useColumns({});

  const {
    state: { item, items, itemsInitial },
  } = useOptimisticResponseContext<Document>();

  const itemFallback = item || selectedDocument;

  const { smartFolderActions } = useDocumentActions({
    position: Position.Sidebar,
    setLoading: setActionLoading,
    sidebarRow: itemFallback,
  });

  // We need smartfolders.refetch() to show documents in a new created smartfolder instantly.
  // We need refetch() to show the appropriate documents if you change the filter of an existing smartfolder.

  if (loading) return <Loader />;

  return (
    <ResponseWrapper error={error} isLoading={!data}>
      <TableContextProvider
        columns={columns}
        id={`table-documents-${data?.smartFolder.id || ''}`}
        items={items}
      >
        <FilterContextProvider<Document>>
          <DocumentDetails
            documentId={documentId}
            isSmartFolder
            onClose={deselectDocument}
            sidebarActions={smartFolderActions}
          />
          <TaskSidebar />
          <SmartFolderActionBar setActionLoading={setActionLoading} />
          {items.length || itemsInitial.length ? (
            <Table
              enableKeyboardControl
              onRowClick={selectDocument}
              onRowDoubleClick={downloadDocument}
              overlayRenderer={actionLoading ? <Loader /> : null}
              selectedId={selectedDocument?.id}
            />
          ) : (
            <BigMessageNoDocumentsInSmartFolder />
          )}
        </FilterContextProvider>
      </TableContextProvider>
    </ResponseWrapper>
  );
};

export default SmartFolderTable;
