import { ActionProps, useCentralActions } from '@pro4all/shared/actions';

import { DocumentActionProps } from '../../types';

export const useOpenSearchAction = ({
  allDocumentsExpected,
  searchContext,
  selection,
}: Pick<
  DocumentActionProps,
  'allDocumentsExpected' | 'searchContext' | 'selection'
>) => {
  const { ACTION_OPEN_IN_SEARCH } = useCentralActions();

  const openSearchAction: ActionProps = {
    ...ACTION_OPEN_IN_SEARCH,
    disabled: allDocumentsExpected,
    onClick: () => {
      searchContext.setContextQuery(
        selection.map((item) => item.versionId || '').filter(Boolean)
      );
    },
  };

  return openSearchAction;
};
