import { useDocument } from '@pro4all/documents/data-access';
import { useCommentThreadQuery } from '@pro4all/graphql';
import { useCardToggle } from '@pro4all/shared/ui/card-toggle';

import { Comments } from '../shared';

export const SectionComments = () => {
  const { CardToggle, expanded } = useCardToggle({ identifier: 'comments' });

  const { document } = useDocument();
  const { data } = useCommentThreadQuery({
    skip: !document?.id,
    variables: { targetId: document?.id || '' },
  });

  const count = !data
    ? undefined
    : data.commentThread && data.commentThread.messages
    ? data.commentThread.messages.length
    : 0;

  return (
    <CardToggle count={count} icon="chatOutlined" label="Comments">
      {expanded && <Comments />}
    </CardToggle>
  );
};
