import { Hierarchy } from '@pro4all/graphql';
import { PasteType } from '@pro4all/shared/types';

import { MoveItemPayload, SelectItemPayload } from './hierarchyEditorReducer';

// ********* Interfaces
export interface BasePropsHierarchyItem {
  deletedAt?: Hierarchy['deletedAt'];
  expanded?: Hierarchy['expanded'];
  hasChildren?: Hierarchy['hasChildren'];
  id: Hierarchy['id'];
  level?: Hierarchy['level'];
  name: Hierarchy['name'];
  parentNodeId?: Hierarchy['parentNodeId'];
  previousNodeId?: Hierarchy['previousNodeId'];
  selected?: Hierarchy['selected'];
}

// ********* Types
export type AddData = {
  addType?: AddType;
  sourceId: string;
  sourceName: string;
  sourceParentId?: string | null;
} | null;

export type CallbackAddEditProps<HierarchyItem extends BasePropsHierarchyItem> =
  {
    dataAddItem: AddData;
    dataEditItem: AddData;
    isAddFirstItem: boolean;
    isAddItem: boolean;
    isAddLastItem: boolean;
    isEditItem: boolean;
    items: HierarchyItem[];
    name: string;
  };

export type CallbackDropProps<HierarchyItem extends BasePropsHierarchyItem> = {
  addType: AddType;
  sourceItem: HierarchyItem;
  targetItem: HierarchyItem;
};

export type CallbackPasteProps<HierarchyItem extends BasePropsHierarchyItem> = {
  pasteType: PasteType;
  sourceItem: HierarchyItem;
  targetItem: HierarchyItem;
};

export type CallBackProps<HierarchyItem extends BasePropsHierarchyItem> = {
  callbackAddEdit?: ({
    dataAddItem,
    dataEditItem,
    isAddFirstItem,
    isAddItem,
    isAddLastItem,
    isEditItem,
    items,
    name,
  }: CallbackAddEditProps<HierarchyItem>) => void;
  callbackClick?: (item: HierarchyItem) => void;
  callbackCopy?: (item: HierarchyItem) => boolean;
  callbackCustomIcons?: (item: HierarchyItem) => JSX.Element;
  callbackCut?: (item: HierarchyItem) => boolean;
  callbackDelete?: (item: HierarchyItem) => boolean;
  callbackDrag?: (item: HierarchyItem) => boolean;
  callbackDrop?: ({
    addType,
    sourceItem,
    targetItem,
  }: CallbackDropProps<HierarchyItem>) => boolean;
  callbackPaste?: ({
    pasteType,
    sourceItem,
    targetItem,
  }: CallbackPasteProps<HierarchyItem>) => boolean;
};

export type ItemFields = {
  name: string;
};

export type HierarchyPayload = {
  breadcrumbs: string[];
  value: string;
};

export type ItemProps<HierarchyItem extends BasePropsHierarchyItem> = {
  allItems: HierarchyItem[];
  childItemsToBeCopied: HierarchyItem[];
  editItem: (item: HierarchyItem) => void;
  id: string;
  ids: string[];
  item: HierarchyItem;
  items: HierarchyItem[];
  itemsFreezed: HierarchyItem[];
  itemsInitial: HierarchyItem[];
  itemsNonExisting: HierarchyPayload[];
  itemsOnEqualLevel: HierarchyItem[];
  level: number;
  moveItem: (moveData: MoveItemPayload) => void;
  multipleAnswers: boolean;
  newItem: HierarchyItem;
  newItems: HierarchyItem[];
  newParentNodeId: string;
  parentNodeId: string;
  previousNodeId: string;
  selectItems: (selectData: SelectItemPayload) => void;
  sourceItem: HierarchyItem;
  targetItem: HierarchyItem;
};

export type OnClose = {
  onClose: () => void;
};

export type SortItemsProps<HierarchyItem extends BasePropsHierarchyItem> = Pick<
  ItemProps<HierarchyItem>,
  'allItems' | 'level' | 'itemsOnEqualLevel'
>;

// ********* Enums
export enum AddType {
  CHILD = 'CHILD',
  SIBLING = 'SIBLING',
}
