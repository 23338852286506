import { useDeleteDocumentsMutation } from '@pro4all/graphql';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { NavigateAwayPrompt } from '@pro4all/shared/ui/navigate-away-prompt';

import { useDisableSave } from '../helpers';
import { useUploaderEditorContext } from '../UploaderEditorProvider';

export const NavigateAwayDialog = () => {
  const { searchParams } = useRouting();
  const [deleteDocuments] = useDeleteDocumentsMutation();

  const { setLocalStorageItem } = useLocalStorage<boolean>({
    key: `uploading-cancelled`,
  });

  const {
    state: {
      documentIdsContainerCreated,
      documentIdsUploaded,
      filesToUpload,
      isProcessed,
    },
  } = useUploaderEditorContext();

  const messageUpload =
    'Your documents aren’t uploaded yet. If you leave, you will stop the upload process and none will be uploaded.'; // i18n
  const messageEdit =
    'You have unsaved changes. By closing the documents editor you will lose any changes made to your documents.'; // i18n
  const titleUpload = 'Leave document upload'; // i18n
  const titleEdit = 'Discard unsaved changes?'; // i18n
  const uploadContext = Boolean(filesToUpload.length);
  const disableSave = useDisableSave({ uploadContext });
  const bypassRouterPrompt = uploadContext ? isProcessed : disableSave;

  if (bypassRouterPrompt) {
    return null; // Do not render `DiscardModal` if there are no unsaved changes anymore.
  }

  /**
   * During document creation we cannot navigate away from the upload modal.
   * That is because the expected documents must be deleted automatically
   * after the user navigates away and the related files are not uploaded.
   */
  return (
    <NavigateAwayPrompt
      bypassRouterPrompt={bypassRouterPrompt}
      message={uploadContext ? messageUpload : messageEdit}
      onConfirm={() => {
        // Remove the documents that were not uploaded because of the upload cancel.
        // Version 0 documents were created for these, but they never got a physical file.

        const documentIds = documentIdsContainerCreated.filter(
          (id) => !documentIdsUploaded.includes(id)
        );

        if (documentIds.length)
          deleteDocuments({
            update: (cache) => {
              documentIds.forEach((documentId) => {
                cache.evict({ id: `Document:${documentId}` });
              });
            },
            variables: { documentIds },
          });

        setLocalStorageItem(true);
        searchParams.clear();
      }}
      title={uploadContext ? titleUpload : titleEdit}
    />
  );
};
