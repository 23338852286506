import { Box } from '@pro4all/shared/mui-wrappers';
// import { BottomActionBar } from './BottomActionBar';
import { customColors } from '@pro4all/shared/themes';

import { ActionBar } from './action-bar/ActionBar';
import { Content } from './Content';
import { LayoutFrameProps } from './layoutFrameTypes';
import { TopBar } from './TopBar';

export const LayoutFrame = ({
  actions,
  columnContentLeft,
  columnContentMain,
  columnContentRight,
  title = '',
  onClose,
  showDivider,
}: LayoutFrameProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      overflow: 'hidden',
    }}
  >
    <TopBar onClose={onClose} title={title} />
    {actions && (
      <Box sx={{ borderBottom: `1px solid ${customColors.grey400}`, py: 1 }}>
        <Box sx={{ pl: 2 }}>
          <ActionBar actions={actions} showDivider={showDivider} />
        </Box>
      </Box>
    )}
    <Content
      columnContentLeft={columnContentLeft}
      columnContentMain={columnContentMain}
      columnContentRight={columnContentRight}
    />
    {/* For now we do not need the BottomActionBar for the document details because all components are self containing */}
    {/* <BottomActionBar /> */}
  </Box>
);
