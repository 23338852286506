import React, { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteGetTagProps,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  Box,
} from '@pro4all/shared/mui-wrappers';
import { Option } from '@pro4all/shared/types';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tag } from '@pro4all/shared/ui/tag';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { sortBy } from '@pro4all/shared/utils';
import { sortByMethod } from '@pro4all/shared/utils';

import { TextField } from '../../text-field/TextField';
import { DynamicPopper } from '../DynamicPopper';
import { useOnSearch } from '../useOnSearch';

import * as Styled from './SearchableMultiSelect.styles';
import { SearchableMultiSelectProps } from './SearchableMultiSelect.types';

// See: https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
// Use ref inside this component and have a ref forwarded to support react hook form
function useCombinedRefs(...refs: React.ForwardedRef<unknown>[]) {
  const targetRef = React.useRef(null);

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

export const SearchableMultiSelect: React.FC<SearchableMultiSelectProps> =
  React.forwardRef(
    (
      {
        autoFocus,
        canAddNewOptions,
        disabled,
        disableCloseOnSelect = true,
        error,
        fixedHeight,
        getLimitTagsText,
        helperText,
        label,
        limitTags,
        margin = 'dense',
        name,
        onBlur,
        onChange,
        onClickTag,
        onInputChange,
        onSearch,
        options,
        placeholder,
        renderCustomInput,
        renderCustomOption,
        renderSelectedTagsInInput = true,
        showValuesInTooltip = false,
        sortTagsBy,
        tabIndex,
        tagColor = 'default',
        tooltipTitle = '',
        value = [],
        variant = 'outlined',
        warning = false,
        ...rest
      },
      ref
    ) => {
      const inputRef = useRef(null);
      const [inputValue, setInputValue] = useState('');
      const [_maxHeight, setMaxHeight] = useState(0);
      const combinedRef = useCombinedRefs(ref, inputRef);

      const listboxRef = useRef<HTMLUListElement | null>(null);
      const previousScrollPosition = useRef(0);

      const handleOnSearch = useOnSearch({ onSearch });

      const getOptionName = (option: Option) =>
        option?.label ? option?.label : option?.inputValue;

      const getIcon = (option: Option) => {
        const { iconName } = option || {};
        return iconName && <Icon iconName={iconName} />;
      };

      const renderTags = (
        value: Option[],
        getTagProps: AutocompleteGetTagProps
      ) => {
        /** If sorting function is provided, sort the tags in place */
        if (sortTagsBy) {
          value.sort(sortByMethod(sortTagsBy));
        }

        return (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              maxHeight: '300px',
              overflowY: 'auto',
            }}
          >
            {value.map((option, index) => {
              const onDelete = (event: any) => {
                /**
                 * By default the focus is not put on the autocomplete field
                 * when deleting a tag. So here we explicitly put focus on
                 * the input before we remove the tag, so we can safely
                 * trigger the onChange handler while still maintaining the
                 * ability to trigger to this change onBlur.
                 */
                combinedRef.current.focus();
                onChange(
                  event,
                  value.filter((val) => val.id !== option?.id),
                  'removeOption'
                );
              };

              if (renderCustomInput) {
                return (
                  <Box
                    sx={{
                      mr: 1,
                      my: 0.75,
                    }}
                  >
                    {renderCustomInput({
                      hasBorder: true,
                      onDelete,
                      option,
                    })}
                  </Box>
                );
              } else {
                return (
                  <Tag
                    color={tagColor}
                    icon={getIcon(option)}
                    name={getOptionName(option)}
                    {...getTagProps({ index })}
                    disabled={disabled}
                    onClickTag={onClickTag}
                    onDelete={onDelete}
                    tagId={option?.id}
                  />
                );
              }
            })}
          </Box>
        );
      };

      const handleChange = (
        event: React.ChangeEvent,
        value: (string | Option)[],
        reason: AutocompleteChangeReason
      ) => {
        /**
         * If the user entered a manual entry in the input field
         * and pressed enter, we need to create an Option for it
         * that our SearchableMultiSelect understands before we pass it to
         * the calling Component as a value.
         * We also need to check if the value is already an
         * existing option. If so, we choose that one as the value
         * instead of creating a new one.
         */
        onChange(
          event,
          value
            .map((val: string | Option) => {
              if (typeof val === 'string') {
                const existingOption = options.find(
                  (option) => getOptionName(option) === val
                );
                if (existingOption) {
                  return existingOption;
                } else if (canAddNewOptions) {
                  return {
                    id: uuid(),
                    inputValue: val,
                    label: val,
                  };
                } else {
                  /**
                   * Not a valid option, and not allowed to add options, so we make it null
                   * to be able to filter it out next
                   */
                  return null;
                }
              }
              return val;
            })
            /** Remove entries that were invalid */
            .filter((entry) => entry !== null)
            /**
             * Remove any duplicates from the array. This may be caused
             * by a user typing and pressing enter on an already
             * existing key
             */
            .filter(
              (val, index, self) =>
                self
                  .map((compareVal) => getOptionName(compareVal))
                  .indexOf(getOptionName(val)) === index
            ),
          reason
        );
      };

      const handleInputChange = (
        event: React.ChangeEvent,
        value: string,
        reason: AutocompleteInputChangeReason
      ) => {
        setInputValue(value); // Update the local input value of the textfield input in the Autocomplete input
        if (onSearch) {
          handleOnSearch(value);
        }
        onInputChange && onInputChange(event, value, reason);
      };

      const renderInput = (params: AutocompleteRenderInputParams) => {
        const { InputProps, ...restParams } = params;
        const { startAdornment, ...restInputProps } = InputProps;

        const restParamsUpdates = {
          ...restParams,
          inputProps: { ...restParams.inputProps, value: inputValue },
        };

        return (
          <TextField
            {...restParamsUpdates}
            InputProps={{
              ...restInputProps,
              inputProps: {
                ...restParamsUpdates.inputProps,
                tabIndex,
              },
              startAdornment: fixedHeight ? (
                <Styled.TagsContainer>{startAdornment}</Styled.TagsContainer>
              ) : (
                startAdornment
              ),
            }}
            autoComplete="off"
            autoFocus={autoFocus}
            error={error}
            fullWidth
            helperText={helperText}
            inputRef={combinedRef}
            label={label}
            margin={margin}
            name={name}
            onBlur={(event) => {
              onBlur && onBlur(event);
              setInputValue(''); // Reset non-confirmed input value textfield input on blur
            }}
            onFocus={() => {
              setInputValue(''); // Reset possible old non-confirmed value textfield input on focus
            }}
            placeholder={placeholder}
            variant={variant}
            warning={warning}
          />
        );
      };

      // Sort the value array.
      const valueSorted = value.sort(sortBy({ key: 'label' }));

      const autocomplete = (
        <Autocomplete
          {...rest}
          ListboxProps={{
            onScroll: () => {
              // Store the current scrollposition
              if (listboxRef.current) {
                previousScrollPosition.current = listboxRef.current.scrollTop;
              }
            },
            ref: (node) => {
              // Check if the node-element ia a ul and cast it explicitly
              if (node && node instanceof HTMLUListElement) {
                listboxRef.current = node;
                // Restore scroll position when list is re-rendered
                node.scrollTop = previousScrollPosition.current;
              }
            },
            style: {
              maxHeight: `${_maxHeight}px`, // Use the dynamical height
              overflow: 'auto',
            },
          }}
          PopperComponent={(popperProps) => (
            <DynamicPopper {...popperProps} setMaxHeight={setMaxHeight} />
          )}
          autoComplete
          disableClearable={!renderSelectedTagsInInput}
          disableCloseOnSelect={disableCloseOnSelect}
          disabled={disabled}
          freeSolo
          getLimitTagsText={(more) =>
            getLimitTagsText ? getLimitTagsText(more) : `+${more}`
          }
          getOptionDisabled={(option: Option) => option?.disabled}
          getOptionLabel={(option: Option) => option?.label}
          isOptionEqualToValue={(option, value) => {
            if (typeof value === 'string') {
              return option === value;
            } else {
              const optionTyped = option as Option;
              return optionTyped.id === value.id;
            }
          }}
          limitTags={limitTags}
          multiple
          onChange={handleChange}
          onInputChange={handleInputChange}
          options={options}
          renderInput={renderInput}
          renderOption={(props, option: Option) => {
            const { iconColor, iconName, id, label, iconComponent } = option;
            if (renderCustomOption) {
              return renderCustomOption({ option, props }); // Render User or Group Card f.i., but can be any JSX.
            }
            return (
              <li {...props} key={id}>
                {iconName && (
                  <Styled.StyledIcon
                    htmlColor={iconColor}
                    iconName={iconName}
                  />
                )}
                {iconComponent && iconComponent}
                {label}
              </li>
            );
          }}
          renderTags={renderSelectedTagsInInput ? renderTags : () => null}
          value={valueSorted}
        />
      );

      if (tooltipTitle || (showValuesInTooltip && valueSorted.length > 0)) {
        const tooltipValue = tooltipTitle
          ? [tooltipTitle]
          : (valueSorted as (string | Option)[]).map((option) => {
              if (
                typeof option === 'object' &&
                option !== null &&
                'label' in option
              ) {
                return (option as Option).label;
              }

              return option as string;
            });

        return (
          <Tooltip
            placement="bottom"
            title={
              <div>
                {tooltipValue.map((value, index) => (
                  <div key={index}>{value}</div>
                ))}
              </div>
            }
          >
            {autocomplete}
          </Tooltip>
        );
      } else {
        return autocomplete;
      }
    }
  );
