import { useGotoStamper } from '@pro4all/documents/ui/stamper';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';

import { DocumentActionProps } from '../../types';

export const useStampAction = ({
  editableDocuments,
  editableVersions,
  isLocked,
}: Pick<
  DocumentActionProps,
  'editableDocuments' | 'editableVersions' | 'isLocked'
>) => {
  const { ACTION_ADD_QR } = useCentralActions();
  const isPdf = (extension?: string) => extension?.replace('.', '') === 'pdf';

  const stampAction: ActionProps = {
    ...ACTION_ADD_QR,
    disabled:
      isLocked ||
      (editableDocuments.length === 0 && editableVersions.length === 0) ||
      editableDocuments.some(({ isExpected }) => isExpected) ||
      editableVersions.some(({ isExpected }) => isExpected) ||
      !(
        editableDocuments.some(({ extension }) => isPdf(extension ?? '')) ||
        editableVersions.some(({ extension }) => isPdf(extension ?? ''))
      ),
    onClick: useGotoStamper(
      editableDocuments
        .filter(({ extension }) => isPdf(extension))
        .map(({ id: documentId, name, versionId: id }) => ({
          documentId,
          id,
          name,
        }))
        .concat(
          editableVersions
            .filter(({ extension }) => isPdf(extension))
            .map(({ documentId, name, id }) => ({
              documentId,
              id,
              name,
            }))
        )
    ),
  };

  return stampAction;
};
