import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Integration, useIntegrationsQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { Box, useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { Column } from '@pro4all/shared/ui/layout';
import { BigMessageNoIntegrations } from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';

import { DeleteIntegrationForm } from '../delete/DeleteIntegrationForm';
import { IntegrationForm } from '../form/IntegrationForm';

import { IntegrationsActionBar } from './IntegrationsActionBar';
import IntegrationsListMobile from './IntegrationsListMobile';
import { useColumnsIntegrations } from './useColumnsIntegrations';
import { useIntegrationsActions } from './useIntegrationsActions';

export const IntegrationsTable = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    state: { items, itemsInitial },
  } = useOptimisticResponseContext<Integration>();

  const { data, loading, error } = useIntegrationsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000 * ApiConfig.pollEnabled,
  });

  const integrations = useMemo(
    () => data?.integrations || [],
    [data?.integrations]
  ) as Integration[];

  const { searchParams } = useRouting();
  const addIntegration = searchParams.is('action', 'addIntegration');
  const deleteIntegration = searchParams.is('action', 'deleteIntegration');

  useSetItemsInLocalState<Integration>(integrations);

  const columnsIntegrations = useColumnsIntegrations();

  const { altActions } = useIntegrationsActions();
  const onClose = () => searchParams.delete('action');

  return (
    <>
      <TableContextProvider
        checkable={false}
        columns={columnsIntegrations}
        id="table-integrations"
        items={items}
      >
        <FilterContextProvider<Integration>>
          <Column>
            <IntegrationsActionBar />
            <ResponseWrapper error={error} isLoading={!data && loading}>
              {items.length === 0 && itemsInitial.length === 0 ? (
                <BigMessageNoIntegrations />
              ) : (
                <Box flex="auto">
                  {isMobile ? (
                    <IntegrationsListMobile integrations={items} />
                  ) : (
                    <Table contextMenuActions={altActions} />
                  )}
                </Box>
              )}
            </ResponseWrapper>
          </Column>
        </FilterContextProvider>
      </TableContextProvider>
      <FloatingModal
        data-testid="create-integration-modal"
        onClose={onClose}
        open={addIntegration}
      >
        <FloatingModal.Header iconName="add">
          {t('Create integration')}
        </FloatingModal.Header>
        <IntegrationForm onClose={onClose} />
      </FloatingModal>
      <FloatingModal
        data-testid="delete-integration-modal"
        onClose={onClose}
        open={deleteIntegration}
      >
        <FloatingModal.Header iconName="delete">
          {t('Delete integration')}
        </FloatingModal.Header>
        <DeleteIntegrationForm items={items} onClose={onClose} />
      </FloatingModal>
    </>
  );
};
