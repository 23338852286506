import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const AiSummary: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g>
      <path
        clipRule="evenodd"
        d="M14.6704 3.06113C14.5104 3.02038 14.3452 3 14.175 3H6C5.45 3 4.97917 3.19583 4.5875 3.5875C4.19583 3.97917 4 4.45 4 5V19C4 19.55 4.19583 20.0208 4.5875 20.4125C4.97917 20.8042 5.45 21 6 21H18C18.55 21 19.0208 20.8042 19.4125 20.4125C19.8042 20.0208 20 19.55 20 19V10.5668C19.7982 10.6532 19.5775 10.7 19.35 10.7C18.6649 10.7 18.0414 10.2761 17.8117 9.62327L16.8592 6.91657L14.5742 6.10468C13.9331 5.87673 13.5 5.27755 13.5 4.6C13.5 3.9225 13.9327 3.32323 14.5742 3.09533L14.6704 3.06113ZM16 17H8C7.71667 17 7.47917 16.9042 7.2875 16.7125C7.09583 16.5208 7 16.2833 7 16C7 15.7167 7.09583 15.4792 7.2875 15.2875C7.47917 15.0958 7.71667 15 8 15H16C16.2833 15 16.5208 15.0958 16.7125 15.2875C16.9042 15.4792 17 15.7167 17 16C17 16.2833 16.9042 16.5208 16.7125 16.7125C16.5208 16.9042 16.2833 17 16 17ZM16 13H8C7.71667 13 7.47917 12.9042 7.2875 12.7125C7.09583 12.5208 7 12.2833 7 12C7 11.7167 7.09583 11.4792 7.2875 11.2875C7.47917 11.0958 7.71667 11 8 11H16C16.2833 11 16.5208 11.0958 16.7125 11.2875C16.9042 11.4792 17 11.7167 17 12C17 12.2833 16.9042 12.5208 16.7125 12.7125C16.5208 12.9042 16.2833 13 16 13ZM13 9H8C7.71667 9 7.47917 8.90417 7.2875 8.7125C7.09583 8.52083 7 8.28333 7 8C7 7.71667 7.09583 7.47917 7.2875 7.2875C7.47917 7.09583 7.71667 7 8 7H13C13.2833 7 13.5208 7.09583 13.7125 7.2875C13.9042 7.47917 14 7.71667 14 8C14 8.28333 13.9042 8.52083 13.7125 8.7125C13.5208 8.90417 13.2833 9 13 9Z"
        fillRule="evenodd"
      />
      <path d="M20.6003 3.27688C20.6404 3.39084 20.7298 3.48056 20.8437 3.52101L23.6236 4.50876C23.6806 4.52899 23.7 4.57173 23.7 4.6C23.7 4.62825 23.6806 4.671 23.6236 4.69125L20.8437 5.679C20.7298 5.71945 20.6404 5.80915 20.6003 5.9231L19.4734 9.12535C19.4615 9.159 19.4188 9.2 19.35 9.2C19.2812 9.2 19.2385 9.159 19.2266 9.12535L18.0998 5.9231C18.0597 5.80915 17.9702 5.71945 17.8564 5.679L15.0764 4.69125C15.0194 4.671 15 4.62825 15 4.6C15 4.57173 15.0194 4.52899 15.0764 4.50876L17.8564 3.52101C17.9702 3.48056 18.0597 3.39083 18.0998 3.27688L19.2266 0.074645C19.2385 0.0410215 19.2812 0 19.35 0C19.4188 0 19.4615 0.041021 19.4734 0.074645L20.6003 3.27688Z" />
    </g>
  </SvgIcon>
);
