import { ActionProps } from './actionTypes';
import { actionGroups } from './useCentralActionGroups';

export const useSortActions = () => {
  const sortActions = (actions: ActionProps[]): ActionProps[] => {
    // Make a map of actionGroupKey to groupOrder for a fast lookup
    const groupOrderMap = actionGroups.reduce<Record<string, number>>(
      (map, group) => {
        map[group.key] = group.groupOrder;
        return map;
      },
      {}
    );

    // Sort the actions
    return actions.sort((a, b) => {
      const groupOrderA =
        groupOrderMap[a.actionGroupKey ?? ''] ?? Number.MAX_SAFE_INTEGER;
      const groupOrderB =
        groupOrderMap[b.actionGroupKey ?? ''] ?? Number.MAX_SAFE_INTEGER;

      // Compare first on groupOrder
      if (groupOrderA !== groupOrderB) {
        return groupOrderA - groupOrderB;
      }

      // Within the same groupOrder, compare on actionOrder
      const actionOrderA = a.actionOrder ?? Number.MAX_SAFE_INTEGER;
      const actionOrderB = b.actionOrder ?? Number.MAX_SAFE_INTEGER;
      return actionOrderA - actionOrderB;
    });
  };

  return { sortActions };
};
