import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';

import { DocumentActionProps } from '../../types';

export const useToFolderAction = ({
  selection,
  sidebarRow,
}: Pick<DocumentActionProps, 'selection' | 'sidebarRow'>) => {
  const { goTo, params } = useRouting();
  const { ACTION_OPEN_IN_FOLDER } = useCentralActions();

  const toFolder = () => {
    const route = params.projectId ? 'projectDocs' : 'docs';
    const path = sidebarRow ? sidebarRow.path : selection[0].path;
    const id = sidebarRow ? sidebarRow.id : selection[0].id;
    goTo(route, {
      params: { path, projectId: params.projectId },
      searchParams: { id },
    });
  };

  const toFolderAction: ActionProps = {
    ...ACTION_OPEN_IN_FOLDER,
    disabled: !sidebarRow && selection.length > 1,
    onClick: toFolder,
  };

  return toFolderAction;
};
