import { useTranslation } from 'react-i18next';

import { useShareSnagstreamContext } from '@pro4all/documents/ui/share-sns';
import { ActionProps } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';

import { DocumentActionProps } from '../../types';

const snsFormats = ['pdf', 'png', 'jpg', 'jpeg'];

export const useShareToSnagstreamAction = ({
  active,
  editableDocumentsWithFinalized,
  isVersion,
}: Pick<
  DocumentActionProps,
  'active' | 'editableDocumentsWithFinalized' | 'isVersion'
>) => {
  const { t } = useTranslation();
  const { searchParams, params } = useRouting();
  const { projectId } = params;
  const { setSelectedDocuments } = useShareSnagstreamContext();

  const isSnsFileType = editableDocumentsWithFinalized.some((document) =>
    snsFormats.includes((document?.extension || '').replace('.', ''))
  );

  const openModal = () => {
    setSelectedDocuments(
      editableDocumentsWithFinalized.filter(
        (document) =>
          !document.isExpected &&
          snsFormats.includes((document?.extension || '').replace('.', ''))
      )
    );
    searchParams.set({
      action: 'sharetosnagstream',
    });
  };

  const shareToSnagstreamAction: ActionProps = {
    ariaLabel: t('Share to Snagstream'),
    dataTestId: 'share-document-to-snagstream',
    disabled:
      !active ||
      !isSnsFileType ||
      !projectId ||
      editableDocumentsWithFinalized.length === 0 ||
      isVersion,
    key: 'share-document-to-snagstream',
    label: t('Share to Snagstream'),
    onClick: openModal,
    startIcon: 'snagstream',
  };

  return shareToSnagstreamAction;
};
