import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DocumentsPdfTronViewer,
  PdfTronOpenAction,
} from '@pro4all/documents/ui/annotate';
import { Box } from '@pro4all/shared/mui-wrappers';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { ImagePreview } from '@pro4all/shared/ui/image-preview';

import { PreviewProps } from './DocumentPreview.types';
import { useSetDocumentPreview } from './useSetDocumentPreview';

const DocumentPreviewComponent: React.FC<PreviewProps> = ({
  document,
  open,
  onClose,
  version,
}) => {
  const { t } = useTranslation();

  const {
    currentVersion,
    documentIsImage,
    showPdfViewer,
    previewImages,
    slidesLoaded,
    totalPreviewPages,
  } = useSetDocumentPreview({
    document,
    open,
    version,
  });

  const unknown = t('Unknown');
  const name = document ? document.name : version ? version.name : unknown;

  return showPdfViewer ? (
    <FloatingModal
      disableEscapeKeyDown={false}
      fullScreen
      fullWidth
      maxWidth="xl"
      onClose={onClose}
      open={open}
      padding={false}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          padding: 0,
          width: '100%',
        }}
      >
        <DocumentsPdfTronViewer
          action={PdfTronOpenAction.PREVIEW}
          hideGoBack
          initialVersionId={currentVersion?.id}
        />
      </Box>
    </FloatingModal>
  ) : (
    <ImagePreview
      onClose={onClose}
      open={open}
      previewImages={previewImages}
      slidesLoaded={slidesLoaded}
      sphereModeEnabled={documentIsImage}
      title={name}
      totalPreviewPages={totalPreviewPages}
    />
  );
};

function skipUpdate(prevProps: PreviewProps, nextProps: PreviewProps) {
  return (
    prevProps.open === nextProps.open &&
    prevProps.document?.id === nextProps.document?.id &&
    prevProps.version?.id === nextProps.version?.id
  );
}

export const DocumentPreview = React.memo(DocumentPreviewComponent, skipUpdate);
