import { Routes } from '@pro4all/shared/config';

export const ProjectRoutes = [
  Routes.projDocsSharedByInvite,
  Routes.projDocsSharedInScope,
  Routes.project,
  Routes.projectAccess,
  Routes.projectAccessDocuments,
  Routes.projectAccessQualityControl,
  Routes.projectAccessTasks,
  Routes.projectAllMessages,
  Routes.projectDmsBase,
  Routes.projectDocs,
  Routes.projectDocsBase,
  Routes.projectDocsTasks,
  Routes.projectGroups,
  Routes.projectMembers,
  Routes.projectMessages,
  Routes.projectMetaDataDocuments,
  Routes.projectMetaDataDocumentsFields,
  Routes.projectMetaDataDocumentsTemplates,
  Routes.projectMetaDataHierarchicalLists,
  Routes.projectMetaDataQualityControl,
  Routes.projectMetaDataQualityControlFields,
  Routes.projectMetaDataQualityControlForms,
  Routes.projectMetaDataQualityControlReportConfigurations,
  Routes.projectMetaDataQualityControlSnags,
  Routes.projectMetaDataQualityControlTemplates,
  Routes.projectObjectDocuments,
  Routes.projectObjectDrawing,
  Routes.projectObjectDrawings,
  Routes.projectObjectResults,
  Routes.projectObjectTBQResults,
  Routes.projectObjectTBQMultiBrandcheckResults,
  Routes.projectObjectTBQRieResults,
  Routes.projectObjectTBQMultiRieResults,
  Routes.projectObjectTasks,
  Routes.projectObjectTbq,
  Routes.projectObjects,
  Routes.projectObjectTBQOsidLog,
  Routes.projectQualityControlDrawing,
  Routes.projectQualityControlDrawings,
  Routes.projectQualityControlMain,
  Routes.projectQualityControlMySavedAnswers,
  Routes.projectQualityControlRecurringSeries,
  Routes.projectQualityControlResults,
  Routes.projectQualityControlTasks,
  Routes.projectQualityControlNotifications,
  Routes.projectSettings,
  Routes.projectSettingsConnections,
  Routes.projectSettingsProject,
  Routes.projectSettingsDocuments,
  Routes.projectSmartFolder,
  Routes.projectSmartFolders,
  Routes.projectUsersGroups,
  Routes.projectWorkflows,
  Routes.projectDocsNotifications,
  Routes.projectTemplatesTasks,
];
