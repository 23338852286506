import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Folder } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useLinkProject = (folder?: Folder) => {
  const { searchParams } = useRouting();
  const { ACTION_PLACE_AND_LINK_PROJECT } = useCentralActions();
  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });

  const linkProjectAction: ActionProps = {
    ...ACTION_PLACE_AND_LINK_PROJECT,
    disabled: folder && !hasFolderPermission('UpdateFolder'),
    onClick: () => searchParams.set({ action: 'linkProjectToFolder' }),
  };

  return {
    linkProjectAction,
  };
};
