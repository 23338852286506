import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Folder, useDuplicateFolderStructureMutation } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  getFolderTreeStructure,
  isSomethingInClipboard,
} from '@pro4all/shared/storage-utils';

export const usePasteFolderTree = (folder?: Folder) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });
  const [duplicate] = useDuplicateFolderStructureMutation();
  const { projectId: targetProjectId } = useRouting().params;
  const { ACTION_PASTE } = useCentralActions();

  const paste = async () => {
    const { folderId: sourceFolderId, projectId: sourceProjectId } =
      getFolderTreeStructure();

    const resp = await duplicate({
      variables: {
        sourceFolderId,
        sourceProjectId,
        targetFolderId: folder?.id,
        targetProjectId,
      },
    });

    if (resp.data.duplicateFolderStructure.success) {
      enqueueSnackbar(
        t('Pasting folders has started, this could take a few minutes.')
      );
    } else {
      if (resp.data.duplicateFolderStructure.errorCode === 409) {
        enqueueSnackbar(
          t('Folder with name "{{sourceFolderName}}" exist in this folder.', {
            sourceFolderName: resp.data.duplicateFolderStructure.folderName,
          })
        );
      } else {
        enqueueSnackbar(t('Something went wrong. Please try again.'));
      }
    }
  };

  const pasteFolderTreeAction: ActionProps = {
    ...ACTION_PASTE,
    ariaLabel: 'Paste folder tree',
    dataTestId: 'paste-folder-tree',
    disabled:
      (folder && !hasFolderPermission('CreateSubFolder')) ||
      !isSomethingInClipboard(),
    key: 'paste-folder-tree',
    label: t('Paste folder tree'),
    onClick: paste,
  };

  return {
    pasteFolderTreeAction,
  };
};
