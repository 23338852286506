import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useRemoveFolderProjectLinkMutation } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/actions';
import {
  getLastVisitedProjectRoute,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { DMSProjectLink } from '@pro4all/shared/types';
import { Position } from '@pro4all/shared/ui/context-menu';

import { useFolderTreeContextOuter } from '..';

export const useProjectLinkActions = () => {
  const { searchParams, goTo } = useRouting();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [buttonPosition, setButtonPosition] = useState<Position>();

  const [removeLinkMutation] = useRemoveFolderProjectLinkMutation();
  const { deleteDMSItem: deleteProjectLinkInLocalState } =
    useFolderTreeContextOuter();

  /**
   * Refs for 'targetProjectLink' because we don't need extra
   * re-rendering on setting these values. Re-rendering goes via
   * contextMenuOpen (showing context menu).
   */
  const targetProjectLink = useRef(null);

  const contextMenuIsOpen =
    (searchParams.get('projectLinkPath') &&
      searchParams.get('action') === 'showProjectLinkContextMenu') ||
    false;

  const handleOpenMenu = (
    event: React.MouseEvent,
    projectLink: DMSProjectLink,
    path: string[]
  ) => {
    targetProjectLink.current = projectLink;
    setButtonPosition({
      left: event.clientX,
      top: event.clientY,
    });
    openContextMenu(event, path, projectLink);
  };

  const deleteLink = async () => {
    const reps = await removeLinkMutation({
      variables: { linkId: targetProjectLink.current.id },
    });
    if (reps.data.removeFolderProjectLink.success) {
      deleteProjectLinkInLocalState(targetProjectLink.current.id);
      enqueueSnackbar(
        t('Link to project "{{projectName}}" has been deleted.', {
          projectName: targetProjectLink.current.name,
        })
      );
    }
  };

  const openContextMenu = (
    event: React.MouseEvent,
    path: string[],
    projectLink?: DMSProjectLink
  ) => {
    event && event.preventDefault();

    targetProjectLink.current = projectLink;
    searchParams.delete('folderPath');
    searchParams.set({
      action: 'showProjectLinkContextMenu',
      projectLinkPath: encodeURIComponent(path.join('/')),
    });
  };

  const closeContextMenu = () => {
    setButtonPosition(undefined);
    if (searchParams.get('action') === 'showProjectLinkContextMenu') {
      searchParams.delete('action');
      searchParams.delete('projectLinkPath');
    }
  };

  const handleOnClick = () => {
    const projectId = targetProjectLink.current.projectId;
    updateLsLastVisitedProjectRoute({
      overwriteCurrentValue: false,
      projectId,
      route: 'projectWorkflows',
    });
    const { params, route } = getLastVisitedProjectRoute(projectId) || {};
    goTo(route || 'projectWorkflows', {
      params: { ...params, projectId },
      state: { prevoiusPage: '' },
    });
  };

  const goToProject: ActionProps = {
    ariaLabel: 'View project',
    dataTestId: 'view-project',
    key: 'view-project',
    label: t('View project'),
    onClick: handleOnClick,
    startIcon: 'arrowForward',
  };

  const removeLink: ActionProps = {
    ariaLabel: 'Remove project link',
    dataTestId: 'remove-project-link',
    key: 'remove-project-link',
    label: t('Remove project link'),
    onClick: deleteLink,
    startIcon: 'removeCircleOutline',
  };

  const menuItems: ActionProps[] = [goToProject, removeLink];

  return {
    buttonPosition,
    closeContextMenu,
    contextMenuIsOpen,
    handleOpenMenu,
    menuItems,
    targetProjectLink: targetProjectLink.current,
  };
};
