import React, { useEffect, useState } from 'react';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { logger } from '@pro4all/shared/api-logging';
import { StorageKeys } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SsoFailPage } from '@pro4all/shared/ui/sso-fail-page';

export const Sso = () => {
  const { searchParams } = useRouting();
  const ssoError = searchParams.get('error');
  const passwordChanged = searchParams.get('passwordChanged');
  const [hasError, setHasError] = useState(false);

  const enableLog = Boolean(
    window.localStorage.getItem(StorageKeys.ENABLE_CONSOLE_LOG) === 'true'
  );

  if (enableLog) {
    console.log('window', window.location);
    console.log('searchParams', searchParams);
  }

  useEffect(() => {
    if (enableLog) console.log('sso.tsc: useEffect()', passwordChanged);
    const signIn = async () => {
      if (enableLog) console.log('sso-signin: init.');
      if (passwordChanged) {
        if (enableLog) console.log('sso-signin: passwordChanged.');
        await AuthService.signoutRedirectCallbackAction();
      } else {
        try {
          if (enableLog) console.log('sso-signin: non password changed.');
          await AuthService.signinRedirectCallbackAction();
        } catch (error: any) {
          logger.error(error, 'error in {reactComponent}', 'Sso.tsx');
          if (enableLog)
            console.log('error on signinRedirectCallbackAction', error);
          setHasError(true);
        }
      }
    };
    signIn();
  }, [enableLog, passwordChanged]);

  if (enableLog) console.log('hasError', hasError);
  if (enableLog) console.log('ssoError', ssoError);
  if (enableLog) console.log('-------------------------------------');
  return hasError || ssoError ? <SsoFailPage /> : <span>Signing in...</span>;
};
