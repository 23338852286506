import React, { useRef } from 'react';

import { ObjectNode } from '@pro4all/graphql';
import { HierarchyEditor } from '@pro4all/shared/hierarchy-editor';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Resizable } from '@pro4all/shared/ui/general';
import { ContentNavWrap, ContentRow } from '@pro4all/shared/ui/layout';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';
import { TableContent } from '@pro4all/shared/ui/table';

import { ObjectDetail } from './view/detail/ObjectDetail';
import { useGetCustomIcons } from './view/helpers/useGetCustomIcons';
import { useOnObjectClick } from './view/helpers/useOnObjectClick';
import { Toolbar } from './view/toolbar/Toolbar';
import * as Messages from './Messages';
import { useObjectsContext } from './ObjectsContext';
import { ItemListWrapper } from './styles';

export const ObjectsView: React.FC = () => {
  const {
    params: { projectId },
  } = useRouting();
  const { objects } = useObjectsContext();
  const { hasAppPermission } = useAppPermissions();
  const scrollableDivRef = useRef<HTMLDivElement>(
    document.createElement('div')
  );

  const noAccess = !projectId && !hasAppPermission('OrganizationUpdate');
  const noObjects = !objects.length;

  const { getCustomIcons } = useGetCustomIcons();
  const { onObjectClickCallback } = useOnObjectClick();

  if (noAccess) return <BigMessageNoAccess />;
  if (!projectId && noObjects) return <Messages.NoObjects />;
  if (projectId && noObjects) return <Messages.NoProjectObjects />;

  return (
    <ContentRow>
      <Resizable initialWidth={500} threshold={960}>
        <ContentNavWrap>
          <Toolbar />
          <ItemListWrapper ref={scrollableDivRef}>
            <HierarchyEditor<ObjectNode>
              callbackClick={onObjectClickCallback}
              callbackCustomIcons={getCustomIcons}
              editMode={false}
              maxItemsRendered={1000}
              scrollableDivRef={scrollableDivRef}
            />
          </ItemListWrapper>
        </ContentNavWrap>
      </Resizable>
      <TableContent>
        <ObjectDetail />
      </TableContent>
    </ContentRow>
  );
};
