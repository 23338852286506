import { ObjectNode } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useOnObjectClick = () => {
  const {
    goTo,
    params: { projectId },
  } = useRouting();

  const onObjectClickCallback = (item: ObjectNode) => {
    const objectId = item.id;
    if (projectId) {
      goTo('projectObjectTbq', { params: { objectId, projectId } });
    } else {
      goTo('objectTBQ', { params: { objectId } });
    }
  };

  return { onObjectClickCallback };
};
