import { ActionProps, useCentralActions } from '@pro4all/shared/actions';

import { DocumentActionProps } from '../../types';

import { useOnClickUnlock } from './useOnClickUnlock';

export const useUnlockAction = ({
  editableDocuments,
  isAdmin,
  isLocked,
  isLockedUser,
  isSpecialFolder,
  isVersion,
  position,
  selection,
  uncheckAllRows,
}: Pick<
  DocumentActionProps,
  | 'editableDocuments'
  | 'isAdmin'
  | 'isLockedUser'
  | 'isLocked'
  | 'isSpecialFolder'
  | 'isVersion'
  | 'position'
  | 'selection'
  | 'uncheckAllRows'
>) => {
  const onClick = useOnClickUnlock({ position, selection, uncheckAllRows });
  const { ACTION_UNLOCK } = useCentralActions();

  const unlockAction: ActionProps = {
    ...ACTION_UNLOCK,
    disabled:
      editableDocuments.length === 0 ||
      !isLocked ||
      !(isAdmin || isLockedUser) ||
      isSpecialFolder ||
      isVersion ||
      selection.length > 1,
    onClick,
  };

  return unlockAction;
};
