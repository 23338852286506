import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PhotoContext } from '@pro4all/shared/contexts';
import { useAnswerEditContext } from '@pro4all/shared/qc-sources/answer-edit-context';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  FileUpload,
  useFileRejections,
  useFileUploadContext,
} from '@pro4all/shared/ui/file-upload';
import { TextFieldProps } from '@pro4all/shared/ui/inputs';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { PhotoSlider } from '../components/PhotoSlider';
import { usePhotoIds } from '../hooks/usePhotoIds';
import { usePhotoUpload } from '../hooks/usePhotoUpload';
import * as Styled from '../styles/Photo.styles';

interface Props extends TextFieldProps {
  isQualityControl?: boolean;
  setValue: (name: string, value: string) => void;
  value: string;
}

interface PreviewFile extends File {
  id: string;
  path?: string;
  preview: string;
}

const MAX_SIZE = 20000000;

const removeDuplicates = (arr: PreviewFile[], prop: keyof PreviewFile) => {
  const seen = new Set();
  return arr.filter((obj) => {
    const duplicate = seen.has(obj[prop]);
    seen.add(obj[prop]);
    return !duplicate;
  });
};

export const Media = ({ name, setValue, value, isQualityControl }: Props) => {
  // prop 'name' is the name of the form input which equals the input id.

  const { searchParams } = useRouting();
  const instanceId = searchParams.get('id');

  const [previewFiles, setPreviewFiles] = useState([]);

  const acceptedFileTypes = ['.bmp', '.gif', '.jpeg', '.jpg', '.png'];

  const { t } = useTranslation();

  const { photoIds } = usePhotoIds({ fieldId: name, initialValue: value });

  const [currentValue, setCurrentValue] = useState(photoIds);

  const { handleUploadPhotos } = usePhotoUpload({
    fieldId: name,
  });

  const onDropRejected = useFileRejections({
    acceptedFileTypes,
    maxSize: MAX_SIZE,
  });

  const { addPhotos } = useContext(PhotoContext);

  const { canSubmit, setCanSubmit, state, startUploading, stopUploading } =
    useAnswerEditContext();

  const {
    filesProcessed,
    filesSelectedToUpload,
    filesUploadedSuccessfully,
    isUploading,
  } = useFileUploadContext();
  const { idToUploadFor } = state;

  const statusCurrentField =
    idToUploadFor === name &&
    filesSelectedToUpload.length > 0 &&
    filesProcessed.length !== filesSelectedToUpload.length
      ? t(
          `{{current}} of {{maximum}} ${
            isQualityControl ? 'photos' : 'documents'
          } uploaded`,
          {
            current: filesProcessed.length,
            maximum: filesSelectedToUpload.length,
          }
        )
      : '';

  useEffect(() => {
    if (isUploading && idToUploadFor === name) {
      if (filesSelectedToUpload.length !== filesProcessed.length) {
        setCanSubmit(false);
      } else if (filesSelectedToUpload.length === filesProcessed.length) {
        const fileIds = filesUploadedSuccessfully.map((file) => file.id);
        const newValue = [...currentValue, ...fileIds]
          .map((s) => s.split('/')[0])
          .join(',');
        setValue && setValue(name, newValue);
        setCurrentValue([...currentValue, ...fileIds] as string[]);
        stopUploading();
        setCanSubmit(true);

        addPhotos(
          filesUploadedSuccessfully.map((fileUploaded) => ({
            fieldId: name,
            id: fileUploaded.id,
            instanceId: instanceId,
            url: fileUploaded.file.preview,
          }))
        );
      }
    }

    if (filesUploadedSuccessfully.length > 0) {
      let newPreviewFiles = previewFiles;

      filesUploadedSuccessfully.forEach((fileObj) => {
        const match = previewFiles.find(
          (previewFile) => previewFile.id === fileObj.file.id
        );
        if (match) {
          newPreviewFiles = newPreviewFiles.filter(
            (file) => file.id !== match.id
          );
        }
      });

      setPreviewFiles(newPreviewFiles);
    }
  }, [
    addPhotos,
    currentValue,
    filesProcessed,
    filesSelectedToUpload,
    filesUploadedSuccessfully,
    idToUploadFor,
    instanceId,
    isUploading,
    name,
    previewFiles,
    setCanSubmit,
    setValue,
    stopUploading,
    value,
  ]);

  const handleDroppedFiles = (acceptedFiles: File[]) => {
    const newPreviewFiles = removeDuplicates(
      previewFiles.concat(
        acceptedFiles.map((file, index) =>
          Object.assign(file, {
            id: index,
            preview: URL.createObjectURL(file),
          })
        )
      ),
      'path'
    );

    setPreviewFiles(newPreviewFiles);

    handleUploadPhotos(newPreviewFiles);
  };

  return (
    <>
      <FormWrapper noPadding>
        <PhotoSlider
          canDelete
          fieldId={name}
          ids={currentValue}
          setCurrentValue={setCurrentValue}
          setValue={setValue}
        />
      </FormWrapper>

      <FileUpload
        accept={acceptedFileTypes}
        onDrop={handleDroppedFiles}
        onDropRejected={onDropRejected}
      >
        {({ openFileInput }) => (
          <Styled.UploadWrapper>
            <Styled.UploadButton
              aria-label={t('Upload')}
              color="primary"
              disabled={!canSubmit}
              onClick={() => {
                startUploading(name);
                openFileInput();
              }}
              startIcon="upload"
            >
              {t('Upload')}
            </Styled.UploadButton>
            <Text>{statusCurrentField ? statusCurrentField : null}</Text>
          </Styled.UploadWrapper>
        )}
      </FileUpload>
    </>
  );
};
