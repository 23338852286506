import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { Routes } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Position } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

export const useUploadVersionAction = ({
  allDocumentsFinalized,
  hasFolderPermission,
  isLocked,
  isVersion,
  openFileInput,
  position,
}: Pick<
  DocumentActionProps,
  | 'allDocumentsFinalized'
  | 'hasFolderPermission'
  | 'isLocked'
  | 'isVersion'
  | 'openFileInput'
  | 'position'
>) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { ACTION_UPLOAD } = useCentralActions();

  const isSmartFolderRoute = useRouteMatch([
    Routes.projectSmartFolder,
    Routes.smartFolder,
  ]);

  const isSearchContext = searchParams.get('search') !== null;

  const uploadVersionAction: ActionProps = {
    ...ACTION_UPLOAD,
    disabled:
      !hasFolderPermission('CreateContent') ||
      Boolean(isSmartFolderRoute) ||
      Boolean(isSearchContext) ||
      (position !== Position.Contextmenu && position !== Position.Sidebar) ||
      allDocumentsFinalized ||
      isLocked ||
      isVersion,
    label: t('Upload version'),
    onClick: openFileInput,
  };

  return uploadVersionAction;
};
