import { AuthService } from '@pro4all/authentication/src/services/auth-service';

export const AccessTokenInterceptor = (config: {
  headers: { [key: string]: string };
}) => {
  // We don't redirect to login page if the token is expired or missing for
  // requests that go directly to the services from the front-end. This is
  // to prevent anonymous links from redirecting to the login page.
  const token = AuthService.getAccessToken(false);
  config.headers['Authorization'] = `Bearer ${token}`;
  return config;
};

export default AccessTokenInterceptor;
