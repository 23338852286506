import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  ScopeType,
  useGenerateDocumentKeywordsMutation,
} from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { supportedExtensionsAiKeywordGeneration } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useAi } from '@pro4all/shared/hooks';

import { DocumentActionProps } from '../../types';

export const useGenerateKeywordsAction = ({
  allDocumentsUnfinalized,
  projectId,
  selection,
}: Pick<
  DocumentActionProps,
  'allDocumentsUnfinalized' | 'projectId' | 'selection'
>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { ACTION_CREATE_KEYWORDS } = useCentralActions();

  const { aiEnabledContext } = useAi(
    projectId ? ScopeType.Project : ScopeType.Organization
  );

  const hasGenerateKeywordsFeatureFlag = useFeatureFlag('generate-keywords');

  const extensions = selection.map((version) => version.extension);

  // Check if one of the values of the array `extensions` is in the array `supportedExtensionsAiKeywordGeneration`.
  const hasSupportedExtension = extensions.some((extension) =>
    supportedExtensionsAiKeywordGeneration.includes(extension || '')
  );

  const [generateDocumentKeywords] = useGenerateDocumentKeywordsMutation();

  const handleGenerateKeywords = async () => {
    const versionIds = selection.map(
      (selectionItem) => selectionItem.versionId || selectionItem.id
    );
    try {
      await generateDocumentKeywords({
        variables: { versionIds },
      });
      enqueueSnackbar(t('Keywords are being generated.'));
    } catch (error) {
      enqueueSnackbar(t('Could not generate keywords, please try again.'));
    }
  };

  const generateKeywordsAction: ActionProps = {
    ...ACTION_CREATE_KEYWORDS,
    disabled:
      !hasGenerateKeywordsFeatureFlag ||
      !hasSupportedExtension ||
      !aiEnabledContext ||
      !allDocumentsUnfinalized,
    onClick: handleGenerateKeywords,
  };

  return generateKeywordsAction;
};
