import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';

import { useDeleteGroups } from './useDeleteGroups';

export const useSidebarActions = () => {
  const deleteGroups = useDeleteGroups();

  const { ACTION_DELETE, ACTION_EDIT } = useCentralActions();

  const {
    params: { projectId },
    searchParams,
  } = useRouting();

  const isEditing = searchParams.is('action', 'editGroup');
  const isViewing = searchParams.is('action', 'viewGroup');

  const handleClose = () => searchParams.clear();

  const { hasAppPermission } = useAppPermissions();
  const userCannotUpdate = projectId
    ? !hasAppPermission('ProjectUpdate')
    : !hasAppPermission('OrganizationUpdate');

  const actionEdit: ActionProps = {
    ...ACTION_EDIT,
    dataTestId: 'edit-group',
    disabled: userCannotUpdate,
    key: 'edit-group',
    onClick: () => searchParams.set({ action: 'editGroup' }),
  };

  const actionDelete: ActionProps = {
    ...ACTION_DELETE,
    dataTestId: 'delete-group',
    disabled: userCannotUpdate,
    key: 'delete-group',
    onClick: () => {
      deleteGroups();
      handleClose();
    },
  };

  return {
    handleClose,
    sidebarActions: isEditing
      ? [actionDelete]
      : isViewing
      ? [actionEdit, actionDelete]
      : [],
  };
};
