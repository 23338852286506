import { useSupportedFormatsQuery } from '@pro4all/graphql';

import { excludedExtensions, supported3DExtensions } from './extensions3D';

export const use3DExtension = () => {
  const { data } = useSupportedFormatsQuery({ fetchPolicy: 'cache-first' });
  const supportedFormats = data?.supportedFormats ?? supported3DExtensions;

  const isSupported3DExtension = (extension: string) =>
    supportedFormats
      .filter(
        (supported) =>
          !excludedExtensions.find((excluded) => excluded === supported)
      )
      ?.includes((extension || '').replace('.', ''));

  return { isSupported3DExtension };
};
