import { useTranslation } from 'react-i18next';

import { ActionProps, useCentralActions } from '@pro4all/shared/actions';

import { useFolderTreeContextOuter } from '../tree/FolderTreeProviderOuter';

export const useHideShowEmptyFolders = () => {
  const { t } = useTranslation();
  const { ACTION_HIDE_SHOW_EMPTY_FOLDERS } = useCentralActions();

  const {
    state: { hideEmptyFolders },
    toggleEmptyFoldersDisplay,
  } = useFolderTreeContextOuter();

  const hideShowEmptyFoldersAction: ActionProps = {
    ...ACTION_HIDE_SHOW_EMPTY_FOLDERS,
    ariaLabel: hideEmptyFolders ? 'Show empty folders' : 'Hide empty folders',
    dataTestId: hideEmptyFolders ? 'show-empty-folders' : 'hide-empty-folders',
    key: hideEmptyFolders ? 'show-empty-folders' : 'hide-empty-folders',
    label: hideEmptyFolders ? t('Show empty folders') : t('Hide empty folders'),
    onClick: toggleEmptyFoldersDisplay,
    startIcon: hideEmptyFolders ? 'folder' : 'folderEmptyHidden',
  };

  return {
    hideShowEmptyFoldersAction,
  };
};
