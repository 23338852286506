import {
  Document,
  DocumentVersion,
  FieldDefinition,
  Instance,
} from '@pro4all/graphql';

import { MetaData } from '../../useUploaderEditorReducer';

import { useGetErrorMetadata, useGetWarningMetadata } from '.';

export const useGetMetaData = () => {
  const getError = useGetErrorMetadata();
  const getWarning = useGetWarningMetadata();

  const getMetaData = ({
    document,
    fields,
    intialize,
    isVersion,
    metaData,
    version,
  }: {
    document: Document;
    fields: FieldDefinition[];
    intialize: boolean;
    isVersion?: boolean;
    metaData: MetaData[];
    version?: DocumentVersion;
  }) => {
    const answers = isVersion
      ? (version?.metaData?.answers as Instance[]) || []
      : (document.metaData?.answers as Instance[]) || [];

    const metaDataUpdated = fields.map((fieldDefinition) => {
      const { fieldDefinitionId, id, name } = fieldDefinition;
      const fieldId = id;

      // We need this to set `valueInitial` properly.
      // This prop is important when user removes the value from the `New version for` column.
      const metaDataField = metaData.find(
        (field) => field.fieldDefinitionId === fieldId
      );

      const compareFieldId = fieldDefinitionId ? fieldDefinitionId : id;
      const answer = answers.find(
        (answer) => answer.fieldDefinitionId === compareFieldId // To search for reusable fields
      ) as Instance;
      const value = answer?.value || '';

      return {
        error: getError({ fieldDefinition, value }),
        fieldDefinitionId: fieldId,
        isUpdatedManually: false,
        name: name as string,
        value,
        valueInitial: intialize ? value : metaDataField?.valueInitial || '',
        warning: getWarning({ fieldDefinition, value }),
      };
    });

    return metaDataUpdated;
  };

  return getMetaData;
};
