import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  AuthenticatedApiService,
  FilesData,
} from '@pro4all/authentication/src/services/authenticated-api-service';
import {
  GetProfileUrlProps,
  ProfilePictureEntity,
} from '@pro4all/shared/types';
import { resizeImage } from '@pro4all/shared/utils/images';

export const IdentityBaseURL = 'https://graph.prostream.dev/identity/';

class Service {
  api: AuthenticatedApiService;

  constructor() {
    this.api = new AuthenticatedApiService(IdentityBaseURL);
  }

  getProfilePicture = async ({
    url,
  }: {
    url: string;
  }): Promise<File | undefined> => {
    const response = await this.api.getImageUrl(url);

    if (response) {
      // Convert the blob URL to a File object
      const responseUrl = await fetch(response);
      const blob = await responseUrl.blob();
      const file = new File([blob], 'profile-picture.jpg', {
        type: blob.type,
      });

      return file;
    }

    return undefined;
  };

  getProfileUrl = ({
    bypassCache = false,
    id,
    profilePictureEntity,
  }: GetProfileUrlProps) => {
    const accessTokenValue = AuthService.getAccessToken();
    const timeParam = bypassCache ? `&t=${new Date().getTime()}` : '';
    const urlSuffix = `/${id}/picture?access_token=${accessTokenValue}${timeParam}`;
    const url =
      profilePictureEntity === ProfilePictureEntity.Organization
        ? `${IdentityBaseURL}/v2/organization${urlSuffix}`
        : profilePictureEntity === ProfilePictureEntity.Project
        ? `${IdentityBaseURL}/v2/project${urlSuffix}`
        : profilePictureEntity === ProfilePictureEntity.User
        ? `${IdentityBaseURL}/v2/user${urlSuffix}`
        : '';
    return url;
  };

  removeProfilePicture = async ({ url }: { url: string }) =>
    this.api.removeProfilePicture(url);

  uploadProfilePicture = async ({
    file,
    onProgress,
    url,
  }: {
    file: File;
    onProgress: (progress: number) => void;
    url: string;
  }) => {
    const resizedFile = await resizeImage({
      file,
      maxHeight: 500,
      maxWidth: 500,
    });

    const response = await this.api.uploadFile<FilesData>({
      file: resizedFile,
      onProgress,
      url,
    });
    return response && response.data && response.data.files
      ? response?.data?.files[0]?.id
      : '';
  };
}

export const IdentityService = new Service();
