import { LockType } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';

import { DocumentActionProps } from '../../types';

export const useEditPopupAction = ({
  editableDocuments,
  editableVersions,
  isSpecialFolder,
  isVersion,
  subItems,
}: Pick<
  DocumentActionProps,
  'editableDocuments' | 'editableVersions' | 'isSpecialFolder' | 'isVersion'
> & { subItems: ActionProps[] }) => {
  const { goTo } = useRouting();
  const { ACTION_EDIT } = useCentralActions();

  const editableDocumentsWithoutLocked = editableDocuments.filter(
    (doc) => doc.lockType !== LockType.Prostream
  );

  const editMetaData = () => {
    goTo({
      searchParams: { action: 'editMetaData', f: undefined, fullscreen: true },
      state: {
        documentIdsForEdit: editableDocumentsWithoutLocked.map(({ id }) => id),
      },
    });
  };

  const editMetaDataAction: ActionProps = {
    ...ACTION_EDIT,
    disabled: isVersion
      ? editableVersions.length === 0
      : editableDocumentsWithoutLocked.length === 0 || isSpecialFolder,
    onClick: editMetaData,
    subItems: subItems,
  };

  return editMetaDataAction;
};
