import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useProjectContext } from '@pro4all/projects/ui/context';
import {
  groupInstancesByDrawing,
  sortGroupedInstances,
} from '@pro4all/quality-control/utils';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  PhotoBlobs,
  ReportOptions,
  SignatureObject,
} from '@pro4all/shared/types';
import { ReportingMainProps } from '@pro4all/shared/types';
import { CircularProgressWithLabel } from '@pro4all/shared/ui/circular-progress-with-label';
import { DialogContainer } from '@pro4all/shared/ui/dialog';

import { getPhotoBlobs } from './utils/getPhotoBlobs';
import { useGetMembers } from './utils/useGetMembers';
import { useGetReportConfigurationTemplates } from './utils/useGetReportConfigurationTemplates';
import { useReportData } from './utils/useReportData';
import { ReportMainContainer } from './ReportingMainStyles';
import { ReportPreview } from './ReportPreview';

export const ReportInstances = ({
  createReportConfiguration,
  reportConfiguration,
  reportConfigurationSidebar = false,
}: ReportingMainProps) => {
  const { t } = useTranslation();

  const members = useGetMembers();
  const { enqueueSnackbar } = useSnackbar();

  const { searchParams, state } =
    useRouting<{
      instanceIds: string[];
      taskInstanceIds?: string[];
    }>() || {};
  const { instanceIds, taskInstanceIds } = state || {};

  const goBack = () => {
    searchParams.clear();
  };

  if (
    !instanceIds?.length &&
    !taskInstanceIds?.length &&
    !reportConfiguration &&
    !createReportConfiguration
  ) {
    goBack();
  }

  const reportConfigurationReportOptions: ReportOptions = reportConfiguration
    ? JSON.parse(reportConfiguration.reportOptions)
    : null;

  const { projectData } = useProjectContext();
  const {
    currentUser,
    finalInstances,
    mapInstances,
    previewInstances,
    taskInstances,
  } = useReportData({
    instanceIds,
    taskInstanceIds,
  });
  const { reportConfigurationDummyTemplates } =
    useGetReportConfigurationTemplates({
      createReportConfiguration,
      reportConfigurationReportOptions,
    });

  const [miniMapsFlag, setMiniMapsFlag] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState(true);
  const [signatures, setSignatures] = useState<SignatureObject[] | undefined>(
    []
  );
  const [signaturesFlag, setSignaturesFlag] = useState<boolean>(false);
  const [photoBlobsFlag, setPhotoBlobsFlag] = useState<boolean>(false);
  const [photoBlobsReportPreview, setPhotoBlobsReportPreview] = useState<
    PhotoBlobs | undefined | null
  >(undefined);

  useEffect(() => {
    if (
      finalInstances.length > 0 &&
      photoBlobsReportPreview === undefined &&
      !photoBlobsFlag
    ) {
      try {
        setPhotoBlobsFlag(true);
        const resolveBlobs = getPhotoBlobs(finalInstances);
        resolveBlobs().then((blobRecord) => {
          setPhotoBlobsReportPreview(blobRecord);
        });
      } catch (e) {
        enqueueSnackbar(
          t('Something went wrong. Please try to generate the report again.'),
          { variant: 'error' }
        );
      }
    }
  }, [
    enqueueSnackbar,
    finalInstances,
    photoBlobsFlag,
    photoBlobsReportPreview,
    t,
  ]);

  const instancesLoaded =
    previewInstances?.length > 0 || taskInstances?.length > 0;
  const photosLoaded = photoBlobsReportPreview !== undefined;

  const everyThingLoaded =
    reportConfigurationSidebar ||
    (instancesLoaded && photosLoaded && miniMapsFlag);

  const handleLoadingComplete = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  return (
    <ReportMainContainer>
      <DialogContainer open={showDialog}>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          minHeight={50}
          minWidth={150}
        >
          <span>
            {t(
              `Loading ${
                !instancesLoaded
                  ? 'instances'
                  : !photosLoaded
                  ? 'photos'
                  : !signaturesFlag
                  ? 'signatures'
                  : 'drawings'
              }`
            )}{' '}
            ...
          </span>
          <div style={{ paddingTop: '10%' }}>
            <CircularProgressWithLabel
              everyThingLoaded={everyThingLoaded}
              onLoadingComplete={handleLoadingComplete}
            />
          </div>
        </Box>
      </DialogContainer>
      <ReportPreview
        currentUser={currentUser}
        date={new Date()}
        instances={finalInstances}
        instancesGroupedByDrawing={sortGroupedInstances(
          groupInstancesByDrawing(finalInstances)
        )}
        mapInstances={mapInstances}
        members={members}
        miniMapsFlag={miniMapsFlag}
        photoBlobsReportPreview={photoBlobsReportPreview}
        project={projectData}
        reportConfiguration={reportConfiguration}
        reportConfigurationDummyTemplates={
          reportConfigurationDummyTemplates || []
        }
        reportConfigurationReportOptions={reportConfigurationReportOptions}
        reportConfigurationSidebar={reportConfigurationSidebar}
        setMiniMapsFlag={setMiniMapsFlag}
        setSignatures={setSignatures}
        setSignaturesFlag={setSignaturesFlag}
        signatures={signatures}
        signaturesFlag={signaturesFlag}
        tasks={taskInstances}
      />
    </ReportMainContainer>
  );
};
