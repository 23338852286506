import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const AiKeywords: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g>
      <path
        clipRule="evenodd"
        d="M13.7478 3.75111L12.6 2.6C12.4167 2.41667 12.2 2.27083 11.95 2.1625C11.7 2.05417 11.4333 2 11.15 2H4C3.45 2 2.97917 2.19583 2.5875 2.5875C2.19583 2.97917 2 3.45 2 4V11.15C2 11.4333 2.05417 11.7 2.1625 11.95C2.27083 12.2 2.41667 12.4167 2.6 12.6L11.4 21.4C11.7833 21.7833 12.2583 21.975 12.825 21.975C13.3917 21.975 13.8667 21.7833 14.25 21.4L21.4 14.25C21.7833 13.8667 21.975 13.3958 21.975 12.8375C21.975 12.2792 21.7833 11.8083 21.4 11.425L20.3447 10.3667C20.0644 10.579 19.716 10.7 19.35 10.7C18.6649 10.7 18.0414 10.2761 17.8117 9.62327L16.8592 6.91657L14.5742 6.10468C13.9331 5.87673 13.5 5.27755 13.5 4.6C13.5 4.29161 13.5897 3.99943 13.7478 3.75111ZM7.5625 7.5625C7.27083 7.85417 6.91667 8 6.5 8C6.08333 8 5.72917 7.85417 5.4375 7.5625C5.14583 7.27083 5 6.91667 5 6.5C5 6.08333 5.14583 5.72917 5.4375 5.4375C5.72917 5.14583 6.08333 5 6.5 5C6.91667 5 7.27083 5.14583 7.5625 5.4375C7.85417 5.72917 8 6.08333 8 6.5C8 6.91667 7.85417 7.27083 7.5625 7.5625Z"
        fillRule="evenodd"
      />
      <path d="M20.6003 3.27688C20.6404 3.39084 20.7298 3.48056 20.8437 3.52101L23.6236 4.50876C23.6806 4.52899 23.7 4.57173 23.7 4.6C23.7 4.62825 23.6806 4.671 23.6236 4.69125L20.8437 5.679C20.7298 5.71945 20.6404 5.80915 20.6003 5.9231L19.4734 9.12535C19.4615 9.159 19.4188 9.2 19.35 9.2C19.2812 9.2 19.2385 9.159 19.2266 9.12535L18.0998 5.9231C18.0597 5.80915 17.9702 5.71945 17.8564 5.679L15.0764 4.69125C15.0194 4.671 15 4.62825 15 4.6C15 4.57173 15.0194 4.52899 15.0764 4.50876L17.8564 3.52101C17.9702 3.48056 18.0597 3.39083 18.0998 3.27688L19.2266 0.074645C19.2385 0.0410215 19.2812 0 19.35 0C19.4188 0 19.4615 0.041021 19.4734 0.074645L20.6003 3.27688Z" />
    </g>
  </SvgIcon>
);
