import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScopeType, Template, TemplateType } from '@pro4all/graphql';
import { Box, DialogContent } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { useTableCheck } from '@pro4all/shared/ui/table';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { useDeleteTemplates } from '../hooks/useDeleteTemplates';

export const useDeleteTemplatesModal = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false); // State to control the visibility of the modal
  const deleteTemplates = useDeleteTemplates(); // Hook to delete templates
  const { templateType } = useMetaDataContext();

  const { checkedRows } = useTableCheck<Template>(); // Hook to get the checked rows in the table
  const {
    params: { projectId },
    goTo,
  } = useRouting(); // Hook to handle routing

  const typeMapping: Record<TemplateType, string> = {
    [TemplateType.Document]: 'folders',
    [TemplateType.Form]: 'forms',
    [TemplateType.Snag]: 'snags',
    [TemplateType.Field]: '', // Not needed for now
    [TemplateType.Section]: '', // Not needed for now
    [TemplateType.Tbq]: '', // Not needed for now
  };

  // Filter the checked rows to get the ones linked to the organization
  const linkedElementsToOrganization = checkedRows?.filter(
    (item) => item.scope.type === ScopeType.Organization
  );
  const isOrganizationLevel = Boolean(!projectId); // Determine if the current level is organization level
  const asProjectLevelSelected = checkedRows?.filter(
    (item) => item.scope.type === ScopeType.Project
  )?.length; // Count the number of project-level selected templates

  // Determine the number of templates to delete based on the current level
  const templatesToDelete = isOrganizationLevel
    ? checkedRows.length
    : asProjectLevelSelected;

  // Determine if the first confirmation label should be shown
  const showFirstConfirmationLabel =
    (templatesToDelete === 1 && !isOrganizationLevel) ||
    (isOrganizationLevel && checkedRows.length === 1);

  // Determine if the multiple confirmation label should be shown
  const showFirstConfirmationLabelMultiple =
    (checkedRows.length > 1 &&
      !isOrganizationLevel &&
      asProjectLevelSelected > 1) ||
    (isOrganizationLevel && checkedRows.length > 1);

  // Get the name of the element to show in the confirmation message
  const elementNameToShow =
    (!showFirstConfirmationLabelMultiple &&
      !isOrganizationLevel &&
      checkedRows.find((item) => item.scope.type === ScopeType.Project)
        ?.name) ||
    checkedRows[0]?.name;

  // Handler to close the modal
  const handleClose = () => {
    setShowModal(false);
  };

  // Handler to confirm the deletion of templates
  const handleConfirmDelete = async () => {
    await deleteTemplates();
    setShowModal(false);
  };

  // Handler to navigate to the organization templates
  const goToOrganization = () => goTo('metaDataDocumentsTemplates');

  const affectedAmount = useMemo(
    () =>
      checkedRows.reduce((acc, template) => {
        if (!isOrganizationLevel && template.scope.type === ScopeType.Project) {
          return acc + template.instanceCount;
        }
        if (isOrganizationLevel) {
          return acc + template.instanceCount;
        }
        return acc;
      }, 0),
    [checkedRows, isOrganizationLevel]
  );

  const entityType = typeMapping[templateType];
  const hasInstances = affectedAmount > 0;

  // Additional actions to show in the modal if certain conditions are met
  const additionalActions = !isOrganizationLevel &&
    asProjectLevelSelected > 0 &&
    linkedElementsToOrganization.length > 0 && (
      <Box>
        <Button color="inherit" onClick={goToOrganization} variant="outlined">
          {t('Go to organization templates')}
        </Button>
      </Box>
    );

  // The delete templates modal component
  const deleteTemplatesModal = (
    <Dialog
      additionalActions={additionalActions}
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      buttonColor={customColors.orangeAccent}
      onClose={handleClose}
      onSubmit={
        asProjectLevelSelected || isOrganizationLevel
          ? handleConfirmDelete
          : goToOrganization
      }
      open={showModal}
      submitLabel={
        asProjectLevelSelected || isOrganizationLevel
          ? t('Delete template')
          : t('Go to organization templates')
      }
      title={t('Delete template')}
    >
      <DialogContent sx={{ padding: 0 }}>
        <p>
          {showFirstConfirmationLabelMultiple
            ? t('Are you sure you want to delete these {{count}} templates?', {
                count: templatesToDelete,
              })
            : showFirstConfirmationLabel &&
              t("Are you sure you want to delete the template '{{name}}'?", {
                name: elementNameToShow,
              })}
        </p>
        {hasInstances && (
          <p>
            {t(
              'If you continue, it will reset the metadata to default of {{affectedAmount}} {{entityType}}.',
              {
                affectedAmount,
                entityType,
              }
            )}
          </p>
        )}
        {linkedElementsToOrganization.length > 0 && !isOrganizationLevel && (
          <p>
            {linkedElementsToOrganization.length === 1
              ? t(
                  "The template '{{name}}' is linked from an organization and can only be deleted from within the organization.",
                  {
                    name: linkedElementsToOrganization[0]?.name,
                  }
                )
              : t(
                  "The templates '{{name}}' and {{count}} more are linked from an organization and can only be deleted from within the organization.",
                  {
                    count: linkedElementsToOrganization.length - 1,
                    name: linkedElementsToOrganization[0]?.name,
                  }
                )}
          </p>
        )}
        {asProjectLevelSelected > 0 &&
          linkedElementsToOrganization.length > 0 && (
            <p>{t('Project-linked templates will be deleted.')}</p>
          )}
      </DialogContent>
    </Dialog>
  );

  return { deleteTemplatesModal, setShowModal };
};
