import { useTranslation } from 'react-i18next';

import { FieldDefinition } from '@pro4all/graphql';
import { ActionProps } from '@pro4all/shared/actions';
import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { CutCopyPasteSection, PasteType } from '@pro4all/shared/types';

import { useFieldContext } from './TemplateMutationContext';
import { useSectionSidebarContext } from './TemplateMutationContext';
import { useHidePaste } from './useHidePaste';

type SectionMenuItemsProps = {
  filterInvalidConditions: (fieldDefinition: FieldDefinition) => void;
  section: FieldDefinition;
};

export const useSectionMenuItems = ({
  filterInvalidConditions,
  section,
}: SectionMenuItemsProps) => {
  const { t } = useTranslation();
  const { setIsOpenSectionSidebar, setSection } = useSectionSidebarContext();
  const { forceRender, removeField, pasteSection } = useFieldContext();
  const { id } = section;

  const { setLocalStorageItem: setCutCopyPasteTemplateSectionInLs } =
    useLocalStorage<CutCopyPasteSection>({
      key: StorageKeys.COPY_CUT_PASTE_TEMPLATE_SECTION,
    });

  const handleRemoveField = (section: FieldDefinition) => {
    filterInvalidConditions(section);
    removeField(section);
  };

  const { hidePaste } = useHidePaste({ item: section });

  const sectionMenuItems: ActionProps[] = [
    {
      ariaLabel: t('Copy'),
      dataTestId: `copy-${id}`,
      disabled: false,
      key: `copy-${id}`,
      label: t('Copy'),
      onClick: () => {
        setCutCopyPasteTemplateSectionInLs({
          pasteType: PasteType.COPY,
          sourceSection: section,
        });
        forceRender();
      },
      startIcon: 'copyFile',
    },
    {
      ariaLabel: t('Cut'),
      dataTestId: `cut-${id}`,
      disabled: false,
      key: `cut-${id}`,
      label: t('Cut'),
      onClick: () => {
        setCutCopyPasteTemplateSectionInLs({
          pasteType: PasteType.CUT,
          sourceSection: section,
        });
        forceRender();
      },
      startIcon: 'cut',
    },
    {
      ariaLabel: t('Paste'),
      dataTestId: `paste-${id}`,
      disabled: hidePaste(),
      key: `paste-${id}`,
      label: t('Paste'),
      onClick: () => pasteSection({ targetSectionOrField: section }),
      startIcon: 'clipboard',
    },
    {
      ariaLabel: t('Edit'),
      dataTestId: `edit-${id}`,
      disabled: false,
      key: `edit-${id}`,
      label: t('Edit'),
      onClick: () => {
        setIsOpenSectionSidebar(true);
        setSection(section);
      },
      startIcon: 'edit',
    },
    {
      ariaLabel: t('Delete'),
      dataTestId: `delete-${id}`,
      disabled: false,
      key: `delete-${id}`,
      label: t('Delete'),
      onClick: () => {
        handleRemoveField(section);
      },
      startIcon: 'delete',
    },
  ];

  return { sectionMenuItems };
};
