import { useEffect } from 'react';

import type { ObjectNode, ObjectsQueryResult } from '@pro4all/graphql';
import { useObjectsQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { useHierarchyEditorContext } from '@pro4all/shared/hierarchy-editor';
import { useRouting } from '@pro4all/shared/routing-utils';
import { toRecord } from '@pro4all/shared/utils';

export interface UseObjectsResult extends Omit<ObjectsQueryResult, 'data'> {
  getObject: (id: ObjectNode['id']) => ObjectNode;
  objects: ObjectNode[];
  selectedObject?: ObjectNode;
}

export function useObjects(): UseObjectsResult {
  const {
    params: { objectId, projectId },
  } = useRouting();

  const { fillInitialItems } = useHierarchyEditorContext();

  const { data, ...queryResult } = useObjectsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000 * ApiConfig.pollEnabled,
    variables: { projectId },
  });

  useEffect(() => {
    if (data) {
      // Initially fill the provider the moment we have hierarchy data.
      fillInitialItems &&
        fillInitialItems({
          currentBreadcrumbs: [],
          items: data.objects,
        });
    }
  }, [data, fillInitialItems]);

  const objects = data?.objects ?? [];
  const lookup = toRecord(objects, 'id');
  const getObject: UseObjectsResult['getObject'] = (id) => lookup[id];
  const selectedObject = objectId ? getObject(objectId) : undefined;

  return {
    ...queryResult,
    getObject,
    objects,
    selectedObject,
  };
}
