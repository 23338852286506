import React from 'react';

import { ActionProps, BackwardsCompatibleProps } from '@pro4all/shared/actions';
import {
  Box,
  IconButtonProps,
  Tooltip,
  TooltipPlacement,
} from '@pro4all/shared/mui-wrappers';
import { ContextMenu } from '@pro4all/shared/ui/context-menu';
import { Icon, IconName } from '@pro4all/shared/ui/icons';

import { useContextMenuPosition } from '../hooks/useContextMenuPosition';

import * as Styled from './IconButton.styled';

interface Props extends BackwardsCompatibleProps, IconButtonProps {
  disableBorder?: boolean;
  iconName: IconName;
  subItems?: ActionProps[];
  tooltipPlacement?: TooltipPlacement;
}

export const IconButton = ({
  ariaLabel,
  color = 'primary',
  dataTestId,
  disableBorder = false,
  iconName,
  onClick,
  subItems,
  tabIndex,
  type = 'button',
  tooltipPlacement = 'bottom',
  ...rest
}: Props) => {
  const { closeContextMenu, isContextMenuOpen, openContextMenu, position } =
    useContextMenuPosition();

  const backwardsCompatibleProps = {
    ...(ariaLabel && { 'aria-label': ariaLabel }),
    ...{
      'data-testid': dataTestId || `icon-button-${iconName}`,
    },
  };

  return (
    <Tooltip
      placement={tooltipPlacement}
      title={isContextMenuOpen ? '' : ariaLabel ?? ''}
    >
      <Box>
        <Styled.ButtonIcon
          $disableBorder={disableBorder}
          color={color}
          onClick={subItems && subItems.length > 0 ? openContextMenu : onClick}
          tabIndex={tabIndex}
          type={type}
          {...backwardsCompatibleProps}
          {...rest}
        >
          {iconName && <Icon iconName={iconName} />}
        </Styled.ButtonIcon>
        <ContextMenu
          data-testid="context-menu-sub-items"
          initialPosition={position}
          menuItems={subItems}
          onClose={closeContextMenu}
          open={isContextMenuOpen}
        />
      </Box>
    </Tooltip>
  );
};
