import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { Document, FinalizationState, LockType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { TrackingEvent } from '@pro4all/shared/config';
import { useClipboardContext } from '@pro4all/shared/contexts';
import { useAnalytics } from '@pro4all/shared/vendor';

import { DocumentActionProps } from '../../types';

export const useCopyAction = ({
  selection,
}: Pick<DocumentActionProps, 'selection'>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { track } = useAnalytics();
  const { setDocumentsToCopy } = useClipboardContext();
  const { organizationId } = useOrganizationContext();
  const { ACTION_COPY } = useCentralActions();

  const copyDocuments = useCallback(
    async (selection: Document[]) => {
      track(TrackingEvent.DocumentCopied, {
        fileCount: selection.length,
      });

      setDocumentsToCopy(
        selection.map((doc) => ({
          ...doc,
          isExpected: false,
          lastUpdatedBy: null,
          lockType: LockType.None,
          lockedAt: null,
          lockedBy: null,
          organizationId: organizationId,
          state: FinalizationState.NotFinalized,
        }))
      );

      if (selection.length === 1) {
        enqueueSnackbar(t('Document copied to clipboard'));
      } else {
        enqueueSnackbar(
          t('{{count}} Documents copied to clipboard', {
            count: selection.length,
          })
        );
      }
    },
    [enqueueSnackbar, organizationId, setDocumentsToCopy, t, track]
  );

  const copyAction: ActionProps = {
    ...ACTION_COPY,
    onClick: () => copyDocuments(selection),
  };

  return copyAction;
};
