import { Document } from '@pro4all/graphql';
import { TaskType } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useRouting } from '@pro4all/shared/routing-utils';

type SearchParams = { action: string; id?: string; taskType: TaskType };

export const useCreateDocumentTaskAction = ({
  documents,
}: {
  documents: Document[];
}) => {
  const { goTo } = useRouting();
  const { ACTION_CREATE_DOCUMENT_TASK } = useCentralActions();

  const createDocumentTaskAction: ActionProps = {
    ...ACTION_CREATE_DOCUMENT_TASK,
    dataTestId: 'create-document-task',
    key: 'create-document-task',
    onClick: () => {
      const searchParams: SearchParams = {
        action: 'createTask',
        taskType: TaskType.Document,
      };
      //scenario: right click a document to create doc task via context menu
      if (documents.length === 1) searchParams['id'] = documents[0].id;
      goTo({
        searchParams,
        state: { selectedDocuments: documents },
      });
    },
    startIcon: 'expectedDocument',
  };
  return createDocumentTaskAction;
};
