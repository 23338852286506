import React from 'react';

import { ActionProps } from '@pro4all/shared/actions';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { ActionBar as ActionBarOld } from '@pro4all/shared/ui/action-bar';
import { ActionBar } from '@pro4all/shared/ui/layout';

export const SidebarActionBar = ({
  showDivider = false,
  sidebarActions,
}: {
  showDivider?: boolean;
  sidebarActions: ActionProps[];
}) => {
  const hasFullScreenView = useFeatureFlag('fullscreen-view');

  return (
    <Box mt={2}>
      {hasFullScreenView ? (
        <ActionBar actions={sidebarActions} showDivider={showDivider} />
      ) : (
        <ActionBarOld hideLabels mainActions={sidebarActions} />
      )}
    </Box>
  );
};
