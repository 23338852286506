import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  QcPermissionCategory,
  QualityControlInstance,
  QualityControlInstanceType,
  TaskType,
  useDeleteInstanceMutation,
  useRestoreInstanceMutation,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { Action } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Alert, Button } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { ItemChangedMessage } from '@pro4all/shared/ui/messages';
import { useTableCheck } from '@pro4all/shared/ui/table';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';
import { useAnalytics } from '@pro4all/shared/vendor';

export const ResultsActionBar: React.FC<{
  deleteItems?: (ids: string[]) => void;
  restoreItems?: (ids: string[]) => void;
  showDataViewContextToggle?: boolean;
  showSearch?: boolean;
}> = ({ showDataViewContextToggle, showSearch, deleteItems, restoreItems }) => {
  const { t } = useTranslation();

  const { ACTION_CREATE_REPORT, ACTION_RESOLVE_TASK, ACTION_DELETE } =
    useCentralActions();

  const [showDialog, setShowDialog] = useState(false);
  const [checkedAllForms, setCheckedAllForms] = useState(false);
  const { userId } = useOrganizationContext();
  const { goTo, params } = useRouting();
  const { checkedRows } = useTableCheck<QualityControlInstance>();
  const { track } = useAnalytics();

  const { resolveCreateAll, resolveCreateOwn } = useQCPermissions({
    category: QcPermissionCategory.Procedure,
  });

  const { formDeleteAll, snagDeleteAll, formDeleteOwn, snagDeleteOwn } =
    useQCPermissions({
      category: QcPermissionCategory.Qcs,
    });

  const canDeleteAll = formDeleteAll && snagDeleteAll;

  const hasTbqType = checkedRows.some((row) => row.type === 'tbq');

  const verifyCheckedResults = () => {
    const flag = checkedRows.every((row) => row.type === 'snag')
      ? 'all-snags'
      : checkedRows.every((row) => row.type === 'form')
      ? 'all-forms'
      : 'mixed';

    if (flag === 'all-snags') {
      createResolveTask();
    } else {
      setShowDialog(true);
      setCheckedAllForms(flag === 'all-forms' ? true : false);
    }
  };

  const createResolveTask = () => {
    if (showDialog) setShowDialog(false);
    goTo({
      searchParams: { action: 'createTask', taskType: TaskType.Resolve },
    });
  };

  const { enqueueSnackbar } = useSnackbar();

  const [deleteInstanceMutation] = useDeleteInstanceMutation();

  const deleteQualityResultsFeatureFlag = useFeatureFlag(
    'delete-quality-results'
  );

  const getIdsToDelete = () => {
    if (canDeleteAll) return checkedRows.map((result) => result.id);

    const ids = checkedRows
      .filter((result) => {
        if (formDeleteAll && result.type === QualityControlInstanceType.Form)
          return true;

        if (snagDeleteAll && result.type === QualityControlInstanceType.Snag)
          return true;
        if (
          formDeleteOwn &&
          result.type === QualityControlInstanceType.Form &&
          result?.createdBy?.id === userId
        )
          return true;
        if (
          snagDeleteOwn &&
          result.type === QualityControlInstanceType.Snag &&
          result?.createdBy?.id === userId
        )
          return true;
        return false;
      })
      .map((result) => result.id);
    return ids;
  };

  const idsToDelete = getIdsToDelete();

  const [restoreInstanceMutation] = useRestoreInstanceMutation();

  const handleRestoreInstances = async () => {
    try {
      const { data } = await restoreInstanceMutation({
        variables: {
          ids: idsToDelete,
        },
      });
      if (data?.restoreInstance?.success) {
        restoreItems?.(idsToDelete);
        enqueueSnackbar(
          <Alert severity="info">
            {t(`{{successNumber}} items restored`, {
              successNumber: idsToDelete.length,
            })}
          </Alert>
        );
      } else {
        enqueueSnackbar(t(`Failed to restore items`));
      }
    } catch (_) {
      enqueueSnackbar(t(`Failed to restore items`));
    }
  };
  const handleDelete = async () => {
    try {
      const deleteResponse = await deleteInstanceMutation({
        variables: {
          ids: idsToDelete,
        },
      });

      const successDeletedItems = deleteResponse.data?.deleteInstance?.filter(
        (item) => item?.success
      );
      const failedDeletedItems = deleteResponse.data?.deleteInstance?.filter(
        (item) => !item?.success
      );
      const numNoPermissions = checkedRows.length - idsToDelete.length;

      let messageTemplate = `{{successNumber}} item(s) deleted.`;
      if (numNoPermissions) {
        messageTemplate += ` {{noPermissions}} item(s) not deleted because you have no permissions to do so.`;
      }
      if (failedDeletedItems?.length) {
        messageTemplate += ` {{failedNumber}} item(s) failed to delete for technical reasons.`;
      }

      const messageVariables = {
        failedNumber: failedDeletedItems?.length,
        noPermissions: numNoPermissions,
        successNumber: successDeletedItems?.length,
      };

      enqueueSnackbar(
        <ItemChangedMessage
          action={
            successDeletedItems?.length && (
              <Button
                color="inherit"
                onClick={handleRestoreInstances}
                size="small"
              >
                {t('Undo')}
              </Button>
            )
          }
        >
          {t(messageTemplate, messageVariables)}
        </ItemChangedMessage>
      );

      deleteItems?.(idsToDelete);
    } catch (_) {
      enqueueSnackbar(t('Failed to delete items'), {
        variant: 'error',
      });
    }
  };
  const altActions: ActionProps[] = [
    {
      ...ACTION_CREATE_REPORT,
      onClick: () => {
        track(Action.CreateReportButtonClicked, {
          itemAmount: checkedRows.length,
          projectId: params.projectId,
        });

        goTo({
          searchParams: { action: 'createReport' },
          state: {
            instanceIds: checkedRows.map(({ id }) => id),
          },
        });
      },
    },
    !hasTbqType &&
      (resolveCreateAll || resolveCreateOwn) && {
        ...ACTION_RESOLVE_TASK,
        onClick: () => {
          track(Action.CreateResolveTaskButtonClicked, {
            itemAmount: checkedRows.length,
            location: 'Results table',
            projectId: params.projectId,
          });
          verifyCheckedResults();
        },
      },
    deleteQualityResultsFeatureFlag &&
      (canDeleteAll || getIdsToDelete().length > 0) && {
        ...ACTION_DELETE,
        onClick: () => {
          handleDelete();
        },
      },
  ].filter(Boolean) as ActionProps[];

  return (
    <>
      <Dialog
        iconName="warning"
        name="resolveTaskWarning"
        onClose={() => setShowDialog(false)}
        onConfirm={checkedAllForms ? null : createResolveTask}
        open={showDialog}
        title={t('Only Snags allowed')}
      >
        {checkedAllForms && (
          <>
            You are trying to create a resolve task with only forms. You need at
            least one snag to create a resolve task.
          </>
        )}
        {!checkedAllForms && (
          <>
            You are trying to add a form to a resolve task. If you continue only
            the selected Snags will be added to the resolve task.
          </>
        )}
      </Dialog>
      <TableActionBar
        altActions={altActions}
        dataTestid="results-action-bar"
        dataViewToggle={showDataViewContextToggle}
        search={showSearch}
      />
    </>
  );
};
