import { useTranslation } from 'react-i18next';

import { ApprovalAction } from '@pro4all/documents/ui/approval';
import { DocumentVersionState } from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { useDocumentActionsLabels } from '@pro4all/shared/label-config';

import { DocumentActionProps } from '../../types';

import { useHandleApprovalOrRejection } from './useHandleApprovalOrRejection';

export const useApproveActions = ({
  allDocumentsExpected,
  allDocumentsFinalized,
  folderId,
  hasFolderPermission,
  isLocked,
  organizationId,
  position,
  projectId,
  selection,
  selectionVersion,
  trackingPosition,
  uncheckAllRows,
  userId,
  versionIds,
}: Pick<
  DocumentActionProps,
  | 'allDocumentsExpected'
  | 'allDocumentsFinalized'
  | 'folderId'
  | 'hasFolderPermission'
  | 'isLocked'
  | 'organizationId'
  | 'position'
  | 'projectId'
  | 'selection'
  | 'selectionVersion'
  | 'trackingPosition'
  | 'uncheckAllRows'
  | 'userId'
  | 'versionIds'
>) => {
  const { t } = useTranslation();
  const {
    ACTION_APPROVE,
    ACTION_REJECT,
    ACTION_REJECT_WITHDRAW,
    ACTION_APPROVE_WITHDRAW,
  } = useCentralActions();

  const { approve_action_label, reject_action_label } =
    useDocumentActionsLabels();

  const handleApprovalOrRejection = useHandleApprovalOrRejection({
    folderId,
    organizationId,
    position,
    projectId,
    selection,
    selectionVersion,
    trackingPosition,
    uncheckAllRows,
    userId,
    versionIds,
  });

  const versionStates = selection.length
    ? selection.map((row) => row.versionState ?? DocumentVersionState.None)
    : selectionVersion.map(
        (row) => row.versionState ?? DocumentVersionState.None
      );

  const hideApprovalRejectionActions =
    allDocumentsExpected ||
    versionStates.every(
      (versionState) =>
        versionState === DocumentVersionState.Approved ||
        versionState === DocumentVersionState.Rejected
    );

  const hideUnapproveActions =
    allDocumentsExpected ||
    versionStates.every(
      (versionState) =>
        versionState === DocumentVersionState.None ||
        versionState === DocumentVersionState.Rejected
    );

  const hideUnrejectActions =
    allDocumentsExpected ||
    versionStates.every(
      (versionState) =>
        versionState === DocumentVersionState.None ||
        versionState === DocumentVersionState.Approved
    );

  const approveVersionAction: ActionProps = {
    ...ACTION_APPROVE,
    ariaLabel: approve_action_label,
    disabled:
      !hasFolderPermission('ApproveContent') ||
      allDocumentsFinalized ||
      isLocked ||
      hideApprovalRejectionActions,
    label: approve_action_label,
    onClick: () => handleApprovalOrRejection(ApprovalAction.Approve),
  };

  const rejectVersionAction: ActionProps = {
    ...ACTION_REJECT,
    ariaLabel: reject_action_label,
    disabled:
      !hasFolderPermission('ApproveContent') ||
      allDocumentsFinalized ||
      isLocked ||
      hideApprovalRejectionActions,
    label: reject_action_label,
    onClick: () => handleApprovalOrRejection(ApprovalAction.Reject),
  };

  const unapproveVersionAction: ActionProps = {
    ...ACTION_APPROVE_WITHDRAW,
    ariaLabel: t('Withdraw {{name}}', { name: approve_action_label }),
    disabled:
      !hasFolderPermission('ApproveContent') ||
      allDocumentsFinalized ||
      isLocked ||
      hideUnapproveActions,
    label: t('Withdraw {{name}}', { name: approve_action_label }),
    onClick: () => handleApprovalOrRejection(ApprovalAction.Unapprove),
  };

  const unrejectVersionAction: ActionProps = {
    ...ACTION_REJECT_WITHDRAW,
    ariaLabel: t('Withdraw {{name}}', { name: reject_action_label }),
    disabled:
      !hasFolderPermission('ApproveContent') ||
      allDocumentsFinalized ||
      isLocked ||
      hideUnrejectActions,
    label: t('Withdraw {{name}}', { name: reject_action_label }),
    onClick: () => handleApprovalOrRejection(ApprovalAction.Unreject),
  };

  return {
    approveVersionAction,
    rejectVersionAction,
    unapproveVersionAction,
    unrejectVersionAction,
  };
};
