import { useMemo } from 'react';

import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import {
  downloadVersion,
  useDocumentsContext,
  useDropFiles,
} from '@pro4all/documents/ui/share';
import { DocumentDetails, SidebarContent } from '@pro4all/documents/ui/sidebar';
import { DocumentVersion, useDocumentVersionQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import {
  useDocumentActions,
  useDocumentSelection,
} from '@pro4all/shared/hooks/src/documents';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Position } from '@pro4all/shared/types';
import { FileUpload, FileUploadOverlay } from '@pro4all/shared/ui/file-upload';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { Loader } from '@pro4all/shared/ui/loader';
import {
  BigMessageNoDocumentVersions,
  BigMessageSelectDocumentToShowVersions,
} from '@pro4all/shared/ui/messages';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
  useTableContextMenuRowContext,
} from '@pro4all/shared/ui/table';

import { useColumns } from './hooks/useColumns';
import { useGetFolder } from './hooks/useGetFolder';
import { VersionTableDropDocument } from './VersionTableDropDocument';

export const VersionTable = ({ expanded }: { expanded: boolean }) => {
  const { searchParams } = useRouting();
  const {
    setDocTableColumnSizes,
    setTableScrollPositionLeft,
    tableScrollPositionLeft,
  } = useDocumentsContext();

  const {
    deselectVersion,
    selectVersion,
    selectedDocument: selectedVersionDocument,
  } = useDocumentSelection();

  const { folder } = useGetFolder();
  const columns = useColumns({
    folder,
    isForVersionTable: true,
    onColumnResizeCallback: setDocTableColumnSizes,
  });

  const { canUploadVersion } = useUserFolderPermissions({
    folderId: folder?.id,
  });
  const {
    state: { item, items, itemsInitial },
  } = useOptimisticResponseContext<DocumentVersion>();

  const selectedDocId = searchParams.get('id');
  const selectedDocument = folder?.documents?.find(
    (document) => document.id === selectedDocId
  );

  const userCanUploadVersion = canUploadVersion(selectedDocument);

  const { data } = useDocumentVersionQuery({
    fetchPolicy: 'network-only',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    skip: !selectedDocId,
    variables: { id: selectedDocId },
  });

  const dropFiles = useDropFiles({
    documentCurrent: selectedDocument,
    mustBeOneFile: true,
  });

  const versions = useMemo(
    () => data?.documentVersion || [],
    [data?.documentVersion]
  );

  useSetItemsInLocalState<DocumentVersion>(versions);

  const { contextMenuRow } = useTableContextMenuRowContext<DocumentVersion>();

  const { versionActions: contextMenuActions } = useDocumentActions({
    contextMenuRowVersion: contextMenuRow,
    position: Position.Contextmenu,
  });
  const { sidebarActions } = useDocumentActions({
    position: Position.Contextmenu,
    sidebarRowVersion: item,
  });

  const versionId = searchParams.get('versionid');

  if (!expanded) return null;
  if (!selectedDocId) return <BigMessageSelectDocumentToShowVersions />;
  if (!data) return <Loader />;
  if (!items.length && !itemsInitial.length)
    return <BigMessageNoDocumentVersions />;

  return (
    <VersionTableDropDocument
      getHover={({ hoversOverCurrent }) => (
        <TableContextProvider
          checkable={false}
          checkableSpacing
          columns={columns}
          id={`table-documents-${folder?.id || ''}`}
          idFilteringSorting={`table-versions-${selectedDocId}`}
          items={items}
        >
          <FilterContextProvider<DocumentVersion>>
            <>
              <FileUpload multiple onDrop={userCanUploadVersion && dropFiles}>
                {({ isDragActive }) => (
                  <FileUploadOverlay
                    isActive={
                      userCanUploadVersion &&
                      (isDragActive || hoversOverCurrent)
                    }
                  >
                    <Table
                      contextMenuActions={
                        contextMenuRow?.__typename === 'DocumentVersion'
                          ? contextMenuActions
                          : []
                      }
                      data-testid="version-table"
                      enableKeyboardControl={Boolean(selectedVersionDocument)}
                      onColumnResizeCallback={setDocTableColumnSizes}
                      onRowClick={selectVersion}
                      onRowDoubleClick={(row) =>
                        downloadVersion(
                          items.find((item) => item.id === row.id)
                        )
                      }
                      onTableHorizontalScrollCallback={
                        setTableScrollPositionLeft
                      }
                      selectedId={selectedVersionDocument?.id}
                      tableScrollPositionLeft={tableScrollPositionLeft}
                    />
                  </FileUploadOverlay>
                )}
              </FileUpload>
              <DocumentDetails
                folder={folder}
                onClose={deselectVersion}
                sidebarActions={sidebarActions}
                sidebarContent={SidebarContent.version}
                versionId={versionId}
              />
            </>
          </FilterContextProvider>
        </TableContextProvider>
      )}
    />
  );
};
