import { useTaskIncludeQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

interface Props {
  includeDeliverables?: boolean;
  includeDocumentAction?: boolean;
  includeFormTemplates?: boolean;
  includeLinkedSnagInstances?: boolean;
  includeSnagTemplates?: boolean;
  includeVisualContexts?: boolean;
}

export const useFetchTask = ({
  includeVisualContexts = false,
  includeDeliverables = false,
  includeLinkedSnagInstances = false,
  includeDocumentAction = false,
  includeSnagTemplates = false,
  includeFormTemplates = false,
}: Props) => {
  const taskId = useRouting().searchParams.get('id') || '';

  const {
    data,
    loading,
    error,
    refetch: refetchTask,
  } = useTaskIncludeQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    skip: !taskId,
    variables: {
      id: taskId,
      includeAssignment: true,
      includeAvailableStatuses: true,
      includeCompletedAt: true,
      includeCreatedAt: true,
      includeCreatedByUser: true,
      includeDeliverables,
      includeDescription: true,
      includeDocumentAction,
      includeEndTime: true,
      includeFormTemplates,
      includeLinkedSnagInstances,
      includeName: true,
      includeProject: true,
      includeSnagTemplates,
      includeStatus: true,
      includeVisualContexts,
    },
  });

  return { data, error, loading, refetchTask };
};
