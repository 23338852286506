import { useGetLinkedTasksQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useLinkedTasks = () => {
  const { searchParams } = useRouting();
  const itemId = searchParams.get('id');

  const { data } = useGetLinkedTasksQuery({
    fetchPolicy: 'cache-and-network',
    skip: !itemId,
    variables: { id: itemId || '' },
  });

  const linkedTasks = data?.getLinkedTasks ?? [];

  return { linkedTasks, loading: !data };
};
