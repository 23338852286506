import { useTranslation } from 'react-i18next';
import { NIL as emptyId } from 'uuid';

import { useGenerateLinkContext } from '@pro4all/documents/ui/share';
import { ActionProps } from '@pro4all/shared/actions';
import { Action, TrackingEvent } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

import { DocumentActionProps } from '../../types';

export const useShareWithOthersAction = ({
  expectedFileCount,
  isVersion,
  projectId,
  selection,
  userId,
}: Pick<
  DocumentActionProps,
  'expectedFileCount' | 'isVersion' | 'projectId' | 'selection' | 'userId'
>) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { searchParams } = useRouting();

  const { openShareModal, setResources } = useGenerateLinkContext();

  const handleShare = () => {
    const shareableDocuments = selection.filter(
      (document) =>
        document.versionId !== emptyId && document?.versionNumber !== 0
    );

    track(TrackingEvent.DocumentShareSelected, {
      action: Action.TableButtonClicked,
      documentNames: shareableDocuments.map((doc) => doc.name),
      projectId,
      url: window.location.href,
      userId,
    });

    setResources(shareableDocuments);

    expectedFileCount > 0
      ? searchParams.set('action', 'file-cant-be-shared')
      : openShareModal();
  };

  const shareWithOthersAction: ActionProps = {
    ariaLabel: t('Share with others'),
    dataTestId: 'share-document-with-others',
    disabled: isVersion,
    key: 'share-document-with-others',
    label: t('Share with others'),
    onClick: () => handleShare(),
    startIcon: 'supervisorAccount',
  };

  return shareWithOthersAction;
};
