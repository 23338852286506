import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  removeDocumentsFromStore,
  useCollectionFolderContext,
  useFolderTreeContextOuter,
} from '@pro4all/documents/ui/folders';
import {
  DocumentDeleted,
  DocumentsDeleted,
} from '@pro4all/documents/ui/snackbars';
import {
  Document,
  FolderByPathDocument,
  useDeleteDocumentsMutation,
} from '@pro4all/graphql';
import { ActionProps, useCentralActions } from '@pro4all/shared/actions';
import { TrackingEvent } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';
import { useAnalytics } from '@pro4all/shared/vendor';

import { DocumentActionProps } from '../../types';
import { useDocumentSelection } from '../../useDocumentSelection';

import { useHideDeleteDocument } from './useHideDeleteDocument';

export const useDeleteAction = ({
  allDocumentsFinalized,
  hasFolderPermission,
  isLocked,
  isSpecialFolder,
  isVersion,
  selection,
  userId,
}: Pick<
  DocumentActionProps,
  | 'allDocumentsFinalized'
  | 'hasFolderPermission'
  | 'isLocked'
  | 'isSpecialFolder'
  | 'isVersion'
  | 'selection'
  | 'userId'
>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { track } = useAnalytics();
  const [deleteDocuments] = useDeleteDocumentsMutation();
  const { params } = useRouting();
  const { projectId } = params;
  const { folderEmptyAfterDocumentDelete } = useFolderTreeContextOuter();
  const { ACTION_DELETE } = useCentralActions();

  const hideDeleteDocument = useHideDeleteDocument({
    hasFolderPermission,
    selection,
    selectionVersion: [],
    userId,
  });

  const {
    deleteItems,
    restoreItems,
    state: { items },
  } = useOptimisticResponseContext<Document>();

  const { updateDocumentsCount } = useCollectionFolderContext();

  const { deselectDocument } = useDocumentSelection(items);

  const handleDelete = async () => {
    track(TrackingEvent.DocumentDeleteSelected, {
      fileCount: selection.length,
    });

    const documentIds =
      hasFolderPermission('DeleteOwnContent') &&
      !hasFolderPermission('DeleteContent')
        ? selection
            .filter((row) => row.createdBy?.id === userId)
            .map(({ id }) => id)
        : selection.map(({ id }) => id);
    const skippedDocuments =
      hasFolderPermission('DeleteOwnContent') &&
      !hasFolderPermission('DeleteContent')
        ? selection.filter((row) => row.createdBy?.id !== userId).length
        : 0;

    const {
      data: {
        deleteDocuments: { succeededIds, failedIds },
      },
      errors,
    } = await deleteDocuments({
      variables: { documentIds },
    });

    // Get current folder information in cache
    const cachedFolder = client?.readQuery({
      query: FolderByPathDocument,
      variables: { path: params?.path ?? '/', projectId },
    });

    // Documents in cache are updated to remove deleted documents.
    // This avoids issues with uploading recently deleted documents.
    const updatedDocuments = [
      ...cachedFolder.folder.documents.filter(
        (document: Document) => !documentIds.includes(document.id)
      ),
    ];
    const hasDocuments = updatedDocuments.length > 0;
    client?.writeQuery({
      data: {
        folder: {
          ...cachedFolder.folder,
          documents: updatedDocuments,
        },
        hasDocuments,
      },
      query: FolderByPathDocument,
      variables: { path: params?.path ?? '/', projectId },
    });

    // We need to update the folder to show the correct folder icon, for the case that all documents in this folder have been deleted.
    if (!hasDocuments) folderEmptyAfterDocumentDelete(cachedFolder.folder.id);

    deselectDocument();

    if (succeededIds?.length) {
      deleteItems(succeededIds);
      removeDocumentsFromStore(succeededIds, projectId, userId);
      updateDocumentsCount();

      const deletedMessage =
        succeededIds.length === 1 ? (
          <DocumentDeleted
            documentId={selection[0].id}
            documentName={selection[0].name}
            restoreItems={restoreItems}
          />
        ) : (
          <DocumentsDeleted
            count={succeededIds.length}
            documentIds={succeededIds}
            restoreItems={restoreItems}
          />
        );

      enqueueSnackbar(deletedMessage);
    }

    if (errors) enqueueSnackbar(t('Something went wrong'));
    if (failedIds?.length) {
      enqueueSnackbar(
        t('Failed to delete {{count}} document(s)', {
          count: failedIds.length,
        })
      );
    }

    if (skippedDocuments > 0) {
      enqueueSnackbar(
        t(
          'Deletion of {{count}} documents skipped due to not being own documents',
          {
            count: skippedDocuments,
          }
        )
      );
    }
  };

  const deleteAction: ActionProps = {
    ...ACTION_DELETE,
    disabled:
      hideDeleteDocument() ||
      allDocumentsFinalized ||
      isLocked ||
      isSpecialFolder ||
      isVersion,
    onClick: handleDelete,
  };

  return deleteAction;
};
