import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/actions';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { ContextMenu, Position } from '@pro4all/shared/ui/context-menu';
import { IconName } from '@pro4all/shared/ui/icons';

interface Props {
  disableBorder?: boolean;
  menuItems: ActionProps[];
  moreIcon?: IconName;
  name?: string;
  onContextMenu?: () => void;
}

export { Props as ContextMenuInstanceProps };

export const ContextMenuInstance = ({
  disableBorder = false,
  menuItems,
  moreIcon = 'moreVert',
  name = 'Untitled',
  onContextMenu,
}: Props) => {
  const { t } = useTranslation();

  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState<Position | undefined>(
    undefined
  );

  const hideContextMenu = () => {
    setContextMenuVisible(false);
    setMenuPosition(undefined);
  };

  const handleContextMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onContextMenu && onContextMenu();
    hasOneAction
      ? menuItems[0].onClick && menuItems[0].onClick(event)
      : showContextMenu(event);
  };

  const showContextMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuPosition({ left: event.clientX, top: event.clientY });
    setContextMenuVisible(true);
  };

  const hasOneAction = menuItems.length === 1;
  if (!menuItems.length) return null;

  return (
    <>
      <IconButton
        ariaLabel={
          hasOneAction ? menuItems[0].ariaLabel : t('Show available actions')
        }
        color="default"
        dataTestId={hasOneAction ? undefined : `icon-button-${name}`}
        disableBorder={disableBorder}
        disabled={hasOneAction ? menuItems[0].disabled : false}
        iconName={
          hasOneAction
            ? menuItems[0].startIcon || menuItems[0].endIcon || moreIcon
            : moreIcon
        }
        onClick={handleContextMenu}
      />
      {contextMenuVisible && (
        <ContextMenu
          data-testid="context-menu"
          initialPosition={menuPosition}
          menuItems={menuItems}
          onClose={hideContextMenu}
          open={contextMenuVisible}
        />
      )}
    </>
  );
};
